/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
//import Navbar from "components/Navbars/Navbar.jsx";
import PoolNavbar from "components/Navbars/PoolNavbar.jsx";
//import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import routes from "routes.js";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/48968.jpg";
import logo from "assets/img/lightbulb-png-842.png";

import { coinImp, stopCoinImp } from "variables/util.jsx"
import ReactGA from 'react-ga';

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/ui") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
    })}
  </Switch>
);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    const splitPath = props.location.pathname.split("/")
    this.state = {
      locationPathname: props.location.pathname,
      pool: splitPath.length >= 2 ? splitPath[2] : "",
      account: ((splitPath.length >= 3) && (splitPath[2] === "search")) ? splitPath[3] : (splitPath.length >= 4) ? splitPath[4] :  "",
      image: image,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false
    };
  }

  initializeReactGA = () => {
    ReactGA.initialize('UA-129218147-10');
    ReactGA.pageview(this.props.location.pathname);
  }

  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/maps";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
    this.initializeReactGA();

    coinImp(this.state.pool);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
    this.initializeReactGA()
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
    stopCoinImp();
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={"Wattpool.net"}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          pool={this.state.pool}
          account={this.state.account}
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel" style={{ backgroundColor: '#000017', backgroundImage: "url(" + this.state.image + ")", backgroundSize: "cover" }}>
          <PoolNavbar
            routes={routes}
            handleDrawerToggle={this.handleDrawerToggle}
            pool={this.state.pool}
            account={this.state.account}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
              <div className={classes.map}>{switchRoutes}</div>
            )}
          {/* {this.getRoute() ? <Footer /> : null} */}
          {/* <FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            bgColor={this.state["color"]}
            bgImage={this.state["image"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
          /> */}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
