import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
// import Quote from "components/Typography/Quote.jsx";
// import Muted from "components/Typography/Muted.jsx";
// import Primary from "components/Typography/Primary.jsx";
// import Info from "components/Typography/Info.jsx";
// import Success from "components/Typography/Success.jsx";
// import Warning from "components/Typography/Warning.jsx";
// import Danger from "components/Typography/Danger.jsx";
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardBody from "components/Card/CardBody.jsx";
import TermsOfService from "components/Pool/Cards/TermsOfService.jsx";
import Discord from "components/Pool/Cards/Discord.jsx";
import Email from "components/Pool/Cards/Email.jsx";
//import Ads from "components/Pool/Cards/Ads.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import TwoCryptoCalc from "components/Pool/Cards/Ads/TwoCryptoCalc.jsx";
import CryptUnit from "components/Pool/Cards/Ads/CryptUnit.jsx";
import FourMiners from "components/Pool/Cards/Ads/FourMiners.jsx";
import Ledger from "components/Pool/Cards/Ads/Ledger.jsx";
import MintMe from "components/Pool/Cards/Ads/MintMe.jsx";
import Mrr from "components/Pool/Cards/Ads/Mrr.jsx";
import Stex from "components/Pool/Cards/Ads/Stex.jsx";
import Graviex from "components/Pool/Cards/Ads/Graviex.jsx";
import DigitalPrice from "components/Pool/Cards/Ads/DigitalPrice.jsx";

import { apiServer } from "variables/util.jsx";

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const _infoURL = apiServer + "/all/api/info";

class TypographyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: "",
      ...props
    };
  }

  update() {
    if (typeof this.props.match.params.pool === "undefined") { return }
    let infoURL = _infoURL.replace("all", this.props.match.params.pool)

    Promise.all([
      fetch(infoURL).then(v => v.json())
    ]).then(allResponses => {
      const info = allResponses[0];
      try {
        this.setState({ info: info });
      } catch (e) {
        console.log(e)
      }
      // this.setState({
      //   isLoaded: true,
      //   items: result.items
      // });
    },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      error => {
        // this.setState({
        //   isLoaded: true,
        //   error
        // });
      }
    );
  }

  intervalID = 0;
  u = () => this.update();

  componentDidMount() {
    this.intervalID = setInterval(this.u, 3000);
    this.u();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { classes } = this.props;
    const pool = this.props.match.params.pool;
    const info = this.state.info;
    return (
      <div align="center" style={{ color: "#ffffff" }}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Email color="info" />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Discord pool={pool} color="info" info={info} />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Discord pool={"wattpool"} color="info" />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <TermsOfService color="primary" />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12}>
            Affiliates:
          </GridItem>
          <GridItem xs={0} sm={2} />
          <GridItem xs={12} sm={8}>
            <Ledger classes={classes} selected={pool} />
          </GridItem>
          <GridItem xs={0} sm={2} />

          <GridItem xs={0} sm={2} />
          <GridItem xs={12} sm={8}>
            <Mrr />
          </GridItem>
          <GridItem xs={0} sm={2} />

          <GridItem xs={12}>
            Calculators:
          </GridItem>
          <GridItem xs={0} sm={2} />
          <GridItem xs={12} sm={8}>
            <FourMiners />
          </GridItem>
          <GridItem xs={0} sm={2} />

          <GridItem xs={0} sm={2} />
          <GridItem xs={12} sm={8}>
            <TwoCryptoCalc />
          </GridItem>
          <GridItem xs={0} sm={2} />

          <GridItem xs={0} sm={2} />
          <GridItem xs={12} sm={8}>
            <CryptUnit color="info" />
          </GridItem>
          <GridItem xs={0} sm={2} />

          <GridItem xs={12}>
            Exchanges:
          </GridItem>

          <GridItem xs={12} sm={6}>
            <Stex color="info" />
          </GridItem>

          <GridItem xs={12} sm={6}>
            <MintMe color="primary" />
          </GridItem>

          <GridItem xs={12} sm={6}>
            <DigitalPrice color="info" />
          </GridItem>

          <GridItem xs={12} sm={6}>
            <Graviex color="primary" />
          </GridItem>

        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(TypographyPage);
