import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Discord extends React.Component {

  _getInvite(chain, info) {
    switch (chain) {
      case "akroma":
        return "KWC8wtT";
      case "aqua":
      case "aqua-solo":
        return "FFZtewC";
      case "ethergem":
      case "egem":
        return "zav3EkW";
      case "mix":
      case "mix-solo":
        return "NUmAEY2";
      case "pirl":
        return "z4faFxD";
      case "reosc":
        return "CDQk9RR";
      case "atheios":
        return "b3DBpjJ";
      case "ether1":
      case "ether1-solo":
        return "MbfKRpC";
      case "ballz":
        return "wtC7bNd";
      case "mintme":
      case "mintme-solo":
      case "webchain":
      case "webchain-solo":
        return "stYaeHh";
      case "ethersocial":
        return "hqHm69E";
      case "solidum":
        return "VS6TyXW";
      case "expanse":
        return "nvNnxtN";
      case "perkle":
        return "9tSxTn5";
      case "ubiq":
        return "HF6vEGF";
      case "verus":
      case "vrsc":
        return "UJQnNhs";
      case "xerom":
      case "xero":
      case "xero-solo":
        return "DKjZt4q";
      case "wattpool":
        return "HjzT8X2";
      default:
        return (typeof info !== "undefined" &&
          typeof info.social !== "undefined" &&
          typeof info.social.discord !== "undefined") ? info.social.discord : null;
    }
  }
  getInvite(chain, info) {
    const i = this._getInvite(chain, info);
    return !i ? null : "https://discord.gg/" + i;
  }

  render() {
    const { classes, color, info, pool /*, selected*/ } = this.props;
    if (typeof pool === "undefined") return null;
    const invite = this.getInvite(pool, info);
    return !invite ? null : (
      <a href={invite} target="discord">
        <Card>
          <CardHeader color={color} stats icon>
            <CardIcon color={color}>
              <Icon>people</Icon>
            </CardIcon>
            <p className={classes.cardTitle}>
              <small>
                <nobr>
                  join the{" "}
                  <b>
                    <a href={invite} target="discord">{pool}</a>
                  </b>
                  {" "}discord:
              </nobr>
              </small>
            </p>
            <nobr>
              <b><a href={invite} target="discord">{"discord.gg/" + invite.split("/")[3]}</a></b>
            </nobr>
          </CardHeader>
        </Card>
      </a>
    );
  }
}

Discord.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
  pool: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(Discord);
