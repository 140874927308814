import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";
import { activeNonTlsStratums } from "variables/util.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Connect extends React.Component {

  render() {
    const stratums = activeNonTlsStratums(this.props.poolStats);
    if (stratums.length === 0) return null;
    const stratumPort = stratums[0].stratumPort;
    const getworkPort = stratums[0].httpPort;
    const { classes, color } = this.props;

    const stratum = typeof stratumPort !== "string" || stratumPort === "" ? null :
      (<p className={classes.cardTitle}><small>stratum:{" "}
        <nobr><font color="#000000" size={1}>
          {this.props.selected + ':' + stratumPort}
        </font></nobr>
      </small></p>)

    const getwork = typeof getworkPort !== "string" || getworkPort === "" ? null :
      (<p className={classes.cardTitle}><small>getwork:{" "}
        <nobr><font color="#000000" size={1}>
          {this.props.selected + ':' + getworkPort}
        </font></nobr>
      </small></p>)

    return (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>power</Icon>
          </CardIcon>
          {stratum}
          {getwork}
        </CardHeader>
      </Card>
    );
  }
}

Connect.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Connect);
