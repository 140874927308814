import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

//import { timeUnits, plainHashes } from "variables/util.jsx";

class WorkersOnline extends React.Component {
  render() {
    const { classes, minerStats, color } = this.props;
    if (typeof minerStats === "undefined") return null;
    const { stats, workersOnline } = minerStats;
    if (typeof stats === "undefined") return null;
    return (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>extension</Icon>
          </CardIcon>
          <p className={classes.cardTitle}><small>blocks found</small>: <b>{typeof stats.blocksFound === "undefined" || stats.blocksFound === null ? 0 : stats.blocksFound}</b></p>
          <p className={classes.cardTitle}><small>workers online</small>: <b>{workersOnline}</b></p>
        </CardHeader>
      </Card>
    );
  }
}

WorkersOnline.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  minerStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(WorkersOnline);
