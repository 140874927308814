import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import { plainHashes, difficulty } from "variables/util.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { getBlockTime } from "../../../variables/util";

class NetworkDifficulty extends React.Component {
  render() {
    const { classes, poolStats, color, selected } = this.props;
    const includeBlockTime = (selected.split('.')[0] !== "verus" && selected.split('.')[0] !== "komodo");
    if (typeof poolStats === "undefined" ||
      typeof poolStats.nodes === "undefined" ||
      poolStats.nodes === null) return null;
    return (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>extension</Icon> {/* memory ac_unit */}
          </CardIcon>
          <p className={classes.cardTitle}><small>network difficulty:</small>{" "}
            <nobr>
              <b>{plainHashes(difficulty(poolStats))}</b>
            </nobr>
          </p>
          <p className={classes.cardTitle}>
            <small>
              {(includeBlockTime ? "block time: " : "")}
            </small>
            {" "}
            <b>
              <font color="#000000">
                {includeBlockTime ? getBlockTime(poolStats).toFixed(2) + "s " : ""}
              </font>
            </b>
          </p>
        </CardHeader>
      </Card>
    );
  }
}

NetworkDifficulty.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(NetworkDifficulty);
