// ##############################
// // // javascript library for creating charts
// #############################
var Chartist = require("chartist");

// ##############################
// // // variables used to create animation on charts
// #############################
// var delays = 80,
//   durations = 500;
// var delays2 = 80,
//   durations2 = 500;

// ##############################
// // // Daily Sales
// #############################

// function timeConverter(UNIX_timestamp) {
//   return timeConverterIntv(UNIX_timestamp, 4)
// }
function timeConverterIntv(UNIX_timestamp, intv) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  // var sec = a.getSeconds();
  // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  //  return time;
  if (((intv % 1 === 0) && (min !== 0)) || hour % intv > 0) {
    return '';
  }
  // if (sec > 2) { // XXX
  //   return '';
  // }
  if (hour + min === 0) {
    return month + '.' + date;
  }
  if ((window.innerWidth <= 959) && (min !== 0)) {
    return '';
  }
  if (min % 20 > 0) {
    return '';
  }
  return hour + ':' + (min > 9 ? '' : '0') + min;
}

// function hourFilter(UNIX_timestamp) {
//   var a = new Date(UNIX_timestamp * 1000);

//   if (a.getMinutes() !== 0) {
//     return false;
//   }
//   return true;
// }

const poolHashRate = (pool) => {
  const maxEntries = window.innerWidth > 959 ? 75 : 25;
  const poolCharts = (typeof pool.poolCharts === "undefined" || pool.poolCharts === null) ? [] : pool.poolCharts.sort((a, b) => b.x - a.x).slice(0, maxEntries);
  const intv = poolCharts.length === 0 ? 1 : poolCharts[0].x - poolCharts[poolCharts.length - 1].x >= 82800 ? 4 : 0.5
  return {
    data: {
      labels:
        poolCharts.reverse()
          //.filter(({ x }) => hourFilter(x))
          .map(({ x }) => timeConverterIntv(x, intv))
      //.reduce((m, i) => m.concat(i), [])
      ,
      series: [
        poolCharts
          .map(({ y }) => y / 1000000000),
        [],
        poolCharts
          .map(({ y2 }) => y2 / 1000000000),
        //.reduce((m, i) => m.concat(i), [])
      ]
    },
    options: {
      showPoint: false,
      // lineSmooth: Chartist.Interpolation.cardinal({
      //   tension: 1
      // }),
      // lineSmooth: Chartist.Interpolation.simple({
      //   divisor: 2,
      // }),
      lineSmooth: Chartist.Interpolation.monotoneCubic({
        fillHoles: false
      }),
      low: 0.8 * Math.min.apply(null, poolCharts.map(i => i.y / 1000000000)),
      high: 1.1 * Math.max.apply(null, poolCharts.map(i => i.y / 1000000000)), // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    animation: {
      draw: (data) => {
        //if (data.type === "line" || data.type === "area") {
        //   data.element.animate({
        //     d: {
        //       begin: 600,
        //       dur: 700,
        //       from: data.path
        //         .clone()
        //         //  .scale(1, 0)
        //         //.translate(0, data.chartRect.height())
        //         .stringify(),
        //       to: data.path.clone().stringify(),
        //       easing: Chartist.Svg.Easing.easeOutQuint
        //     }
        //   });
        // } else
        // if (data.type === "point") {
        //   data.element.animate({
        //     opacity: {
        //       begin: (data.index + 1),// * delays,
        //       dur: durations,
        //       from: 0,
        //       to: 0,
        //       easing: "ease"
        //     }
        //   });
        // }
      }
    }
  }
};

const minerHashRate = miner => {
  let maxEntries = window.innerWidth > 959 ? 75 : 25;
  let mc =
    typeof miner.minerCharts === "undefined" || miner.minerCharts === null
      ? []
      : miner.minerCharts;
  if (miner.currentHashrate + miner.hashrate === 0 && mc.length > 0) {
    for (let i = 0; i < 5; i++) {
      mc.push({ x: Date.now() - i, minerHash: 0, minerLargeHash: 0 });
    }
    maxEntries = 20;
  }
  const minerCharts = mc.sort((a, b) => b.x - a.x).slice(0, maxEntries);
  const intv = minerCharts.length === 0 ? 1 : 4;
  return {
    data: {
      labels: minerCharts
        .reverse()
        .map(({ x }) => timeConverterIntv(x, intv))
      //.reduce((m, i) => m.concat(i), [])
      ,
      series: [
        minerCharts
          .map(({ minerHash }) => minerHash / 1000000000),
        [],
        minerCharts
          .map(({ minerLargeHash }) => minerLargeHash / 1000000000)

        //.reduce((m, i) => m.concat(i), [])
      ]
    },
    options: {
      showPoint: false,
      // lineSmooth: Chartist.Interpolation.cardinal({
      //   tension: 1
      // }),
      // lineSmooth: Chartist.Interpolation.simple({
      //   divisor: 2,
      // }),
      lineSmooth: Chartist.Interpolation.monotoneCubic({
        fillHoles: false
      }),
      low: 0.9 * Math.min.apply(null, minerCharts.map(i => i.minerHash / 1000000000)),
      high: 1.1 * Math.max.apply(null, minerCharts.map(i => i.minerHash / 1000000000)), // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
        //      },
        // },
        // animation: {
        //   draw: (data) => {
        // if (data.type === "line" || data.type === "area") {
        //   data.element.animate({
        //     d: {
        //       begin: 600,
        //       dur: 700,
        //       from: data.path
        //         .clone()
        //         .scale(1, 0)
        //         .translate(0, data.chartRect.height())
        //         .stringify(),
        //       to: data.path.clone().stringify(),
        //       easing: Chartist.Svg.Easing.easeOutQuint
        //     }
        //   });
        // } else
        // if (data.type === "point") {
        //   data.element.animate({
        //     opacity: {
        //       begin: (data.index + 1) * delays,
        //       dur: durations,
        //       from: 0,
        //       to: 0,
        //       easing: "ease"
        //     }
        //   });
        // }
      }
    }
  };
};

const minerPayments = miner => {
  const maxEntries = window.innerWidth > 959 ? 75 : 15;
  const paymentCharts = ((miner.paymentCharts === null) ? [] : miner.paymentCharts).sort((a, b) => b.x - a.x).slice(0, maxEntries);
  return {
    data: {
      labels:
        paymentCharts.reverse()
          // .map(({ x }) => timeConverter(x))
          .map(({ timeFormat }) => { const a = timeFormat.split(" ")[0].split("-"); return parseInt(a[2]) === 1 ? a[1] + " " + a[2] : a[2]; })
      //.reduce((m, i) => m.concat(i), [])
      ,
      series: [
        paymentCharts.map(({ amount }) => amount / 1000000000)
        //.reduce((m, i) => m.concat(i), [])
      ]
    },
    options: {
      axisX: {
        showGrid: false
      },
      low: 0,
      high: 1.1 * Math.max.apply(null, paymentCharts.map(i => i.amount / 1000000000)),
      chartPadding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 0
      }
    },
    responsiveOptions: [
      [
        "screen and (max-width: 640px)",
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            }
          }
        }
      ]
    ],
    // animation: {
    //   draw: function (data) {
    //     if (data.type === "bar") {
    //       data.element.animate({
    //         opacity: {
    //           begin: (data.index + 1) * delays2,
    //           dur: durations2,
    //           from: 0,
    //           to: 1,
    //           easing: "ease"
    //         }
    //       });
    //     }
    //   }
    // }
  };
};


module.exports = {
  minerHashRate,
  minerPayments,
  poolHashRate
};
