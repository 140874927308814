import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import { timeUnits } from "variables/util.jsx";

class LastBeat extends React.Component {
  roundShare() {
    const { classes, minerStats, poolStats } = this.props;
    if (typeof minerStats.roundShares === "number" && typeof poolStats.stats.roundShares === "number") {
      return (<p className={classes.cardTitle}>
        <small>round share</small>: <b>{poolStats.stats.roundShares === 0 ? 100 : Math.round(1000 * minerStats.roundShares / poolStats.stats.roundShares) / 10}{" "}<small>%</small></b>
      </p>)
    }
    return null;
  }
  render() {
    const { classes, color, minerStats, timeRightNow } = this.props;
    return (typeof minerStats === "undefined" || typeof minerStats.stats === "undefined") ? null : (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>hourglass_empty</Icon>
          </CardIcon>
          <p className={classes.cardTitle}><small>last share</small>: <b>{timeUnits((timeRightNow - 1000 * minerStats.stats.lastShare) / 1000)}</b></p>
          {this.roundShare()}
        </CardHeader>
      </Card>
    );
  }
}

LastBeat.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  minerStats: PropTypes.object.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(LastBeat);
