import React, { Component, useMemo } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { hashes, timeUnits } from "variables/util.jsx";

const cpuChains = ["mintme", "webchain", "verus", "aqua"];
const asicChains = ["komodo"];

class PoolListTable extends Component {
  state = {
    sortBy:
      window.location.href.includes("last") ||
        window.location.href.includes("block") ? "lastBlock" : "popularity",
    filtered: [],
    algo: undefined
  };

  getTrBgProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      //console.log(rowInfo)
      return {
        style: {
          background: this.transitionThisRow({ 'value': rowInfo.original }),
          //background: this.transitionThisRow(rowInfo),
          color: 'black'
        },
        onClick: (e, handleOriginal) => {
          //console.log(e)
          if (handleOriginal) {
            handleOriginal();
          }
          window.location.href = "/ui/" + rowInfo.original.name.split(".")[0] + "/connect";
        }
      }
    }
    return {};
  }

  roundVariance(row) {
    const { roundShares } = row.value.stats;
    const difficulty = this.nodeData(row.value).difficulty;
    const val =
      difficulty > 0 ? Math.round((100 * roundShares) / difficulty) : 0;
    return (typeof roundShares === "number") ? (
      <div>
        <nobr>
          {val} <small>%</small>
        </nobr>
      </div>
    ) : null;
  }
  dag(d) {
    const dag = this.nodeData(d).dagSize
    if ((dag == 0) || (typeof dag !== "number")) return "-";
    return (<div><small>DAG:</small> {(((100 * dag / Math.pow(1024, 3))) / 100).toFixed(2)}<small>{" G"}</small></div>);
  }
  transitionThisText = row => "#000000";
  //(this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : '#000000'
  transitionThisRow = row => {
    const since =
      Math.trunc(this.props.timeRightNow / 1000) -
      row.value.stats.lastBlockFound;
    if (since <= 15) {
      return "#00ccff";
    }
    if (since >= 145) {
      return row.backgroundColor;
    }
    const r = Math.trunc(0x00 + (since - 15) * 2);
    const g = 0xcc;
    const b = Math.trunc(0xfe - (since - 15) * 2);
    return "#" + r.toString(16) + g.toString(16) + b.toString(16);
  };
  compareBy(key) {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  sort() {
    const key = this.state.sortBy;
    let arrayCopy = [...this.state.pools];
    arrayCopy.sort(this.compareBy(key));
    this.setState({ pools: arrayCopy });
  }

  sortBy(key) {
    //console.log("REQUEST TO SORT BY " + key)
    this.setState({ sortBy: key });
  }

  getNodeData(value) {
    const node = value.nodes
      .filter(node => value.now / 1000 - parseInt(node.lastBeat) < 120)
      .sort((a, b) => a.lastBeat > b.lastBeat)[0];
    return typeof node !== "undefined" ? node : [];
  }

  nodeData = (input) => useMemo(() => this.getNodeData(input), [input])

  makeChainFilter(_this) {
    return (filter, row) => {
      //console.log(_this.props.info[row._original.name])
      return (row._original.name.split(".")[0].toLowerCase().includes(filter.value.toLowerCase()) || this.props.info[row._original.name].payout.unit.toLowerCase().includes(filter.value.toLowerCase()));
      //return false
    };
  }

  filterInput({ filter, onChange }) {
    return (
      <div style={{ position: 'relative' }}>
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          style={{
            width: '100%',
            backgroundColor: '#cccccc',
            color: 'white',
          }}
        />
        <i style={{ position: 'absolute', right: '10px', lineHeight: '30px' }}>
          search
        </i>
      </div>
    )
  }

  interpretData() {
    const columns = [
      {
        id: "pool",
        filterable: true,
        filterMethod: this.makeChainFilter(this),
        Header: "chain / algo",
        _accessor: "name",
        accessor: d => d,
        //maxWidth: 80,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Filter: this.filterInput,
        Cell: row => (
          // <div
          //   style={{
          //     width: '100%',
          //     height: '100%',
          //     //backgroundColor: '#dadada',
          //     borderRadius: '2px'
          //   }}
          // >
          //   <div
          //     style={{
          //       width: '100%',//`${row.value}%`,
          //       height: '100%',
          //       _backgroundColor: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 15 ? '#00ccff' // '#85cc00'
          //         : (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 60 ? '#85cc00' // '#ffbf00'
          //           : row.backgroundColor, //'#ff2e00',

          //       //backgroundColor: self.transitionThisRow(row),
          //       //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
          //       borderRadius: '2px',
          //       transition: 'all .2s ease-out'
          //     }}
          //   >
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/connect"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        <big><b>{row.value.name.split(".")[0]}</b></big>
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/connect"}>
                      <font color='black'>
                        {this.props.info[row.value.name].algo.name}
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
          //   </div>
          // </div>
        )
        // Cell: row => (
        //   <span>
        //     <span style={{
        //       color: row.value === 'relationship' ? '#ff2e00'
        //         : row.value === 'complicated' ? '#ffbf00'
        //         : '#57d500',
        //       transition: 'all .3s ease'
        //     }}>
        //       &#x25cf;
        //     </span> {
        //       row.value === 'relationship' ? 'In a relationship'
        //       : row.value === 'complicated' ? `It's complicated`
        //       : 'Single'
        //     }
        //   </span>
        // )
      },
      {
        id: "connect",
        filterable: false,
        Header: "pool net share",
        accessor: d => d,
        //maxWidth: 85,
        // width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          // <div
          //   style={{
          //     width: '100%',
          //     height: '100%',
          //     //backgroundColor: '#dadada',
          //     borderRadius: '2px'
          //   }}
          // >
          //   <div
          //     style={{
          //       width: '100%',//`${row.value}%`,
          //       height: '100%',
          //       _backgroundColor: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 15 ? '#00ccff' // '#85cc00'
          //         : (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 60 ? '#85cc00' // '#ffbf00'
          //           : row.backgroundColor, //'#ff2e00',

          //       //backgroundColor: self.transitionThisRow(row),
          //       //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
          //       borderRadius: '2px',
          //       transition: 'all .2s ease-out'
          //     }}
          //   >
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/connect"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        {row.value.networkHashrate > row.value.hashrate ? Math.round(10000 * row.value.hashrate / row.value.networkHashrate) / 100 : 100}<small>{" %"}</small>
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/connect"}>
                      <font color='black'>
                        <small>CONNECT</small>
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
          //   </div>
          // </div>
        )
      },
      {
        id: "miners",
        filterable: false,
        Header: "miners / method",
        _accessor: "name",
        accessor: d => d,
        //maxWidth: 70,
        // width: 100,
        // rowSpan: function (params) {
        //   return 2;//true ? 2 : 1;
        // },
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          // <div
          //   style={{
          //     width: '100%',
          //     height: '100%',
          //     //backgroundColor: '#dadada',
          //     borderRadius: '2px'
          //   }}
          // >
          //   <div
          //     style={{
          //       width: '100%',//`${row.value}%`,
          //       height: '100%',
          //       _backgroundColor: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 15 ? '#00ccff' // '#85cc00'
          //         : (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 60 ? '#85cc00' // '#ffbf00'
          //           : row.backgroundColor, //'#ff2e00',

          //       //backgroundColor: self.transitionThisRow(row),
          //       //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
          //       borderRadius: '2px',
          //       transition: 'all .2s ease-out'
          //     }}
          //   >
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/miners"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/miners"}>
                      <font color='black'>
                        {row.value.minersTotal} <small>miner{(row.value.minersTotal == 1 ? "" : "s")}</small>
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/miners"}>
                      <font color='black'>
                        <small>{this.props.info[row.value.name].payout.method}</small>
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
          //   </div>
          // </div>
        )
        // Cell: row => (
        //   <span>
        //     <span style={{
        //       color: row.value === 'relationship' ? '#ff2e00'
        //         : row.value === 'complicated' ? '#ffbf00'
        //         : '#57d500',
        //       transition: 'all .3s ease'
        //     }}>
        //       &#x25cf;
        //     </span> {
        //       row.value === 'relationship' ? 'In a relationship'
        //       : row.value === 'complicated' ? `It's complicated`
        //       : 'Single'
        //     }
        //   </span>
        // )
      },
      {
        id: "hashrate",
        filterable: false,
        Header: "pool / net hashrate",
        accessor: d => d,
        //maxWidth: 85,
        // width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          // <div
          //   style={{
          //     width: '100%',
          //     height: '100%',
          //     //backgroundColor: '#dadada',
          //     borderRadius: '2px'
          //   }}
          // >
          //   <div
          //     style={{
          //       width: '100%',//`${row.value}%`,
          //       height: '100%',
          //       _backgroundColor: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 15 ? '#00ccff' // '#85cc00'
          //         : (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 60 ? '#85cc00' // '#ffbf00'
          //           : row.backgroundColor, //'#ff2e00',

          //       //backgroundColor: self.transitionThisRow(row),
          //       //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
          //       borderRadius: '2px',
          //       transition: 'all .2s ease-out'
          //     }}
          //   >
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        {hashes(row.value.hashrate)}
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        {hashes(row.value.networkHashrate)}
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
          //   </div>
          // </div>
        )
      },
      {
        id: "payoutfee",
        filterable: false,
        Header: "min payout / fee",
        _accessor: "name",
        accessor: d => d,
        //maxWidth: 70,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/payments"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/payments"}>
                      <font color='black'>
                        {this.props.info[row.value.name].payout.size}{" "}{this.props.info[row.value.name].payout.unit}
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/payments"}>
                      <font color='black'>
                        {"" + (100 * this.props.info[row.value.name].payout.poolFee)}<small>% fee</small>
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
        )
      },
      {
        id: "difficulty",
        filterable: false,
        Header: "diff / variance",
        accessor: d => d,
        //maxWidth: 80,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          // <div
          //   style={{
          //     width: '100%',
          //     height: '100%',
          //     //backgroundColor: '#dadada',
          //     borderRadius: '2px'
          //   }}
          // >
          //   <div
          //     style={{
          //       width: '100%',//`${row.value}%`,
          //       height: '100%',
          //       _backgroundColor: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 15 ? '#00ccff' // '#85cc00'
          //         : (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 60 ? '#85cc00' // '#ffbf00'
          //           : row.backgroundColor, //'#ff2e00',

          //       //backgroundColor: self.transitionThisRow(row),
          //       //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
          //       borderRadius: '2px',
          //       transition: 'all .2s ease-out'
          //     }}
          //   >

          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        {
                          hashes(this.nodeData(row.value).difficulty
                            //   Math.max(
                            //     parseInt(row.value.nodes === null ? 0 :
                            //       row.value.nodes
                            //         .filter(node => row.value.now / 1000 - parseInt(node.lastBeat) < 120)
                            //         .map(({ difficulty }) => difficulty)
                            //         .reduce((m, i) => m.concat(i), [])
                            //     )
                            //   )
                          )
                        }
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        {this.roundVariance(row)}
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>



          //   </div>
          // </div>
        )
      },
      {
        id: "blockHeight",
        filterable: false,
        Header: "block height / DAG size",
        accessor: d => d,
        //maxWidth: 80,
        // width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          // <div
          //   style={{
          //     width: '100%',
          //     height: '100%',
          //     //backgroundColor: '#dadada',
          //     borderRadius: '2px'
          //   }}
          // >
          //   <div
          //     style={{
          //       width: '100%',//`${row.value}%`,
          //       height: '100%',
          //       _backgroundColor: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 15 ? '#00ccff' // '#85cc00'
          //         : (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 60 ? '#85cc00' // '#ffbf00'
          //           : row.backgroundColor, //'#ff2e00',

          //       //backgroundColor: self.transitionThisRow(row),
          //       //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
          //       borderRadius: '2px',
          //       transition: 'all .2s ease-out'
          //     }}
          //   >
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                      <font color='black'>
                        {this.nodeData(row.value).height
                          //   Math.max(
                          //   parseInt(row.value.nodes === null ? 0 :
                          //     row.value.nodes
                          //       .filter(node => row.value.now / 1000 - parseInt(node.lastBeat) < 120)
                          //       .map(({ height }) => height)
                          //       .reduce((m, i) => m.concat(i), [])
                          //   )
                          // )
                        }
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        {this.dag(row.value)}
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>




          //   </div>
          // </div>
        )
      },
      // {
      //   id: "dagSize",
      //   Header: "DAG size",
      //   accessor: d => d,
      //   maxWidth: 70,
      //   headerStyle: { fontSize: "7pt" },
      //   style: { textAlign: "center" },
      //   Cell: row => (
      //     // <div
      //     //   style={{
      //     //     width: '100%',
      //     //     height: '100%',
      //     //     //backgroundColor: '#dadada',
      //     //     borderRadius: '2px'
      //     //   }}
      //     // >
      //     //   <div
      //     //     style={{
      //     //       width: '100%',//`${row.value}%`,
      //     //       height: '100%',
      //     //       _backgroundColor: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 15 ? '#00ccff' // '#85cc00'
      //     //         : (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 60 ? '#85cc00' // '#ffbf00'
      //     //           : row.backgroundColor, //'#ff2e00',

      //     //       //backgroundColor: self.transitionThisRow(row),
      //     //       //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
      //     //       borderRadius: '2px',
      //     //       transition: 'all .2s ease-out'
      //     //     }}
      //     //   >
      //     <font color='black'>
      //       {this.dag(row.value)}
      //     </font>
      //     //   </div>
      //     // </div>
      //   )
      // },
      // {
      //   id: "lastBlockFound",
      //   Header: "last block found",
      //   accessor: d => d,
      //   //   <a href={"/ui/" + d.name.split(".")[0] + "/blocks"}>
      //   //     {timeUnits((this.props.timeRightNow - d.stats.lastBlockFound * 1000) / 1000)}
      //   //   </a>,
      //   maxWidth: 90,
      //   headerStyle: { fontSize: "7pt" },
      //   style: { textAlign: "center" },
      //   Cell: row => (
      //     // <div
      //     //   style={{
      //     //     width: '100%',
      //     //     height: '100%',
      //     //     //backgroundColor: '#dadada',
      //     //     borderRadius: '2px'
      //     //   }}
      //     // >
      //     //   <div
      //     //     style={{
      //     //       width: '100%',//`${row.value}%`,
      //     //       height: '100%',
      //     //       _backgroundColor: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 15 ? '#00ccff' // '#85cc00'
      //     //         : (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 60 ? '#85cc00' // '#ffbf00'
      //     //           : row.backgroundColor, //'#ff2e00',

      //     //       //backgroundColor: self.transitionThisRow(row),
      //     //       //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
      //     //       borderRadius: '2px',
      //     //       transition: 'all .2s ease-out'
      //     //     }}
      //     //   >
      //     <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
      //       <font color='black'>
      //         {timeUnits((this.props.timeRightNow - row.value.stats.lastBlockFound * 1000) / 1000)}
      //       </font>
      //     </a>
      //     //   </div>
      //     // </div>
      //   )
      // },
      {
        id: "blocks",
        filterable: false,
        Header: "blocks",
        _accessor1: "candidatesTotal",
        _accessor2: "immatureTotal",
        accessor: d => d,
        //maxWidth: 85,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          // <div
          //   style={{
          //     width: '100%',
          //     height: '100%',
          //     //backgroundColor: '#dadada',
          //     borderRadius: '2px'
          //   }}
          // >
          //   <div
          //     style={{
          //       width: '100%',//`${row.value}%`,
          //       height: '100%',
          //       _backgroundColor: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 15 ? '#00ccff' // '#85cc00'
          //         : (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 60 ? '#85cc00' // '#ffbf00'
          //           : row.backgroundColor, //'#ff2e00',

          //       //backgroundColor: self.transitionThisRow(row),
          //       //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
          //       borderRadius: '2px',
          //       transition: 'all .2s ease-out'
          //     }}
          //   >


          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                      <font color='black'>
                        {row.value.immatureTotal} <small>pending</small>
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                      <font color='black'>
                        + {row.value.candidatesTotal} <small>new</small>
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>




          //   </div>
          // </div>
        )
      },
      {
        id: "lastPayment",
        filterable: false,
        Header: "last block found / payout",
        _accessor: "lastPayment",
        accessor: d => d,
        //maxWidth: 75,
        minWidth: 140,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          // <div
          //   style={{
          //     width: '100%',
          //     height: '100%',
          //     //backgroundColor: '#dadada',
          //     borderRadius: '2px'
          //   }}
          // >
          //   <div
          //     style={{
          //       width: '100%',//`${row.value}%`,
          //       height: '100%',
          //       _backgroundColor: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 15 ? '#00ccff' // '#85cc00'
          //         : (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 60 ? '#85cc00' // '#ffbf00'
          //           : row.backgroundColor, //'#ff2e00',

          //       //backgroundColor: self.transitionThisRow(row),
          //       //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
          //       borderRadius: '2px',
          //       transition: 'all .2s ease-out'
          //     }}
          //   >

          <div align="center">
            <tbody align="center" style={{ align: "center", textAlign: "center" }}>
              <tr>
                <td>
                  <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                    <font color='black'>
                      <small>last block: </small>{timeUnits((this.props.timeRightNow - row.value.stats.lastBlockFound * 1000) / 1000)}
                    </font>
                  </a>
                </td>
              </tr>
              <tr><hr /></tr>
              <tr>
                <td>
                  <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                    <div>
                      {(
                        typeof row.value.lastPayment === "undefined") ? "" : (<a href={"/ui/" + row.value.name.split(".")[0] + "/payments"}>
                          <font color='black'>
                            <small>last paid: </small>{row.value.lastPayment === 0 ? "none" : timeUnits((this.props.timeRightNow - row.value.lastPayment * 1000) / 1000)}
                          </font>
                        </a>
                        )}
                    </div>
                  </a>
                </td>
              </tr>
            </tbody>
          </div>






          //   </div>
          // </div>
        )
      },
      {
        width: 20,
        id: "end"
      }
    ];

    return { pools: this.sortPools(), columns: columns };
  }

  interpretLessData() {

    const columns = [
      {
        id: "pool",
        filterable: true,
        filterMethod: this.makeChainFilter(this),
        Header: "chain / algo",
        _accessor: "name",
        accessor: d => d,
        //maxWidth: 80,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/connect"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        <big><b>{row.value.name.split(".")[0]}</b></big>
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/connect"}>
                      <font color='black'>
                        {this.props.info[row.value.name].algo.name}
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
        )
      },
      // {
      //   id: "hashrate",
      //   Header: "pool / net hashrate",
      //   accessor: d => d,
      //   //maxWidth: 85,
      //   width: 100,
      //   headerStyle: { fontSize: "8pt" },
      //   style: { textAlign: "center" },
      //   Cell: row => (
      //     <div align="center">
      //       <tbody align="center" style={{ align: "center", textAlign: "center" }}>
      //         <tr>
      //           <td>
      //             <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
      //               <font color='black'>
      //                 {hashes(row.value.hashrate)}
      //               </font>
      //             </a>
      //           </td>
      //         </tr>
      //         <tr><hr /></tr>
      //         <tr>
      //           <td>
      //             <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
      //               <font color='black'>
      //                 {hashes(row.value.networkHashrate)}
      //               </font>
      //             </a>
      //           </td>
      //         </tr>
      //       </tbody>
      //     </div>
      //   )
      // },
      {
        id: "miners",
        filterable: false,
        Header: "miners / method",
        _accessor: "name",
        accessor: d => d,
        //maxWidth: 70,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/miners"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/miners"}>
                      <font color='black'>
                        {row.value.minersTotal} <small>miner{(row.value.minersTotal == 1 ? "" : "s")}</small>
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/miners"}>
                      <font color='black'>
                        <small>{this.props.info[row.value.name].payout.method}</small>
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
        )
      },
      {
        id: "hashrate",
        filterable: false,
        Header: "pool / net hashrate",
        accessor: d => d,
        //maxWidth: 85,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        {hashes(row.value.hashrate)}
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        {hashes(row.value.networkHashrate)}
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
        )
      },
      {
        id: "blockHeight",
        filterable: false,
        Header: "block height / DAG size",
        accessor: d => d,
        //maxWidth: 80,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                      <font color='black'>
                        {this.nodeData(row.value).height
                        }
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        {this.dag(row.value)}
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
        )
      },
      {
        id: "blocks",
        filterable: false,
        Header: "blocks",
        _accessor1: "candidatesTotal",
        _accessor2: "immatureTotal",
        accessor: d => d,
        //maxWidth: 85,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                      <font color='black'>
                        {row.value.immatureTotal} <small>pending</small>
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                      <font color='black'>
                        + {row.value.candidatesTotal} <small>new</small>
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
        )
      },
      {
        id: "lastPayment",
        filterable: false,
        Header: "last block found / payout",
        _accessor: "lastPayment",
        accessor: d => d,
        //maxWidth: 75,
        //width: 140,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <tbody align="center" style={{ align: "center", textAlign: "center" }}>
              <tr>
                <td>
                  <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                    <font color='black'>
                      <small>last block: </small>{timeUnits((this.props.timeRightNow - row.value.stats.lastBlockFound * 1000) / 1000)}
                    </font>
                  </a>
                </td>
              </tr>
              <tr><hr /></tr>
              <tr>
                <td>
                  {(
                    typeof row.value.lastPayment === "undefined") ? "" : (<a href={"/ui/" + row.value.name.split(".")[0] + "/payments"}>
                      <font color='black'>
                        <small>last paid: </small>{row.value.lastPayment === 0 ? "none" : timeUnits((this.props.timeRightNow - row.value.lastPayment * 1000) / 1000)}
                      </font>
                    </a>
                    )}
                </td>
              </tr>
            </tbody>
          </div>
        )
      },
      {
        width: 20,
        id: "end"
      }
    ];

    return { pools: this.sortPools(), columns: columns };
  }

  interpretMuchLessData() {

    const columns = [
      {
        id: "pool",
        filterable: true,
        filterMethod: this.makeChainFilter(this),
        //String(row[filter.id]).includes(filter.value),
        Header: "chain / miners",
        _accessor: "name",
        accessor: d => d,
        //maxWidth: 80,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/connect"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/connect"}>
                      <font color='black'>
                        <big><b>{row.value.name.split(".")[0]}</b></big>
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/miners"}>
                      <font color='black'>
                        {row.value.minersTotal} <small>miner{(row.value.minersTotal == 1 ? "" : "s")}</small>
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
        )
      },
      // {
      //   id: "miners",
      //   Header: "miners / method",
      //   _accessor: "name",
      //   accessor: d => d,
      //   //maxWidth: 70,
      //   //width: 100,
      //   headerStyle: { fontSize: "8pt" },
      //   style: { textAlign: "center" },
      //   Cell: row => (
      //     <div align="center">
      //       <a href={"/ui/" + row.value.name.split(".")[0] + "/miners"}>
      //         <tbody align="center" style={{ align: "center", textAlign: "center" }}>
      //           <tr>
      //             <td>
      //               <a href={"/ui/" + row.value.name.split(".")[0] + "/miners"}>
      //                 <font color='black'>
      //                   {row.value.minersTotal} <small>miner{(row.value.minersTotal == 1 ? "" : "s")}</small>
      //                 </font>
      //               </a>
      //             </td>
      //           </tr>
      //           <tr><hr /></tr>
      //           <tr>
      //             <td>
      //               <a href={"/ui/" + row.value.name.split(".")[0] + "/miners"}>
      //                 <font color='black'>
      //                   <small>{this.props.info[row.value.name].payout.method}</small>
      //                 </font>
      //               </a>
      //             </td>
      //           </tr>
      //         </tbody>
      //       </a>
      //     </div>
      //   )
      // },
      {
        id: "hashrate",
        filterable: false,
        Header: "pool / net hashrate",
        accessor: d => d,
        //maxWidth: 85,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <tbody align="center" style={{ align: "center", textAlign: "center" }}>
              <tr>
                <td>
                  <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                    <font color='black'>
                      {hashes(row.value.hashrate)}
                    </font>
                  </a>
                </td>
              </tr>
              <tr><hr /></tr>
              <tr>
                <td>
                  <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                    <font color='black'>
                      {hashes(row.value.networkHashrate)}
                    </font>
                  </a>
                </td>
              </tr>
            </tbody>
          </div>
        )
      },
      // {
      //   id: "blockHeight",
      //   Header: "block height / DAG size",
      //   accessor: d => d,
      //   //maxWidth: 80,
      //   //width: 100,
      //   headerStyle: { fontSize: "8pt" },
      //   style: { textAlign: "center" },
      //   Cell: row => (
      //     <div align="center">
      //       <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
      //         <tbody align="center" style={{ align: "center", textAlign: "center" }}>
      //           <tr>
      //             <td>
      //               <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
      //                 <font color='black'>
      //                   {this.nodeData(row.value).height
      //                   }
      //                 </font>
      //               </a>
      //             </td>
      //           </tr>
      //           <tr><hr /></tr>
      //           <tr>
      //             <td>
      //               <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
      //                 <font color='black'>
      //                   {this.dag(row.value)}
      //                 </font>
      //               </a>
      //             </td>
      //           </tr>
      //         </tbody>
      //       </a>
      //     </div>
      //   )
      // },
      // {
      //   id: "blocks",
      //   Header: "blocks",
      //   _accessor1: "candidatesTotal",
      //   _accessor2: "immatureTotal",
      //   accessor: d => d,
      //   //maxWidth: 85,
      //   //width: 100,
      //   headerStyle: { fontSize: "8pt" },
      //   style: { textAlign: "center" },
      //   Cell: row => (
      //     <div align="center">
      //       <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
      //         <tbody align="center" style={{ align: "center", textAlign: "center" }}>
      //           <tr>
      //             <td>
      //               <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
      //                 <font color='black'>
      //                   {row.value.immatureTotal} <small>pending</small>
      //                 </font>
      //               </a>
      //             </td>
      //           </tr>
      //           <tr><hr /></tr>
      //           <tr>
      //             <td>
      //               <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
      //                 <font color='black'>
      //                   + {row.value.candidatesTotal} <small>new</small>
      //                 </font>
      //               </a>
      //             </td>
      //           </tr>
      //         </tbody>
      //       </a>
      //     </div>
      //   )
      // },
      {
        id: "lastBlock",
        filterable: false,
        Header: "block height / pending",
        _accessor1: "candidatesTotal",
        _accessor2: "immatureTotal",
        accessor: d => d,
        //maxWidth: 85,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                      <font color='black'>
                        {this.nodeData(row.value).height}
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                      <font color='black'>
                        {row.value.immatureTotal + row.value.candidatesTotal} <small>pending</small>
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
        )
      },
      // {
      //   id: "lastPayment",
      //   Header: "last block found / payout",
      //   _accessor: "lastPayment",
      //   accessor: d => d,
      //   //maxWidth: 75,
      //   //width: 140,
      //   headerStyle: { fontSize: "8pt" },
      //   style: { textAlign: "center" },
      //   Cell: row => (
      //     <div align="center">
      //       <tbody align="center" style={{ align: "center", textAlign: "center" }}>
      //         <tr>
      //           <td>
      //             <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
      //               <font color='black'>
      //                 <small>last block: </small>{timeUnits((this.props.timeRightNow - row.value.stats.lastBlockFound * 1000) / 1000)}
      //               </font>
      //             </a>
      //           </td>
      //         </tr>
      //         <tr><hr /></tr>
      //         <tr>
      //           <td>
      //             <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
      //               <div>
      //                 {(
      //                   typeof row.value.lastPayment === "undefined") ? "" : (<a href={"/ui/" + row.value.name.split(".")[0] + "/payments"}>
      //                     <font color='black'>
      //                       <small>last paid: </small>{row.value.lastPayment === 0 ? "none" : timeUnits((this.props.timeRightNow - row.value.lastPayment * 1000) / 1000)}
      //                     </font>
      //                   </a>
      //                   )}
      //               </div>
      //             </a>
      //           </td>
      //         </tr>
      //       </tbody>
      //     </div>
      //   )
      // },
      {
        id: "lastPayment",
        filterable: false,
        Header: "last block found / payout",
        _accessor: "lastPayment",
        accessor: d => d,
        //maxWidth: 75,
        //width: 140,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <tbody align="center" style={{ align: "center", textAlign: "center" }}>
              <tr>
                <td>
                  <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                    <font color='black'>
                      <small>last block: </small>{timeUnits((this.props.timeRightNow - row.value.stats.lastBlockFound * 1000) / 1000)}
                    </font>
                  </a>
                </td>
              </tr>
              <tr><hr /></tr>
              <tr>
                <td>
                  {(
                    typeof row.value.lastPayment === "undefined") ? "" : (<a href={"/ui/" + row.value.name.split(".")[0] + "/payments"}>
                      <font color='black'>
                        <small>last paid: </small>{row.value.lastPayment === 0 ? "none" : timeUnits((this.props.timeRightNow - row.value.lastPayment * 1000) / 1000)}
                      </font>
                    </a>
                    )}
                </td>
              </tr>
            </tbody>
          </div>
        )
      },
      {
        width: 20,
        id: "end"
      }
    ];

    return { pools: this.sortPools(), columns: columns };
  }

  interpretEvenMuchLessData() {

    const columns = [
      {
        id: "pool",
        filterable: true,
        filterMethod: this.makeChainFilter(this),
        //String(row[filter.id]).includes(filter.value),
        Header: "chain / miners",
        _accessor: "name",
        accessor: d => d,
        //maxWidth: 80,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/connect"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/connect"}>
                      <font color='black'>
                        <big><b>{row.value.name.split(".")[0]}</b></big>
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/miners"}>
                      <font color='black'>
                        {row.value.minersTotal} <small>miner{(row.value.minersTotal == 1 ? "" : "s")}</small>
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
        )
      },
      {
        id: "hashrate",
        filterable: false,
        Header: "hashrate / height",
        accessor: d => d,
        //maxWidth: 85,
        //width: 100,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
              <tbody align="center" style={{ align: "center", textAlign: "center" }}>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/stats"}>
                      <font color='black'>
                        {hashes(row.value.hashrate)}
                      </font>
                    </a>
                  </td>
                </tr>
                <tr><hr /></tr>
                <tr>
                  <td>
                    <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                      <font color='black'>
                        {this.nodeData(row.value).height}
                      </font>
                    </a>
                  </td>
                </tr>
              </tbody>
            </a>
          </div>
        )
      },
      {
        id: "lastPayment",
        filterable: false,
        Header: "last block found / payout",
        _accessor: "lastPayment",
        accessor: d => d,
        //maxWidth: 75,
        //width: 140,
        headerStyle: { fontSize: "8pt" },
        style: { textAlign: "center" },
        Cell: row => (
          <div align="center">
            <tbody align="center" style={{ align: "center", textAlign: "center" }}>
              <tr>
                <td>
                  <a href={"/ui/" + row.value.name.split(".")[0] + "/blocks"}>
                    <font color='black'>
                      <small>last block: </small>{timeUnits((this.props.timeRightNow - row.value.stats.lastBlockFound * 1000) / 1000)}
                    </font>
                  </a>
                </td>
              </tr>
              <tr><hr /></tr>
              <tr>
                <td>
                  {(
                    typeof row.value.lastPayment === "undefined") ? "" : (<a href={"/ui/" + row.value.name.split(".")[0] + "/payments"}>
                      <font color='black'>
                        <small>last paid: </small>{row.value.lastPayment === 0 ? "none" : timeUnits((this.props.timeRightNow - row.value.lastPayment * 1000) / 1000)}
                      </font>
                    </a>
                    )}
                </td>
              </tr>
            </tbody>
          </div>
        )
      },
      {
        width: 20,
        id: "end"
      }
    ];

    return { pools: this.sortPools(), columns: columns };
  }

  sortPools() {
    const unsorted = (typeof this.props.payments === "undefined" || this.props.payments === null || this.props.payments.length === 0) ? Object.entries(this.props.poolStats) : Object.keys(this.props.poolStats).map(k => (
      [
        k,
        {
          ...this.props.poolStats[k],
          lastPayment: Math.max(parseInt((
            typeof this.props.payments[k] === "undefined" ||
              typeof this.props.payments[k].payments === "undefined" ||
              this.props.payments[k].payments === null ? [] :
              this.props.payments[k].payments
          ).map(({ timestamp }) => timestamp)))
        }
      ]
    ))

    let filtered = [];
    if (typeof this.state.algo !== "undefined" && this.state.algo !== undefined) {
      if (this.state.algo == "CPU") {
        filtered = unsorted.filter(a => {
          return cpuChains.includes(a[0].split(".")[0].split("-")[0]);
        });
      } else if (this.state.algo == "GPU") {
        filtered = unsorted.filter(a => {
          return !asicChains.includes(a[0].split(".")[0].split("-")[0]);
        });
      } else {
        //console.log("this.state.algo = " + this.state.algo)
        filtered = unsorted.filter(a => {
          //console.log("a[0] (" + a[0] + "), this.props.info[a[0]].algo.name (" + this.props.info[a[0]].algo.name + ") == this.state.algo (" + this.state.algo + ")")
          return this.props.info[a[0]].algo.name == this.state.algo
        });
      }
    } else {
      filtered = unsorted;
    }

    let pools = [];
    //console.log("they want to sort by ", this.state.sortBy)
    switch (this.state.sortBy) {
      case "pool":
        pools = filtered.sort((a, b) => b[0].split(".")[0] < a[0].split(".")[0] ? 1 : -1);
        break;
      case "-pool":
        pools = filtered.sort((b, a) => b[0].split(".")[0] < a[0].split(".")[0] ? 1 : -1);
        break;
      case "connect":
        pools = filtered.sort((a, b) => b[1].hashrate / b[1].networkHashrate > a[1].hashrate / a[1].networkHashrate ? 1 : -1)
        break;
      case "miners":
        pools = filtered.sort((a, b) => b[1].minersTotal - a[1].minersTotal);
        break;
      case "-miners":
        pools = filtered.sort((b, a) => b[1].minersTotal - a[1].minersTotal);
        break;
      case "hashrate":
        pools = filtered.sort((a, b) => b[1].hashrate - a[1].hashrate);
        break;
      case "-hashrate":
        pools = filtered.sort((a, b) => b[1].networkHashrate - a[1].networkHashrate);
        break;
      // begin huh
      case "difficulty":
        pools = filtered.sort((a, b) => parseInt(this.nodeData(b[1]).difficulty) - parseInt(this.nodeData(a[1]).difficulty));
        break;
      case "-difficulty":
        pools = filtered.sort((b, a) => parseInt(this.nodeData(b[1]).difficulty) - parseInt(this.nodeData(a[1]).difficulty));
        break;
      case "blockHeight":
        pools = filtered.sort((a, b) => parseInt(this.nodeData(b[1]).height) - parseInt(this.nodeData(a[1]).height));
        break;
      case "-blockHeight":
        pools = filtered.sort((b, a) => parseInt(this.nodeData(b[1]).height) - parseInt(this.nodeData(a[1]).height));
        break;
      case "blocks":
        pools = filtered.sort((a, b) => (b[1].candidatesTotal + b[1].immatureTotal) - (a[1].candidatesTotal + a[1].immatureTotal));
        break;
      case "-blocks":
        pools = filtered.sort((b, a) => (b[1].candidatesTotal + b[1].immatureTotal) - (a[1].candidatesTotal + a[1].immatureTotal));
        break;
      // end huh
      case "lastBlock":
      case "lastPayment":
        pools = filtered.sort((a, b) => b[1].stats.lastBlockFound - a[1].stats.lastBlockFound);
        break;
      case "-lastBlock":
        pools = filtered.sort((b, a) => b[1].stats.lastBlockFound - a[1].stats.lastBlockFound);
        break;
      case "-lastPayment":
        pools = filtered.sort((a, b) => b[1].lastPayment - a[1].lastPayment);
        break;
      case "popularity":
      default:
        pools = filtered.sort((a, b) => b[1].hashrate - a[1].hashrate).sort((a, b) => b[1].minersTotal - a[1].minersTotal);
    }

    //this.setState({ pools: pools, columns: columns })
    return pools;
  }

  selectAlgo = algo => {
    return () => {
      if (algo == "all") {
        this.setState({ filtered: [], algo: undefined });
      } else {
        this.setState({ filtered: [], algo: algo });
      }
    };
  };

  tableKey() {

    let algoMap = {}
    Object.keys(this.props.info).map(k => this.props.info[k].algo.name).forEach(a => {
      algoMap[a] = true
    })

    const algos = Object.keys(algoMap).map(k => k).sort((a, b) => a < b ? -1 : 1)

    //console.log(algos)

    return (
      <table
        width="100%"
        onClick={() => {
          if (this.state.sortBy == "lastBlock") {
            this.sortBy("-lastBlock");
          } else {
            this.sortBy("lastBlock");
          }
        }}>
        <tr align="center">
          <td colspan={3}>
            algo selection:
          </td>
        </tr>
        <tr align="center">
          <td colspan={3}>
            <button onClick={
              this.selectAlgo("all")
            }>all</button>
            {["CPU", "GPU", ...algos].map(a => (<button style={{ 'margin-left': '10px' }} onClick={this.selectAlgo(a)}>{a}</button>))}
          </td>
        </tr>
        <tr align="center">
          <td colspan={3}>
            <div
              style={{
                width: '100%',
                height: '100%',
                //backgroundColor: '#dadada',
                borderRadius: '2px'
              }}
            >
              <div
                style={{
                  width: '100%',//`${row.value}%`,
                  height: '100%',
                  backgroundColor: '#d8d8d8',
                  //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
                  borderRadius: '2px',
                  transition: 'all .2s ease-out'
                }}
              ><small>
                  {/* <nobr> */}
                  KEY: Highlight color indicates
                <wbr />
                  {" "}recently found blocks.
                    <wbr />
                  {" "}(Most) columns are sortable -
                    <wbr />
                  {" "}click the headings!
                    <wbr />
                  {" "}Click a statistic for
                    <wbr />
                  {" "}more pool details.
                  {/* </nobr> */}
                </small>
              </div>
            </div>
          </td>
        </tr>
        <tr align="center">
          <td width="33%">
            <div
              style={{
                width: '100%',
                height: '100%',
                //backgroundColor: '#dadada',
                borderRadius: '2px'
              }}
            >
              <div
                style={{
                  width: '100%',//`${row.value}%`,
                  height: '100%',
                  backgroundColor: '#00ccff',
                  //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
                  borderRadius: '2px',
                  transition: 'all .2s ease-out'
                }}
              >
                <small>
                  JUST NOW
                </small>
              </div>
            </div>
          </td>
          <td width="33%">
            <div
              style={{
                width: '100%',
                height: '100%',
                //backgroundColor: '#dadada',
                borderRadius: '2px'
              }}
            >
              <div
                style={{
                  width: '100%',//`${row.value}%`,
                  height: '100%',
                  backgroundColor: '#80cc80',
                  //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
                  borderRadius: '2px',
                  transition: 'all .2s ease-out'
                }}
              >
                <small>
                  A MINUTE AGO
                </small>
              </div>
            </div>
          </td>
          <td width="33%">
            <div
              style={{
                width: '100%',
                height: '100%',
                //backgroundColor: '#dadada',
                borderRadius: '2px'
              }}
            >
              <div
                style={{
                  width: '100%',//`${row.value}%`,
                  height: '100%',
                  backgroundColor: '#ffcc00',
                  //color: (this.props.timeRightNow / 1000 - row.value.stats.lastBlockFound) < 100 ? '#ffffff' : row.color,
                  borderRadius: '2px',
                  transition: 'all .2s ease-out'
                }}
              >
                <small>
                  TWO MINUTES AGO
                </small>
              </div>
            </div>
          </td>
        </tr>
      </table >
    );
  }
  render() {
    const { columns, pools } = (window.innerWidth <= 459) ? this.interpretEvenMuchLessData() : (window.innerWidth <= 759) ? this.interpretMuchLessData() : (window.innerWidth <= 1159) ? this.interpretLessData() : this.interpretData();
    if (
      typeof pools === "undefined" ||
      typeof pools[0] === "undefined" ||
      typeof pools[0][1].nodes === "undefined" ||
      pools[0][1].nodes === null
    ) {
      return null;
    }

    const { sortBy } = this.state;
    return (
      <div>
        {this.tableKey()}
        <ReactTable
          columns={columns}
          data={pools}
          resolveData={data => data.map(row => ({ ...row[1], name: row[0] }))}
          defaultPageSize={pools.length}
          pageSize={pools.length}
          showPagination={false}
          showPageSizeOptions={false}
          className="-striped -highlight"
          getTrProps={this.getTrBgProps}
          filtered={this.state.filtered}
          onFilteredChange={filtered => { this.setState({ filtered }); }}
          suppressRowTransform={true}
          getTheadThProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                const other = "-" + column.id;
                //console.log("currently sortBy is '" + sortBy + "', column.id is '" + column.id + "', equal? " + (sortBy == column.id) ? "yes" : "no" + " other would be: " + other)
                if (sortBy == column.id) {
                  this.sortBy(other);
                } else {
                  this.sortBy(column.id);
                }
                // console.log(e)
                // console.log("A header Element was clicked!");
                //console.log("it produced this event:", e);
                // console.log("It was in this column:", column);
                // console.log("It was in this row:", rowInfo);
                // console.log("It was in this table instance:", instance);

                // if (handleOriginal) {
                //   handleOriginal();
                // }
              }
            }
          }}


        //getTrProps={(state, rowInfo, column, instance) => { }}
        //TrComponent={`<tr></tr>`}

        //TdComponent={`<td onMouseOver='this.style.backgroundColor="#000000"' onMouseOut='this.style.backgroundColor="#333300"'></td>`}
        //getTrProps={(state, rowInfo, column, instance) => {}}
        /*
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            // onMouseOver: (e, handleOriginal) => {
            //   // console.log("mouseOver");
            //   if (handleOriginal) {
            //     handleOriginal();
            //   }
            // },
            // onMouseOut: (e, handleOriginal) => {
            //   // console.log("mouseOut");
            //   if (handleOriginal) {
            //     handleOriginal();
            //   }
            // },
            onClick: (e, handleOriginal) => {
              console.log("A Td Element was clicked!");
              console.log("it produced this event:", e);
              console.log("It was in this column:", column);
              console.log("It was in this row:", rowInfo);
              console.log("It was in this table instance:", instance);
         
              // IMPORTANT! React-Table uses onClick internally to trigger
              // events like expanding SubComponents and pivots.
              // By default a custom 'onClick' handler will override this functionality.
              // If you want to fire the original onClick handler, call the
              // 'handleOriginal' function.
              if (handleOriginal) {
                handleOriginal();
              }
            }
          };
        }}
        */
        />
      </div>
    );
  }
}

export default PoolListTable;
