import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import { difficulty, timeUnits } from "variables/util.jsx";

class LastBlock extends React.Component {
  roundVariance() {
    const { classes, poolStats } = this.props;
    const { roundShares } = poolStats.stats;
    return (typeof roundShares === "number") ? (<p className={classes.cardTitle}><small>round variance:</small> <b>{Math.round(
      (100 * roundShares) / difficulty(poolStats)
    )} <small>%</small></b></p>) : null;
  }
  render() {
    const { classes, poolStats, color, timeRightNow } = this.props;
    if (typeof poolStats === "undefined" ||
      typeof poolStats.nodes === "undefined") return null;
    return (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>search</Icon>
          </CardIcon>
          <p className={classes.cardTitle}><small>last block found:</small> <b>{timeUnits((timeRightNow - poolStats.stats.lastBlockFound * 1000) / 1000)}</b></p>
          {this.roundVariance()}
        </CardHeader>
      </Card>
    );
  }
}

LastBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(LastBlock);
