import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
// import GridItem from "components/Grid/GridItem.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
// import CheckBox from "@material-ui/icons/CheckBox";
// import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
// import IndeterminateCheckBox from "@material-ui/icons/IndeterminateCheckBox";

// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
// import Tasks from "components/Tasks/Tasks.jsx";
// import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

// import BlocksCard from "components/Pool/Cards/Blocks.jsx";

// import { bugs, website, server } from "variables/general.jsx";
// import { Checkbox } from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class LuckTable extends React.Component {
  render() {
    const { classes, color, blocks } = this.props;
    if (typeof blocks === "undefined" || typeof blocks.luck === "undefined" || blocks.luck === null) { return null; }
    const luckItems = Object.keys(blocks.luck)
      .map(k => [
        k,
        Math.round(100 * blocks.luck[k].luck) + '%',
        Math.round(100 * blocks.luck[k].uncleRate) + '%',
        Math.round(100 * blocks.luck[k].orphanRate) + '%'
      ]);
    if (luckItems.length === 0) return null;
    return (
      <Card>
        <CardHeader color={color}>
          {/* <h4 className={classes.cardTitleWhite}>luck</h4> */}
          <p className={classes.cardTitleWhite}>luck</p>
          <p className={classes.cardCategoryWhite}>
            {/* Block maturity requires up to 520 blocks. Usually it's less indeed. */}
            {/* block maturity takes a few hundred blocks... */}
          </p>
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor={color}
            tableHead={["blocks", "shares / diff", "uncle rate", "orphan rate"]}
            tableData={luckItems}
          />
        </CardBody>
      </Card>
    );
  }
}
export default withStyles(styles)(LuckTable);
