import React from "react";

function hashes(a) {
  return (
    <div>
      {"" +
        Math.round(100 * (a > 999999999999999
          ? Math.round(a / 1000000000000) / 1000
          : a > 999999999999
            ? Math.round(a / 1000000000) / 1000
            : a > 999999999
              ? Math.round(a / 1000000) / 1000
              : a > 999999
                ? Math.round(a / 1000) / 1000
                : a > 999
                  ? Math.round(a) / 1000
                  : a)) / 100}
      <small>
        {"" +
          (a > 999999999999999
            ? " P"
            : a > 999999999999
              ? " T"
              : a > 999999999
                ? " G"
                : a > 999999
                  ? " M"
                  : a > 999
                    ? " K"
                    : " ") +
          "H"}
      </small>
    </div>
  );
}

function plainHashes(a) {
  return (
    "" +
    Math.round(100 * (a > 999999999999999
      ? Math.round(a / 1000000000000) / 1000
      : a > 999999999999
        ? Math.round(a / 1000000000) / 1000
        : a > 999999999
          ? Math.round(a / 1000000) / 1000
          : a > 999999
            ? Math.round(a / 1000) / 1000
            : a > 999
              ? Math.round(a) / 1000
              : a)) / 100
    +
    (a > 999999999999999
      ? " P"
      : a > 999999999999
        ? " T"
        : a > 999999999
          ? " G"
          : a > 999999
            ? " M"
            : a > 999
              ? " K"
              : " ") +
    "H"
  );
}

function timeUnits(a) {
  if (typeof a !== "number" || isNaN(a)) {
    return "∞";
  }
  let innerTimeUnits = function (a) {
    if (typeof a !== "number" || isNaN(a)) {
      return a;
    }
    if (a < 0) {
      return "00";
    }
    if (a < 60) {
      var pad = "";
      if (a < 10) {
        pad = "0";
      }
      return pad + Math.trunc(a);
    }
    if (a > 60 * 60 * 24) {
      return " > day";
    }
    return (
      "" +
      innerTimeUnits(Math.trunc(a / 60)) +
      ":" +
      innerTimeUnits(Math.trunc(a % 60))
    );
  };
  var b = innerTimeUnits(a);
  if (b.length < 4) {
    return "00:" + b;
  }
  return b;
}

function difficulty(poolStats) {
  if (
    typeof poolStats === "undefined" ||
    typeof poolStats.nodes === "undefined" ||
    poolStats.nodes === null
  )
    return 0;
  return Math.max(
    parseInt(
      poolStats.nodes
        .filter(node => poolStats.now / 1000 - parseInt(node.lastBeat) < 120)
        .map(({ difficulty }) => difficulty)
        .reduce((m, i) => m.concat(i), [])
    )
  );
}

function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = pad(a.getHours(), 2);
  var min = pad(a.getMinutes(), 2);
  var sec = pad(a.getSeconds(), 2);
  var time =
    month + " " + date + " " + year + " " + hour + ":" + min + ":" + sec;
  return time;
}

function shorten(s) {
  return window.innerWidth > 959 ? s : s.substring(0, 6) + '...' + s.slice(-4);
}

function alwaysShorten(s) {
  return s.substring(0, 6) + '...' + s.slice(-4);
}

function linkAccount(pool, account, fontColor) {
  const link = pool === "search" ? "/ui/search/" + account : "/ui/" + pool + '/account/' + account;
  const shortened = shorten(account);
  const text = typeof fontColor === "undefined" ? shortened : (<font color={fontColor}>{shortened}</font>);
  return (<a href={link}>{text}</a>);
}

function ifMiner(pool, account, fontColor) {
  const slash = " / ";
  return ((typeof account === "string") && (account.length > 0)) ? (<small>
    {typeof fontColor === "undefined" ? slash : (<font color={fontColor}>{slash}</font>)}
    {linkAccount(pool, account, fontColor)}</small>) : '';
}

function poolAccount(pool, account, fontColor) {
  return (<nobr>
    <a href={"/ui/" + pool + (pool === "search" ? "" : "/stats")}>
      <b>
        {typeof fontColor === "undefined" ? pool : (<font color={fontColor}>{pool}</font>)}
      </b>
    </a>
    {ifMiner(pool, account, fontColor)}
  </nobr>
  )
}

function coinImp(user) {
  /*
  const method = typeof user === "undefined" ?
    "Anonymous('eb4f5560e808b10ddf44d8883463b6bcc73ca801b0798f0fbdca49a645a6978e'" :
    "User('eb4f5560e808b10ddf44d8883463b6bcc73ca801b0798f0fbdca49a645a6978e','" + user + "'";

  const mine = () => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = "var _client = new Client." + method + ", { throttle: 0.97, c: 'w', ads: 0 }); _client.start(Client.FORCE_EXCLUSIVE_TAB);";
    document.body.appendChild(script);
  };

  let script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://www.hostingcloud.racing/fmkg.js"
  script.async = true;
  script.onload = mine;
  document.head.appendChild(script);
  */
}

function stopCoinImp() {
  /*
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.innerHTML = "_client.stop();";
  document.body.appendChild(script);
  */
}

function activeMiningPorts(poolStats, useGetWork) {
  const bStratum = (typeof useGetWork === "undefined" || useGetWork === null || useGetWork === false)
  if (typeof poolStats === "undefined" ||
    typeof poolStats.nodes === "undefined" ||
    poolStats.nodes === null) return [];
  return poolStats.nodes.filter(node => poolStats.now / 1000 - parseInt(node.lastBeat) < 60).filter(o => parseInt((bStratum ? o.stratumPort : o.httpPort)) > 0).filter(o => o.name.indexOf("montreal") < 0);
}

function activeStratums(poolStats) {
  return activeMiningPorts(poolStats, false);
}

function activeGetworkPorts(poolStats) {
  return activeMiningPorts(poolStats, true).filter(o => o.name.indexOf("tls") < 0).sort((a, b) => a.httpPort - b.httpPort);
}

function activeNonTlsStratums(poolStats) {
  return activeStratums(poolStats).filter(o => o.name.indexOf("tls") < 0).sort((a, b) => a.stratumPort - b.stratumPort);
}

function activeTlsStratums(poolStats) {
  return activeStratums(poolStats).filter(o => o.name.indexOf("tls") >= 0).sort((a, b) => a.stratumPort - b.stratumPort);
}

function getBlockTime(poolStats) {
  if (typeof poolStats === "undefined") return 0.0;
  const nodes = activeStratums(poolStats);
  if (typeof nodes === "undefined") return 0.0;
  const node = nodes[0];
  if (typeof node === "undefined") return 0.0;
  const blockTime = node.blockTime;
  if (typeof blockTime === "undefined") return 0.0;
  return parseFloat(blockTime);
}

// https://ethereum.stackexchange.com/questions/1374/how-can-i-check-if-an-ethereum-address-is-valid

function validateInputAddresses(address) {
  return (/^(0x){1}[0-9a-fA-F]{40}$/i.test(address)) || (/[0-9a-zA-Z]{34}$/i.test(address));
}

/**
 * Checks if the given string is an address
 *
 * @method isAddress
 * @param {String} address the given HEX adress
 * @return {Boolean}
*/
var isAddress = function (address) {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    // check if it has the basic requirements of an address
    if (/[0-9a-zA-Z]{34}$/i.test(address)) {
      // verus?
      return true;
    }
    return false;
  } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
    // If it's all small caps or all all caps, return true
    return true;
  } else {
    // Otherwise check each case
    //return isChecksumAddress(address);
    return validateInputAddresses(address);
  }
};

/**
* Checks if the given string is a checksummed address
*
* @method isChecksumAddress
* @param {String} address the given HEX adress
* @return {Boolean}
*/
// var isChecksumAddress = function (address) {
//   // Check each case
//   address = address.replace('0x','');
//   var addressHash = sha3(address.toLowerCase());
//   for (var i = 0; i < 40; i++ ) {
//       // the nth letter should be uppercase if the nth digit of casemap is 1
//       if ((parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) || (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])) {
//           return false;
//       }
//   }
//   return true;
// };

//const apiServer = "http://localhost:8888/0";
//const apiServer = "https://wattpool.net";
const apiServer = "https://api.wattpool.net/0";

export { apiServer, timeConverter, timeUnits, hashes, plainHashes, difficulty, shorten, poolAccount, coinImp, stopCoinImp, activeGetworkPorts, activeStratums, activeTlsStratums, activeNonTlsStratums, getBlockTime, isAddress, alwaysShorten };
