import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
//import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import PoolNavbarLinks from "components/Navbars/PoolNavbarLinks.jsx";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

import { apiServer, isAddress } from "variables/util.jsx";

const _poolStatsURL = apiServer + "/all/api/stats";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poolStats: "",
      ...props
    };
  }

  update() {
    if (typeof this.props.pool === "undefined" || new Set(["search", "about", "calculators"]).has(this.props.pool)) { return null; }
    let poolStatsURL = _poolStatsURL.replace("all", this.props.pool);

    try {
      fetch(poolStatsURL)
        .then(v => v.json())
        .then(result => {
          if (
            typeof result !== "undefined"
          ) {
            this.setState({
              poolStats: result
            });
          }
        })
        .catch(() => console.log("promise rejected :-("));
    } catch (err) {
      console.log(err);
    }
  }

  intervalID = 0;
  u = () => this.update();

  componentDidMount() {
    const which = (typeof this.props.pool !== "undefined" && new Set(["search", "about", "calculators"]).has(this.props.pool) === false) ? this.props.pool + "." : "";
    document.title = which + "wattpool.net";
    this.intervalID = setInterval(this.u, 2000);
    this.u();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  modify = s => {
    const { candidatesTotal, immatureTotal, minersTotal } = this.state.poolStats;
    const pendingBlocks = candidatesTotal + immatureTotal;
    const pool = this.props.pool;
    const showMinerCount = true; // ( > 1 )
    return typeof pendingBlocks === "number" && pendingBlocks > 0 && s === "Blocks" ? s + " (" + pendingBlocks + ")" :
      showMinerCount && typeof minersTotal === "number" && minersTotal > 1 && s === "Miners" ? s + " (" + minersTotal + ")" :
        s === "Pool Statistics" ? pool.charAt(0).toUpperCase() + pool.slice(1) + " Pool" : s;
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  render() {
    const props = this.props;
    const { classes, color, logo, image, logoText, routes } = props;
    var links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          var activePro = " ";
          var listItemClasses;
          var pool = new Set(["search", "about", "calculators"]).has(props.pool) ? undefined : props.pool;
          var path = prop.path.replace(":pool", pool).replace(":account", props.account)
          if (path === "/search" && typeof props.account !== "undefined" && props.location.pathname.indexOf("pools") === -1) { // && typeof props.pool !== "undefined") {
            return null;
          }
          if (path === "/about" && typeof props.account !== "undefined" && props.location.pathname.indexOf("pools") === -1 && typeof pool !== "undefined") {
            return null;
          }
          if (path === "/about" && typeof pool !== "undefined") {
            return null;
          }
          // if (path === "/search" && typeof pool === "undefined" && props.location.pathname.indexOf("pools") !== -1 && typeof props.account === "undefined") {
          //   return null;
          // }
          if (path.includes("search/undefined") || path === "/search/") {
            return null;
          }
          if (path.includes("/undefined/") || (path.includes("/account/") && !isAddress(path.split("account/")[1]))) {
            return null;
          }
          if (path === "/upgrade-to-pro") {
            activePro = classes.activePro + " ";
            listItemClasses = classNames({
              [" " + classes[color]]: true
            });
          } else {
            listItemClasses = classNames({
              [" " + classes[color]]: this.activeRoute(prop.layout + path)
            });
          }
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: this.activeRoute(prop.layout + path)
          });
          return (
            <NavLink
              to={prop.layout + path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                    <prop.icon
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive
                      })}
                    />
                  )}
                <ListItemText
                  primary={
                    props.rtlActive ? prop.rtlName : this.modify(prop.name)
                  }
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive
                  })}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          );
        })}
      </List>
    );
    var brand = (
      <div className={classes.logo}>
        <a
          href="/pools"
          className={classNames(classes.logoLink, {
            [classes.logoLinkRTL]: props.rtlActive
          })}
        >
          <div className={classes.logoImage}>
            <img src={logo} alt="logo" className={classes.img} />
          </div>
          {logoText}
        </a>
      </div>
    );
    const landing = props.location.pathname.split('/')[3]
    return (this.state.poolStats === "" &&
      props.location.pathname.indexOf("pools") === -1 &&
      props.location.pathname.indexOf("search") === -1 &&
      props.location.pathname.indexOf("about") === -1 &&
      props.location.pathname.indexOf("calculators") === -1) ? null : (
        <div>
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={props.rtlActive ? "left" : "right"}
              open={props.open}
              classes={{
                paper: classNames(classes.drawerPaper, {
                  [classes.drawerPaperRTL]: props.rtlActive
                })
              }}
              onClose={props.handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {brand}
              <div className={classes.sidebarWrapper}>
                {props.rtlActive ? <RTLNavbarLinks /> : <PoolNavbarLinks pool={props.pool} account={props.account} landing={landing} />}
                {links}
              </div>
              {image !== undefined ? (
                <div
                  className={classes.background}
                  style={{ backgroundImage: "url(" + image + ")" }}
                />
              ) : null}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              anchor={props.rtlActive ? "right" : "left"}
              variant="permanent"
              open
              classes={{
                paper: classNames(classes.drawerPaper, {
                  [classes.drawerPaperRTL]: props.rtlActive
                })
              }}
            >
              {brand}
              <div className={classes.sidebarWrapper}>{links}</div>
              {image !== undefined ? (
                <div
                  className={classes.background}
                  style={{ backgroundImage: "url(" + image + ")" }}
                />
              ) : null}
            </Drawer>
          </Hidden>
        </div>
      );
  }
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
