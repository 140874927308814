import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const stex = ["akroma", "ether1", "mix", "egem", "ethergem", "atheios", "pirl", "reosc", "webchain", "mintme", "callisto", "perkle", "verus", "etc", "eth"];
const grav = ["akroma", "ether1", "mix", "egem", "ethergem", "pirl", "callisto", "expanse", "verus", "komodo", "etc", "eth"];

class Price extends React.Component {
  stex() {
    const { selected } = this.props;
    return stex.indexOf(selected.split(".")[0]) < 0 ? null : (<a href="https://app.stex.com/?ref=41715718" target="stex">STEX&nbsp;&nbsp;&nbsp;</a>)
  }

  grav() {
    const { selected } = this.props;
    return grav.indexOf(selected.split(".")[0]) < 0 ? null : (<a href="https://graviex.net/signup?ref=c1932b9b34edf20b7b9d1153" target="graviex">GRAVIEX</a>)
  }

  price() {
    const { classes, price } = this.props;
    if (price === null || typeof price === "undefined") return null;
    const priceUSD = typeof price.usd === "undefined" ? "" : price.usd.toFixed(4)
    const priceBTC = typeof price.usd === "undefined" ? "" : price.btc.toFixed(8)
    const priceETH = typeof price.usd === "undefined" ? "" : price.eth.toFixed(8)

    return priceUSD + priceBTC + priceETH === "" ? null : (
      <p className={classes.cardTitle}>{priceBTC} BTC<br />{priceETH} ETH<br />${priceUSD} USD</p>
    );
  }

  render() {
    const s = this.stex();
    const g = this.grav();
    const p = this.price();
    if (s === null && g === null && p === null) return null;
    const { color } = this.props;

    return (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>attach_money</Icon>
          </CardIcon>
          <small>
            {this.price()}
            {/* <div className={classes.stats}>
              <b><a href="https://app.stex.com/?ref=41715718" target="stex">STEX</a></b>
            </div>
            {"   "}
            <div className={classes.stats}>
              <b><a href="https://graviex.net/signup?ref=c1932b9b34edf20b7b9d1153" target="graviex">GRAVIEX</a></b>
            </div> */}
          </small>
        </CardHeader>
      </Card>
    );
  }
}

Price.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired,
  price: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(Price);
