import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
// import GridItem from "components/Grid/GridItem.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { timeConverter } from "variables/util.jsx";

function shorten(s) {
  return window.innerWidth > 1259 ? s : s.substring(0, 6) + '...' + s.slice(-4);
}

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Payments extends React.Component {
  render() {
    const { classes, payments, color, explorer } = this.props;
    const { baseURL, addr, tx } = explorer;
    const addrBaseURL = '' + baseURL + addr;
    const txBaseURL = '' + baseURL + tx;
    if (typeof payments === "undefined" || typeof payments.payments === "undefined") return null;
    return (
      <Card>
        <CardHeader color={color}>
          <h4 className={classes.cardTitleWhite}>
            {/* Pool always pay tx fees from it's own pocket for now. */}
            recent payments
          </h4>
          <p className={classes.cardCategoryWhite}>
            total payments sent: {payments.paymentsTotal}
          </p>
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor={color}
            tableHead={["when", "amount", "address", "transaction"]}
            tableData={payments.payments
              .sort((a, b) => (a.timestamp < b.timestamp) ? 1 : (a.timestamp > b.timestamp) ? -1 : (a.address < b.address) ? 1 : (a.address > b.address) ? -1 : 0)
              .map(({ address, amount, timestamp, tx }) => [
                timeConverter(timestamp),
                String(Math.trunc(amount / 1000000) / 1000),
                <a href={addrBaseURL + address} target="blockExplorer">
                  {shorten(address)}
                </a>,
                <a href={txBaseURL + tx} target="blockExplorer">
                  {shorten(tx)}
                </a>
              ])}
          />
        </CardBody>
      </Card>
    );
  }
}

Payments.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  payments: PropTypes.object.isRequired,
  explorer: PropTypes.object.isRequired
};

export default withStyles(styles)(Payments);
