import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class MinersOnlinePoolFee extends React.Component {
  minersOnline(poolStats) {
    const { classes } = this.props;

    return (typeof poolStats === "undefined" || typeof poolStats.minersTotal !== "number") ? null : (
      <p className={classes.cardTitle}><small>miners online:</small> <b>{poolStats.minersTotal}</b></p>
    );
  }
  render() {
    const { classes, poolInfo, poolStats, color, selected } = this.props;
    const poolFee = typeof poolInfo !== "undefined" ? 100 * poolInfo.payout.poolFee : selected.includes("-solo") ? 1.5 : 1;
    return (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>people</Icon>
          </CardIcon>
          {this.minersOnline(poolStats)}
          <p className={classes.cardTitle}><small>pool fee</small>:{" "}
            <nobr><b>
              {poolFee} <small>%</small>
            </b></nobr>
          </p>
        </CardHeader>
      </Card>
    );
  }
}

MinersOnlinePoolFee.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  poolInfo: PropTypes.object.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(MinersOnlinePoolFee);
