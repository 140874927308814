import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { activeStratums } from "variables/util.jsx";

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      trackHashrate: true
    };
  }

  bookmark() {
    const { selected, payoutControls } = this.props;
    const splitPathname = this.props.location.pathname.split('/');
    const landing = splitPathname[3] === "account" ? splitPathname[4] : undefined;
    if (typeof landing === "undefined") return null;
    const url = selected + "/account/" + landing;
    const payoutContolsMsg = (payoutControls) ?
      (<div>{"Try our new manual payout interval control feature (still in testing).  The feature will be available after your first payout.  To enable the feature, click on the dollar sign ($) and scroll down.  NOTE: As this feature is still in testing, early (or late) payments may occur.  Please report any issues over email or discord.  The minimumum payout feature currently does not support decimals or fractions.  For now, the smallest minimum payout is 1."}<br /><br /></div>)
      : null;
    return (
      <CardBody>
        {payoutContolsMsg}
        Link directly to your account status page with the URL:{" "}
        <a href={"https://" + url}>{"https://" + url}</a>
      </CardBody>
    );
  }

  handleCheckboxChange = event =>
    this.setState({ trackHashrate: event.target.checked });

  webCalc() {
    const { classes, minerStats, poolStats } = this.props;
    const hr = this.state.trackHashrate === false ? 500 :
      typeof minerStats !== "undefined" && typeof minerStats.hashrate !== "undefined" && minerStats.hashrate > 0 ? minerStats.hashrate :
        typeof poolStats !== "undefined" && typeof poolStats.hashrate !== "undefined" && poolStats.hashrate > 0 ? poolStats.hashrate : 500;
    return (
      <GridItem xs={12} lg={6}>
        <Card>
          <CardHeader color="rose">
            <iframe
              className={classes.iframe}
              src={"https://www.cryptunit.com/widgets/2.php?coin=32&small=false&transparent=true&black=false&hr=" + (hr)}
              title="cryptunit webchain hash calculator"
              width="100%"
              height="100%"
              scrolling="no"
              frameBorder={0}
            >
              <p>Your browser does not support iframes.</p>
            </iframe>
          </CardHeader>
          <CardFooter>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.trackHashrate}
                    onChange={this.handleCheckboxChange}
                    value="trackHashrate"
                  />
                }
                label="track hashrate"
              />
            </FormGroup>
          </CardFooter>
        </Card>
      </GridItem>
    );
  }

  fourMinersCalc(coin) {
    const { classes } = this.props;
    return (
      <GridItem xs={12} lg={6}>
        <Card>
          <CardHeader color="rose">
            <table height="100%" width="100%">
              <tr><td>
                <iframe
                  className={classes.iframe}
                  src={"https://4miners.pro/widget.php?coin_id=" + coin}
                  title="4miners mining calculator"
                  width="100%"
                  height="230"
                  scrolling="yes"
                  frameBorder={0}
                >
                  <p>Your browser does not support iframes.</p>
                </iframe>
              </td></tr>
            </table>
          </CardHeader>
        </Card>
      </GridItem>
    );
  }

  webchain() {
    const { classes, poolStats, selected } = this.props;
    const stratums = activeStratums(poolStats)
    const blockHeight = stratums.length > 0 ? stratums[0].height : 0;
    const hardforkOccurred = blockHeight > 2619000;
    const occurrance = hardforkOccurred ? "that has occurred" : "scheduled to occur";
    return (
      <GridItem xs={12} lg={6}>
        <Card>
          <CardHeader>
            <p className={classes.cardTitle}>Welcome to the <b>MintMe</b> mining pool on <b><a href="/">wattpool.net</a></b>!</p>
            {this.soloShared(selected.split(".")[0])}
            {/* <p>Please be aware that there is a hardfork & mining algorithm change {occurrance} at block height 2619000.</p>
            <p><b><a href={"https://" + selected}>{selected}</a></b> is {hardforkOccurred ? "in sync with" : "ready for"} the hardfork, but are your miners{hardforkOccurred ? "" : " ready"}?</p> */}
            <p>Make sure your miner is running the latest version of <b><a href="https://github.com/webchain-network/webchain-miner/releases" target="github-webchain-miner">webchain-miner</a></b>, v2.8.0 or later.</p>
            <p>If you are running the <b><a href="https://github.com/wattpool/webchain-miner-dockerized" target="github-wattpool-webchain-miner-dockerized">dockerized</a></b> build of <b><a href="https://github.com/wattpool/webchain-miner-dockerized" target="github-wattpool-webchain-miner-dockerized">webchain-miner</a></b>, you should stop the running container, then run `docker container prune && docker image prune` before rebuilding your image in order to build the latest miner.</p>
            <p>For more info about running <b><a href="https://github.com/wattpool/webchain-miner" target="github-wattpool-webchain-miner">webchain-miner</a></b>, please see <b><a href="/ui/webchain/connect">{selected}/connect</a></b></p>
          </CardHeader>
          {/* <CardBody>
              Please bear with us as we continue to polish up this brand new <b>MintMe</b> mining pool.  Payouts are temporarily disabled for now, but will be enabled over the next few days.
            <CardFooter>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      pool hash rate:{" "}
                    </nobr>
                  </div>
                </div>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      pool hash rate:{" "}
                    </nobr>
                  </div>
                </div>
              </CardFooter>
            </CardBody> */}
          {this.bookmark()}
        </Card>
      </GridItem>
    );
  }

  verus() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} lg={6}>
          <Card>
            <CardHeader>
              <p className={classes.cardTitle}>Welcome to the <b>Verus</b> mining pool on <b><a href="/">wattpool.net</a></b>!</p>
            </CardHeader>
            <CardBody>
              <p>This UI integration is a work in progress.  Please report any bugs or strange behavior via <a href="/ui/verus/about">email or discord</a>.</p>
            </CardBody>
            {/* <CardBody>
              Please bear with us as we continue to polish up this brand new <b>MintMe</b> mining pool.  Payouts are temporarily disabled for now, but will be enabled over the next few days.
            <CardFooter>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      pool hash rate:{" "}
                    </nobr>
                  </div>
                </div>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      pool hash rate:{" "}
                    </nobr>
                  </div>
                </div>
              </CardFooter>
            </CardBody> */}
            {this.bookmark()}
          </Card>
        </GridItem>
      </GridContainer>
    )
  }

  aqua() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} lg={6}>
          <Card>
            <CardHeader>
              <p className={classes.cardTitle}>Welcome to the <b>AquaChain</b> mining pool on <b><a href="/">wattpool.net</a></b>!</p>
            </CardHeader>
            {this.bookmark()}
          </Card>
        </GridItem>
      </GridContainer>
    )
  }

  nopayout() {
    const { classes, selected } = this.props;
    const pool = selected.split(".")[0];
    return (
      <GridContainer>
        <GridItem xs={12} lg={6}>
          <Card>
            <CardHeader>
              <p className={classes.cardTitle}>Welcome to <b><a href={"/ui/" + pool + "/stats"}>{selected}</a></b>!</p>
              {this.poolNews(pool)}
            </CardHeader>
            {this.bookmark()}
          </Card>
        </GridItem>
      </GridContainer>
    )
  }

  soloShared(pool) {
    const shared = pool.split("-")[0];
    const solo = shared + "-solo";
    return (
      <div>
        <p>We are now hosting both shared and solo pools!</p>
        <ul>
          <li>shared: <b><a href={"https://" + shared + ".wattpool.net"}>{shared}.wattpool.net</a></b></li>
          <li>solo: <b><a href={"https://" + solo + ".wattpool.net"}>{solo}.wattpool.net</a></b></li>
        </ul>
      </div>
    )
  }

  poolNews(pool) {
    //return null;
    //return (pool === "ubiq") ? (
    //return (false) ? (
    //return ((pool.split("-")[1] === "solo") || (pool === "xerom")) ? (
    const poolCoin = pool.split("-")[0];
    const beginResume = (poolCoin === "xxx") ? "begin" : "resume";
    if (poolCoin === "xxx") return (
      <p>
        <br />
        Payments are temporarily disabled on the <b>{pool.toUpperCase()}</b> pool for the time being.  Please bear with us - mining rewards are safe.  Payouts will {beginResume} shortly.
        </p>
    )
    return ((poolCoin === "xerom") || (poolCoin === "xero") ||
      (poolCoin === "ether1") || (poolCoin === "etho") ||
      (poolCoin === "mintme") || (poolCoin === "webchain") ||
      (poolCoin === "webcn") || (poolCoin === "web")) ? (
        < p >
          {this.soloShared(pool)}
          {/* <br />Payment rounds for the < b > {pool.toUpperCase()}</b > pool will begin shortly. */}
        </p >
        // )
        // return (
        // <p>
        //   <br />
        //   Payments are temporarily disabled on the <b>{pool.toUpperCase()}</b> pool for the time being.  Please bear with us - mining rewards are safe.  Payouts will resume shortly.
        // </p>
      ) : null;
  }

  // tryWebchain() {
  //   const { classes, selected } = this.props;
  //   const pool = selected.split(".")[0];
  //   return (
  //     <GridContainer>
  //       <GridItem xs={12}>
  //         <Card>
  //           <CardHeader>
  //             <p className={classes.cardTitle}>Welcome to <b><a href={"/ui/" + pool + "/stats"}>{selected}</a></b>!</p>
  //             {this.poolNews(pool)}
  //           </CardHeader>
  //           <CardBody>
  //             Put your <b>idle CPU</b> to work!  Give <b>CPU mining</b> a try on our <b>brand new <a href="/ui/webchain/stats" target="webchainpool">MintMe mining pool</a></b>!
  //             Visit <b><a href="https://webchain.wattpool.net" target="webchainpool">webchain.wattpool.net</a></b> for more details.
  //           {/* <CardFooter>
  //               <div className={classes.stats}>
  //                 <div>
  //                   <nobr>
  //                     pool hash rate:{" "}
  //                   </nobr>
  //                 </div>
  //               </div>
  //               <div className={classes.stats}>
  //                 <div>
  //                   <nobr>
  //                     pool hash rate:{" "}
  //                   </nobr>
  //                 </div>
  //               </div>
  //             </CardFooter> */}
  //           </CardBody>
  //           {this.bookmark()}
  //         </Card>
  //       </GridItem>
  //     </GridContainer>
  //   )
  // }

  tryCpuMining() {
    const { classes, selected } = this.props;
    const pool = selected.split(".")[0];
    return (
      <GridItem xs={12} lg={6}>
        <Card>
          <CardHeader>
            <p className={classes.cardTitle}>Welcome to <b><a href={"/ui/" + pool + "/stats"}>{selected}</a></b>!</p>
            {this.poolNews(pool)}
          </CardHeader>
          <CardBody>
            Put your <b>idle CPU</b> to work!  Give <b>CPU mining</b> a try on one of our <b>new CPU mining pools!</b>!
              Visit <b><a href="https://aquachain.wattpool.net" target="aquapool">aquachain.wattpool.net</a></b>, <b><a href="https://verus.wattpool.net" target="veruspool">verus.wattpool.net</a></b> or <b><a href="https://webchain.wattpool.net" target="webchainpool">webchain.wattpool.net</a></b> for more details.
            {/* <CardFooter>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      pool hash rate:{" "}
                    </nobr>
                  </div>
                </div>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      pool hash rate:{" "}
                    </nobr>
                  </div>
                </div>
              </CardFooter> */}
          </CardBody>
          {this.bookmark()}
        </Card>
      </GridItem>
    )
  }

  render() {
    const { poolInfo, selected } = this.props;

    const chain = selected.split(".")[0]
    if (chain === "verus") return this.verus();
    if ((chain === "aqua") ||
      (chain === "aqua-solo")) return this.aqua();
    if ((chain === "webchain") ||
      (chain === "webchain-solo") ||
      (chain === "mintme") ||
      (chain === "mintme-solo")) return (
        <GridContainer>
          {this.webchain()}
          {this.webCalc()}
        </GridContainer>
      );
    //if (chain.split("-")[0] === "solo") return this.solo();
    if (typeof poolInfo !== "undefined" &&
      typeof poolInfo.social !== "undefined" &&
      typeof poolInfo.social.cryptUnit !== "undefined" &&
      poolInfo.social.cryptUnit > 0) return (
        <GridContainer>
          {this.tryCpuMining()}
          {this.fourMinersCalc(poolInfo.social.cryptUnit)}
        </GridContainer>
      )
    return (
      <GridContainer>
        {this.tryCpuMining()}
      </GridContainer>
    )
  }
}

Welcome.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  pool: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  payoutControls: PropTypes.bool.isRequired,
  poolInfo: PropTypes.object.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Welcome);
