import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class DAGSize extends React.Component {
  dag() {
    const { classes, poolStats } = this.props;
    const intDagSize =
      Math.max(
        parseInt(
          poolStats.nodes
            .filter(({ lastBeat }) => poolStats.now / 1000 - parseInt(lastBeat) < 120)
            .map(({ dagSize }) => dagSize)
            .reduce((m, i) => m.concat(i), [])
        )
      ) / Math.pow(1024, 3)

    const dagSize = Math.trunc(1 + 100 *
      intDagSize
    ) / 100
    return typeof intDagSize !== "number" || intDagSize === 0 ? null : (
      <p className={classes.cardTitle}><small>DAG size:</small>{" "}
        <nobr>
          <b>
            <font color="#000000">
              {dagSize} GB
          </font>
          </b>
        </nobr>
      </p>
    )
  }

  render() {
    const { classes, poolStats, color } = this.props;
    //const includeBlockTime = this.props.selected.split('.')[0] !== "verus";
    if (typeof poolStats === "undefined" ||
      typeof poolStats.nodes === "undefined" ||
      poolStats.nodes === null) return null;
    return (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>reorder</Icon> {/* memory ac_unit */}
          </CardIcon>
          <p className={classes.cardTitle}><small>block height:</small>{" "}
            <nobr>
              <b>
                <font color="#000000">
                  {Math.max(
                    parseInt(
                      poolStats.nodes
                        .filter(({ lastBeat }) => poolStats.now / 1000 - parseInt(lastBeat) < 120)
                        .map(({ height }) => height)
                        .reduce((m, i) => m.concat(i), [])
                    )
                  )}
                </font>
              </b>
            </nobr>
          </p>
          {this.dag()}
        </CardHeader>
      </Card>
    );
  }
}

DAGSize.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(DAGSize);
