import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// import AccessTime from "@material-ui/icons/AccessTime";
// core components
// import GridItem from "components/Grid/GridItem.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

// import WorkerList from "components/Pool/Cards/MinerWorkers.jsx";
import { minerHashRate } from "variables/stats.jsx";
// import { timeUnits, plainHashes } from "variables/util.jsx";
import { plainHashes } from "variables/util.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class MinerHashChart extends React.Component {
  render() {
    const { minerStats, height } = this.props;
    if (typeof minerStats.minerCharts === "undefined" ||
      minerStats.minerCharts === null) return (
        <center>hash rate: {plainHashes(minerStats.currentHashrate)} (short) / {plainHashes(minerStats.hashrate)} (long)</center>
      );
    const hashRate = minerHashRate(minerStats);
    return (<ChartistGraph
      className="ct-chart"
      data={hashRate.data}
      type="Line"
      options={hashRate.options}
      listener={hashRate.animation}
      style={{ height: height }}
    />
    )
  }
}

MinerHashChart.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  minerStats: PropTypes.object.isRequired,
  height: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(MinerHashChart);
