import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Email extends React.Component {
  render() {
    const { classes, color } = this.props;
    return (
      <a href="mailto:wattpool@gmail.com">
        <Card>
          <CardHeader color={color} stats icon>
            <CardIcon color={color}>
              <Icon>mail</Icon>
            </CardIcon>
            <p className={classes.cardTitle}><small>pool support email</small>:{" "}
              <nobr><b><a href="mailto:wattpool@gmail.com">wattpool@gmail.com</a></b></nobr>
            </p>
          </CardHeader>
        </Card>
      </a>
    );
  }
}

Email.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Email);
