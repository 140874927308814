import React from "react";
import { Helmet } from "react-helmet";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
// import Quote from "components/Typography/Quote.jsx";
// import Muted from "components/Typography/Muted.jsx";
// import Primary from "components/Typography/Primary.jsx";
// import Info from "components/Typography/Info.jsx";
// import Success from "components/Typography/Success.jsx";
// import Warning from "components/Typography/Warning.jsx";
// import Danger from "components/Typography/Danger.jsx";
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import TermsOfService from "components/Pool/Cards/TermsOfService.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CommandLine from "components/Pool/Cards/CommandLine.jsx";
import TermsOfService from "components/Pool/Cards/TermsOfService.jsx";
import Ads from "components/Pool/Cards/Ads.jsx";
import { apiServer } from "variables/util.jsx";

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const _infoURL = apiServer + "/all/api/info";
const _statsURL = apiServer + "/all/api/stats";

class ConnectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poolStats: "",
      info: "",
      selected: this.props.match.params.pool + ".wattpool.net",
      ...props
    };
  }

  update() {
    let statsURL = _statsURL.replace("all", this.props.match.params.pool)
    let infoURL = _infoURL.replace("all", this.props.match.params.pool)

    try {
      Promise.all([
        fetch(statsURL).then(v => v.json()),
        fetch(infoURL).then(v => v.json())
      ]).then(allResponses => {
        const stats = allResponses[0];
        const info = allResponses[1];

        try {
          if (
            typeof stats !== "undefined" &&
            typeof stats.nodes !== "undefined"
          ) {
            this.setState({ poolStats: stats, info: info });
            //  &&
            //   (typeof result[this.state.selected] !== "undefined") &&
            //   (typeof result[this.state.selected].nodes !== "undefined") &&
            //   (typeof result[this.state.selected].nodes.length > 0)) {
            //   this.setState({ poolStats: result[this.state.selected] });
          }
        } catch (e) {
          console.log(e);
        }
        // this.setState({
        //   isLoaded: true,
        //   items: result.items
        // });
      },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          // this.setState({
          //   isLoaded: true,
          //   error
          // });
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  intervalID = 0;
  u = () => this.update();

  componentDidMount() {
    //this.intervalID = setInterval(this.u, 20000);
    this.u();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  // const { classes } = props;
  render() {
    return (
      <div>
        <Helmet>
          <title>{"wattpool.net mining pools - connect"}</title>
          <meta name="keywords" content={"wattpool,crypto,cryptocurrency,blockchain,mining,pool,bitcoin,btc,ethereum,eth"} />
          <meta
            name="description"
            content={"wattpool.net mining pools"}
          />
        </Helmet>

        <GridContainer>
          <GridItem xs={12}>
            <CommandLine color="primary" poolStats={this.state.poolStats} pool={this.state.selected} info={this.state.info} />
          </GridItem>
        </GridContainer>
        <Ads selected={this.state.selected} vertical={false} />
        <GridContainer>
          <GridItem xs={12}>
            <TermsOfService color="primary" />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(ConnectPage);
