import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
//import AccessTime from "@material-ui/icons/AccessTime";
// core components
// import GridItem from "components/Grid/GridItem.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import CardFooter from "components/Card/CardFooter.jsx";

import WorkerList from "components/Pool/Cards/MinerWorkers.jsx";
import MinerHashChart from "components/Pool/Graphs/MinerHashChart.jsx";
// import { minerHashRate } from "variables/stats.jsx";
// import { timeUnits, plainHashes } from "variables/util.jsx";
//import { timeUnits } from "variables/util.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";


class MinerHash extends React.Component {
  chart(height) {
    const { minerStats } = this.props;
    if (typeof minerStats.minerCharts === "undefined") return null;
    //xconst hashRate = minerHashRate(minerStats);
    return (
      <MinerHashChart
        minerStats={minerStats}
        height={height}
      />
    )
  }

  workers(showWorkers) {
    const { classes, minerStats, poolStats } = this.props;
    return !showWorkers ? null : (
      <CardBody>
        {/* <h4 className={classes.cardTitle}>Our Pool's Hash Rate</h4> */}
        <h4 className={classes.cardTitle}>total workers: {minerStats.workersTotal} ({minerStats.workersOnline} online / {minerStats.workersOffline} offline)</h4>
        {/* <h4 className={classes.cardTitle}>miner current total hash rate (30m): {plainHashes(minerStats.currentHashrate)} {" / (3h):"} {plainHashes(minerStats.hashrate)}</h4> */}
        {/* <p className={classes.cardCategory}>
              Last Campaign Performance
            </p> */}
        <WorkerList
          color={this.props.color}
          minerStats={minerStats}
          poolStats={poolStats}
          timeRightNow={this.props.timeRightNow}
        />
      </CardBody>
    )
  }

  render() {
    //console.log(this.props.minerStats)
    //console.log(this.props.data)
    const { /*classes, poolStats, */ minerStats, height, hideWorkers } = this.props;
    const showWorkers = (hideWorkers !== true);
    const chartHeight = (typeof height === "undefined") ? "300px" : height;
    if (typeof minerStats === "undefined") return null;
    if (
      typeof minerStats === "undefined" ||
      typeof minerStats === "string"
    ) return null; // FIXME: show connection info box instead

    return (
      <Card chart>
        <CardHeader color={this.props.color}>
          {this.chart(chartHeight)}
        </CardHeader>
        {this.workers(showWorkers)}
        {/* <CardFooter chart>
          <div className={classes.stats}>
            <AccessTime /> updated {timeUnits(Math.round(poolStats.now / 1000) - Math.max.apply(null, minerStats.minerCharts.map(i => i.x)))} ago
          </div>
        </CardFooter> */}
      </Card>
    );
  }
}

MinerHash.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  minerStats: PropTypes.object.isRequired,
  poolStats: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(MinerHash);
