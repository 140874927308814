import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import { difficulty, plainHashes } from "variables/util.jsx";

class PoolHashRate extends React.Component {
  render() {
    const { classes, color, poolStats, blockTime } = this.props;
    if (typeof poolStats === "undefined" ||
      typeof blockTime === "undefined" ||
      typeof poolStats.nodes === "undefined") return null;
    var networkHashrate = typeof poolStats.networkHashrate === "undefined" ? plainHashes(difficulty(poolStats) / blockTime) : plainHashes(poolStats.networkHashrate);
    return (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>network_check</Icon>
          </CardIcon>
          <p className={classes.cardTitle}><small>pool hash rate:</small> <nobr><b>{plainHashes(poolStats.hashrate)}</b></nobr></p>
          <p className={classes.cardTitle}><small>network hash rate:</small> <nobr><b>{networkHashrate}</b></nobr></p>
          {/* <h3 className={classes.stats}>Workers Total: {minerStats.workersTotal}</h3> */}
        </CardHeader>
      </Card>
    );
  }
}

PoolHashRate.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  minerStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(PoolHashRate);
