import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.jsx";
import Pools from "layouts/Pools.jsx";
import RTL from "layouts/RTL.jsx";
import MetaTags from 'react-meta-tags';

import "assets/css/material-dashboard-react.css?v=1.6.0";

const hist = createBrowserHistory();

ReactDOM.render(
  <div>
    <MetaTags>
      <title>wattpool.net</title>
      <meta name="keywords" content={"wattpool,crypto,cryptocurrency,blockchain,mining,pool,bitcoin,btc,ethereum,eth"} />
      <meta
        name="description"
        content="wattpool.net mining pools"
      />
      <meta property="og:title" content="wattpool.net" />
      <meta property="og:image" content="https://wattpool.net/poolchart.jpg" />
      <meta property="og:description" content="wattpool.net mining pools" />
      <meta property="og:url" content="https://wattpool.net" />
      <meta name="twitter:card" content="wattpool.net mining pools" />
      <meta property="og:site_name" content="wattpool.net" />
      <meta name="twitter:image:alt" content="wattpool.net mining pools" />
      <meta property="twitter:title" content="wattpool.net" />
      <meta property="twitter:image" content="https://wattpool.net/poolchart.jpg" />
      <meta property="twitter:description" content="wattpool.net mining pools" />
      <meta property="twitter:url" content="https://wattpool.net" />
    </MetaTags>
    <Router history={hist}>
      <Switch>
        <Route path="/ui/about" component={Admin} />
        <Route path="/ui/search" component={Admin} />
        <Route path="/pools" component={Pools} />
        <Route path="/ui/:pool" component={Admin} />
        <Route path="/rtl" component={RTL} />
        <Redirect from="/" to="/pools" />
      </Switch>
    </Router>
  </div >,
  document.getElementById("root")
);
