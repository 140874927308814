// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import Power from "@material-ui/icons/Power";
// import Casino from "@material-ui/icons/Casino";
// import Dashboard from "@material-ui/icons/Dashboard";
import Equalizer from "@material-ui/icons/Equalizer";
import NetworkCheck from "@material-ui/icons/NetworkCheck";
import People from "@material-ui/icons/People";
import Search from "@material-ui/icons/Search";
import AttachMoney from "@material-ui/icons/AttachMoney";
// import Money from "@material-ui/icons/Money";
import Receipt from "@material-ui/icons/Receipt";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import EventAvailable from "@material-ui/icons/EventAvailable";
// import Person from "@material-ui/icons/Person";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
// import DashboardPage from "views/Dashboard/Dashboard.jsx";
import PoolIndexPage from "views/Dashboard/PoolIndex.jsx";
import PoolDashboardPage from "views/Dashboard/PoolDashboard.jsx";
import MinerDashboardPage from "views/Dashboard/MinerDashboard.jsx";
import MinerSearchPage from "views/Dashboard/MinerSearch.jsx";
// import UserProfile from "views/UserProfile/UserProfile.jsx";
// import TableList from "views/TableList/TableList.jsx";
import BlockList from "views/TableList/BlockList.jsx";
import MinerList from "views/TableList/MinerList.jsx";
import PaymentList from "views/TableList/PaymentList.jsx";
import About from "views/Typography/About.jsx";
import Connect from "views/Typography/Connect.jsx";
// import Calculators from "views/Typography/Calculators.jsx";
// import Typography from "views/Typography/Typography.jsx";
// import Icons from "views/Icons/Icons.jsx";
// import Maps from "views/Maps/Maps.jsx";
// import NotificationsPage from "views/Notifications/Notifications.jsx";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.jsx";
// // core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.jsx";

const dashboardRoutes = [
  {
    path: "",
    name: "Pool Index",
    rtlName: "Pool Index",
    icon: Apps,
    component: PoolIndexPage,
    layout: "/pools"
  },
  {
    path: "/:pool/stats",
    name: "Pool Statistics",
    rtlName: "Statistics",
    icon: Equalizer, // Dashboard,
    component: PoolDashboardPage,
    layout: "/ui"
  },
  {
    path: "/:pool/connect",
    name: "Connect",
    rtlName: "Connect",
    icon: Power,
    component: Connect,
    layout: "/ui"
  },
  {
    path: "/search",
    name: "Account Search",
    rtlName: "Account Search",
    icon: Search,
    component: MinerSearchPage,
    layout: "/ui"
  },
  {
    path: "/search/:account",
    name: "Account Search",
    rtlName: "Account Search",
    icon: Search,
    component: MinerSearchPage,
    layout: "/ui"
  },
  {
    path: "/:pool/account/:account",
    name: "Miner Statistics",
    rtlName: "Miner Statistics",
    icon: NetworkCheck, // Dashboard,
    component: MinerDashboardPage,
    layout: "/ui"
  },
  {
    path: "/:pool/miners",
    name: "Miners",
    rtlName: "Miners",
    icon: People,
    component: MinerList,
    layout: "/ui"
  },
  {
    path: "/:pool/blocks",
    name: "Blocks",
    rtlName: "Blocks",
    icon: Receipt, //EventAvailable,
    component: BlockList,
    layout: "/ui"
  },
  {
    path: "/:pool/payments",
    name: "Payments",
    rtlName: "Payments",
    icon: AttachMoney,
    component: PaymentList,
    layout: "/ui"
  },
  // {
  //   path: "/calculators",
  //   name: "Calculators",
  //   rtlName: "Calculators",
  //   icon: Money,
  //   component: Calculators,
  //   layout: "/ui"
  // },
  // // {
  //   path: "/:pool/status",
  //   name: "Status",
  //   rtlName: "Status",
  //   icon: LibraryBooks,
  //   component: About,
  //   layout: "/ui"
  // },
  {
    path: "/:pool/about",
    name: "About",
    rtlName: "About",
    icon: LibraryBooks,
    component: About,
    layout: "/ui"
  },
  {
    path: "/about",
    name: "About",
    rtlName: "About",
    icon: LibraryBooks,
    component: About,
    layout: "/ui"

    // },
    // {
    //   path: "/dashboard",
    //   name: "Dashboard",
    //   rtlName: "لوحة القيادة",
    //   icon: Dashboard,
    //   component: DashboardPage,
    //   layout: "/admin"
    // },
    // {
    //   path: "/user",
    //   name: "User Profile",
    //   rtlName: "ملف تعريفي للمستخدم",
    //   icon: Person,
    //   component: UserProfile,
    //   layout: "/admin"
    // },
    // {
    //   path: "/table",
    //   name: "Table List",
    //   rtlName: "قائمة الجدول",
    //   icon: "content_paste",
    //   component: TableList,
    //   layout: "/admin"
    // },
    // {
    //   path: "/typography",
    //   name: "Typography",
    //   rtlName: "طباعة",
    //   icon: LibraryBooks,
    //   component: Typography,
    //   layout: "/admin"
    // },
    // {
    //   path: "/icons",
    //   name: "Icons",
    //   rtlName: "الرموز",
    //   icon: BubbleChart,
    //   component: Icons,
    //   layout: "/admin"
    // },
    // {
    //   path: "/maps",
    //   name: "Maps",
    //   rtlName: "خرائط",
    //   icon: LocationOn,
    //   component: Maps,
    //   layout: "/admin"
    // },
    // {
    //   path: "/notifications",
    //   name: "Notifications",
    //   rtlName: "إخطارات",
    //   icon: Notifications,
    //   component: NotificationsPage,
    //   layout: "/admin"
    // },
    // {
    //   path: "/upgrade-to-pro",
    //   name: "Upgrade To PRO",
    //   rtlName: "التطور للاحترافية",
    //   icon: Unarchive,
    //   component: UpgradeToPro,
    //   layout: "/admin"
    // },
    // {
    //   path: "/rtl-page",
    //   name: "RTL Support",
    //   rtlName: "پشتیبانی از راست به چپ",
    //   icon: Language,
    //   component: RTLPage,
    //   layout: "/rtl"
  }
];

export default dashboardRoutes;
