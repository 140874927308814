import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";

// core components
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";


import { timeConverter, shorten } from "variables/util.jsx";

/*
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    // "& small": {
    //   color: "#777",
    //   fontSize: "65%",
    //   fontWeight: "400",
    //   lineHeight: "1"
    // }
  }
};
*/

class MinerPayments extends React.Component {
  render() {
    const { minerStats, color, explorer } = this.props;
    const { baseURL, tx } = explorer;
    const txBaseURL = '' + baseURL + tx;
    if (typeof minerStats === "undefined" || typeof minerStats.payments === "undefined") return null
    return minerStats.payments === null ? null : (
      <Table
        tableHeaderColor={color}

        tableHead={["when", "transaction", "amount"]}
        tableData={minerStats.payments
          .map(({ amount, timestamp, tx }) => [
            timeConverter(timestamp),
            <a href={txBaseURL + tx} target="blockExplorer">
              {shorten(tx)}
            </a>,
            String(Math.round(amount / 1000000) / 1000)
          ])}
      />
    );
  }
}

MinerPayments.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  minerStats: PropTypes.object.isRequired,
  explorer: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(MinerPayments);
