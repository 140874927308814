import React from "react";
import { Helmet } from "react-helmet";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Table from "components/Table/Table.jsx";
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
// import CheckBox from "@material-ui/icons/CheckBox";
// import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
// import IndeterminateCheckBox from "@material-ui/icons/IndeterminateCheckBox";

// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
// import Tasks from "components/Tasks/Tasks.jsx";
// import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

import BlocksCard from "components/Pool/Cards/Blocks.jsx";
import LuckCard from "components/Pool/Cards/Luck.jsx";

// import { bugs, website, server } from "variables/general.jsx";
// import { Checkbox } from "@material-ui/core";
import { apiServer } from "variables/util.jsx";

import PoolMinerBlocksPieChart from "components/Pool/Graphs/PoolMinerBlocksPieChart.jsx";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const _blocksURL = apiServer + "/all/api/blocks";
const _statsURL = apiServer + "/all/api/stats";
const _infoURL = apiServer + "/all/api/info";

class TableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blocks: "",//ether1, //[selectedPool],
      stats: "",
      info: "",
      explorer: "",
      selected: this.props.match.params.pool + ".wattpool.net",
      ...props
    };
  }

  update() {
    let blocksURL = _blocksURL.replace("all", this.props.match.params.pool)
    let statsURL = _statsURL.replace("all", this.props.match.params.pool)
    let infoURL = _infoURL.replace("all", this.props.match.params.pool)


    Promise.all([
      fetch(blocksURL).then(v => v.json()),
      fetch(statsURL).then(v => v.json()),
      fetch(infoURL).then(v => v.json())
    ]).then(allResponses => {
      const poolBlocks = allResponses[0];
      const poolStats = allResponses[1];
      const info = allResponses[2];

      try {
        //if ((typeof poolBlocks.matured !== "undefined") && (Object.keys(poolBlocks.matured).length > 0)) {
        if (typeof poolBlocks.candidatesTotal === "number") {
          this.setState({ blocks: poolBlocks, stats: poolStats, info: info, explorer: info.explorer });
        }
      } catch (e) {
        console.log(e)
      }
      // this.setState({
      //   isLoaded: true,
      //   items: result.items
      // });
    },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      error => {
        // this.setState({
        //   isLoaded: true,
        //   error
        // });
      }
    );
  }

  intervalID = 0;
  u = () => this.update();
  timeNowID = 0;
  t = () => { this.setState({ timeRightNow: new Date().getTime() }) }

  componentDidMount() {
    this.intervalID = setInterval(this.u, 5000);
    this.timeNowID = setInterval(this.t, 1000);
    this.u();
    this.t();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { classes } = this.props;
    const { blocks, stats, explorer, timeRightNow } = this.state;
    return blocks === "" ? null : (
      <div>
        <Helmet>
          <title>{"wattpool.net mining pools - payments"}</title>
          <meta name="keywords" content={"wattpool,crypto,cryptocurrency,blockchain,mining,pool,bitcoin,btc,ethereum,eth"} />
          <meta
            name="description"
            content={"wattpool.net mining pools"}
          />
        </Helmet>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <LuckCard
              classes={classes}
              color="warning"
              blocks={blocks}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <PoolMinerBlocksPieChart
              blocks={blocks}
              color="primary"
              poolStats={stats}
              timeRightNow={timeRightNow}
            />
          </GridItem>


          <GridItem xs={12} sm={12} md={12}>
            <BlocksCard
              classes={classes}
              color="primary"
              blocks={blocks}
              stats={stats}
              explorer={explorer}
              timeRightNow={timeRightNow}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(styles)(TableList);
