import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
// import Quote from "components/Typography/Quote.jsx";
// import Muted from "components/Typography/Muted.jsx";
// import Primary from "components/Typography/Primary.jsx";
// import Info from "components/Typography/Info.jsx";
// import Success from "components/Typography/Success.jsx";
// import Warning from "components/Typography/Warning.jsx";
// import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";


// core components
// import GridItem from "components/Grid/GridItem.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
// import Table from "components/Table/Table.jsx";
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
// import CheckBox from "@material-ui/icons/CheckBox";
// import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
// import IndeterminateCheckBox from "@material-ui/icons/IndeterminateCheckBox";

// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
// import Tasks from "components/Tasks/Tasks.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import { TextField } from "@material-ui/core"; // Button too?
import Radio from "@material-ui/core/Radio";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import RadioGroup from '@material-ui/core/RadioGroup';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { activeNonTlsStratums, activeStratums, activeGetworkPorts } from "variables/util.jsx";


const style = {
  cmd: {
    background: "#cccccc"
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class CommandLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: 0,
      ...props
    };
  }

  // for TextField
  handleFocus = event => {
    event.target.select();
  };

  handleChange = event => {
    this.setState({ selectedValue: event.target.value });
  };

  algo = () => {
    const { info } = this.props;
    return (typeof info !== "undefined") &&
      (typeof info.algo !== "undefined") &&
      (info.algo !== null) &&
      (typeof info.algo.name !== "undefined") ? info.algo.name : "unknown";
  }

  makePortSelector(stratums, useGetWork) {
    const bStratum = (typeof useGetWork === "undefined" || useGetWork === null || useGetWork === false)
    const { classes } = this.props;
    let { selectedValue } = this.state;
    if (selectedValue === 0) {
      if (stratums.length > 0) {
        selectedValue = bStratum
          ? stratums[0].stratumPort
          : stratums[0].httpPort;
      }
    }
    const activePorts = bStratum ? activeStratums : activeGetworkPorts;

    return (
      //      <Card>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Select a mining node:</FormLabel>
        <RadioGroup
          aria-label="Gender"
          name="gender1"
          className={classes.group}
          value={selectedValue}
          onChange={this.handleChange}
        >
          {activePorts(this.props.poolStats).filter(o => o.stratumPort !== "3333").sort((a, b) =>
            (bStratum ? (a.stratumPort - b.stratumPort) : (a.httpPort - b.httpPort))
          ).map(o => {
            const tlsLabel = o.name.includes("tls") ? " (TLS)" : "";
            return bStratum ? (
              <FormControlLabel key={o.stratumPort} value={o.stratumPort} control={<Radio />} label={o.name + ": port " + o.stratumPort + tlsLabel} />
            ) : (
                <FormControlLabel key={o.httpPort} value={o.httpPort} control={<Radio />} label={o.name + ": port " + o.httpPort + tlsLabel} />
              )
          })}
          {/* <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
          {/* <FormControlLabel
              value="disabled"
              disabled
              control={<Radio />}
              label="(Disabled option)"
            /> */}
        </RadioGroup>
      </FormControl>
      //      </Card>
    )
    // <FormControl component="fieldset" className={classes.formControl}>
    //   <FormLabel component="legend">Gender</FormLabel>
    //   <RadioGroup
    //     aria-label="gender"
    //     name="gender2"
    //     className={classes.group}
    //     value={this.state.value}
    //     onChange={this.handleChange}
    //   >
    //     <FormControlLabel
    //       value="female"
    //       control={<Radio color="primary" />}
    //       label="Female"
    //       labelPlacement="start"
    //     />
    //     <FormControlLabel
    //       value="male"
    //       control={<Radio color="primary" />}
    //       label="Male"
    //       labelPlacement="start"
    //     />
    //     <FormControlLabel
    //       value="other"
    //       control={<Radio color="primary" />}
    //       label="Other"
    //       labelPlacement="start"
    //     />
    //     <FormControlLabel
    //       value="disabled"
    //       disabled
    //       control={<Radio />}
    //       label="(Disabled option)"
    //       labelPlacement="start"
    //     />
    //   </RadioGroup>
    //   <FormHelperText>labelPlacement start</FormHelperText>
    // </FormControl>

    // return (<div>
    //   {["x", "y", "z"].map(o =>
    //     (<Radio
    //       checked={this.state.selectedValue === o}
    //       onChange={this.handleChange}
    //       value={o}
    //       name={o} HELLO
    //       aria-label={o}
    //       icon={<FiberManualRecord className={classes.radioUnchecked} />}
    //       checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
    //       classes={{
    //         checked: classes.radio,
    //       }}>
    //       {o} HELLO
    //     </Radio>
    //     )
    //   )}

    // </div>
    // )
  }

  makeTextField(data, selectedValue, tlsPorts, plain) {
    // const { stratumTcp, stratumTls } = data;
    const bTLS = typeof data.stratumTls !== "undefined" && typeof tlsPorts !== "undefined" && tlsPorts.includes("" + selectedValue);
    const text = bTLS ? data.stratumTls : data.stratumTcp;
    //const { text } = data;
    const s = text.replace("[stratumPort]", selectedValue)
    const rigNameInstructions = data.tabName === "webchain-miner" ? "" : ", replace `$rigName` with the name of your rig";
    return (
      <div>
        <br />
        {plain === true ? "" : ("command line: (replace `$wallet` with your wallet address" + rigNameInstructions + ")")}
        <Card>
          <TextField className={this.props.classes.cmd} fullWidth={true} value={" " + s} onFocus={this.handleFocus} />
        </Card>
      </div>
    )
  }

  makeTab(data, tlsPorts, msg) {
    const { tabName } = data;
    let { selectedValue } = this.state;
    if (selectedValue === 0) {
      const stratums = activeNonTlsStratums(this.props.poolStats);
      if (stratums.length > 0) {
        selectedValue = stratums[0].stratumPort;
      }
    }
    const introMessage = typeof msg === "undefined" ? "" : msg;
    return ({
      tabName: tabName,
      //tabIcon: CheckBox,
      tabContent: (
        <CardBody>
          {/* {"describe port selector: " + selectedValue} */}
          {introMessage}
          {this.makePortSelector(activeNonTlsStratums(this.props.poolStats))}
          {(selectedValue === 0) ? null : this.makeTextField(data, selectedValue, tlsPorts)}
          {/* < Table
        tableHeaderColor={color}
        tableHead={["Height", "Block Hash", "Time Found", "Variance", "Reward"]}
        tableData={[]}
      /> */}
        </CardBody>
      )
    })
  }

  phoenixCoin(pool) {
    const coin = this.props.info.social.phoenix
    return coin ? coin : "auto";
  }

  webchain() {
    const { classes, pool } = this.props;
    //const { tabName } = data;
    let { selectedValue } = this.state
    if (selectedValue === 0) {
      const stratums = activeNonTlsStratums(this.props.poolStats);
      if (stratums.length > 0) {
        selectedValue = stratums[0].stratumPort;
      }
    }
    const algo = this.algo();
    return (
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>webchain-miner</h4>
        </CardHeader>
        <CardBody>
          <CardBody>
            <p><b>MintMe</b> uses it's own custom POW algorithm, <b>{algo}</b>, which is <b>CPU-mineable</b> and requires a custom miner, <b>webchain-miner</b>.</p>
            <p>Even though <b>MintMe</b> uses <b>{algo}</b>, you can still use the popular <a href="https://mycrypto.com?network=WEB" target="mycrypto">MyCrypto</a> or <a href="https://vintage.myetherwallet.com?network=WEB" target="mew">MyEtherWallet</a> <b>web wallets</b>.</p>
            <hr />
            <p><b>webchain-miner -o webchain.wattpool.net:{selectedValue} -u</b> <i>{"<"}WALLET_ADDRESS{">"}</i> <b>-p x</b></p>
            <hr />
            <p>Download the latest <b>webchain-miner</b> from <a href="https://github.com/webchain-network/webchain-miner/releases" target="webchain">https://github.com/webchain-network/webchain-miner/releases</a></p>
            <p>Are you cloud mining?  Do you want to build <b>webchain-miner</b> yourself from the latest sources without dealing with build steps or dependencies?  Build a dockerized <b>webchain-miner</b> container using <a href="https://github.com/wattpool/webchain-miner-dockerized" target="dockerized">webchain-miner-dockerized</a>.</p>
            <br />
            {this.makePortSelector(activeNonTlsStratums(this.props.poolStats))}
            <p><b>OPTIONAL</b>: If you specify a number as your password, it will be used as your <i>vardiff</i> starting point.  The stratum should settle at the same difficulty either way - the idea is to supply an initial difficulty that you know your miner performs well at, and minimize the calibration time.  This allows you to run your miner as efficiently as possible as soon as it starts up.  If you choose not to use this feature, the stratum will calibrate as it normally would.  The command line example, below, begins <i>vardiff</i> calibration at 250H.</p>
            {this.makeTextField({
              tabName: "webchain-miner",
              stratumTcp: "webchain-miner -o " + pool + ":[stratumPort] -u $wallet -p 250"
            }, selectedValue)}
            <p>
              Try out the experimental build of the webchain GPU miner.
              Windows users can use the WATTPOOL version of the GPU miner, that mines the dev fee on webchain.wattpool.net: <a href="/xmrig-amd-nvidia-lyra2v2only.exe">xmrig-amd-nvidia-lyra2v2only.exe</a> (after the fork) / <a href="/xmrig-gpu.exe">xmrig-gpu.exe</a> (before the fork).
              For Linux-based miners, the latest build is available at <a href="https://github.com/monkins1010/Webchain-AMD-NVIDIA/releases" target="webchaingpuminer">https://github.com/monkins1010/Webchain-AMD-NVIDIA/releases</a>.
              Use the configuration file: <a href="/config.json">config.json</a>.  If you're using AMD GPUs, change the value of the "opencl-platform" field from "NVIDIA" to "AMD".  Be sure to replace the wallet address with your own.
            </p>
          </CardBody>
        </CardBody>
      </Card>
    );
  }

  verus() {
    const { classes, pool } = this.props;
    //const { tabName } = data;
    let { selectedValue } = this.state
    if (selectedValue === 0) {
      const stratums = activeNonTlsStratums(this.props.poolStats);
      if (stratums.length > 0) {
        selectedValue = stratums[0].stratumPort;
      }
    }
    const algo = this.algo();
    return (
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>veruscoin miner</h4>
        </CardHeader>
        <CardBody>
          <CardBody>
            <p><b>VerusCoin</b> uses it's own custom POW algorithm, <b>{algo}</b>, which is <b>CPU-mineable</b> and requires a custom miner.</p>
            <p>Some miners are available for download on <a href="https://veruscoin.io/download.html" target="veruscoin_io">veruscoin.io</a>, or use the github | dockerized links below to download the wattpool.net preferred miners.</p>
            <br />
            {this.makePortSelector(activeNonTlsStratums(this.props.poolStats))}
            <hr />
            <p><b>ccminer</b>: <a href="https://github.com/VerusCoin/ccminer/releases" target="ccminer_github">github</a> | <a href="https://github.com/wattpool/verus-ccminer-dockerized" target="ccminer_dockerized">dockerized</a></p>
            <p>Add the `-t` argument to control the number of threads.  For example, `-t 2` for dual-threaded, or `-t 8` for eight threads.</p>
            {this.makeTextField({
              tabName: "ccminer",
              stratumTcp: "ccminer -a verus -o stratum+tcp://" + pool + ":[stratumPort] -u $wallet.$rigName -p x"
            }, selectedValue)}
            <hr />
            <p><b>nheqminer</b>: <a href="https://github.com/VerusCoin/nheqminer/releases" target="nheqminer_github">github</a> | <a href="https://github.com/wattpool/verus-nheqminer-dockerized" target="ccminer_dockerized">dockerized</a></p>
            <p>Add the `-t` argument to control the number of threads.  For example, `-t 2` for dual-threaded, or `-t 8` for eight threads.</p>
            {this.makeTextField({
              tabName: "nheqminer",
              stratumTcp: "nheqminer -v -l " + pool + ":[stratumPort] -u $wallet.$rigName -p x"
            }, selectedValue)}
          </CardBody>
        </CardBody>
      </Card>
    );
  }

  aquachain() {
    const { classes, pool } = this.props;
    //const { tabName } = data;
    let { selectedValue } = this.state
    if (selectedValue === 0) {
      const stratums = activeGetworkPorts(this.props.poolStats);
      if (stratums.length > 0) {
        selectedValue = stratums[0].httpPort;
      }
    }
    const algo = this.algo();
    return (
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>aquacppminer</h4>
        </CardHeader>
        <CardBody>
          <CardBody>
            <p><b>AquaChain</b> uses the <b>{algo}</b> POW algorithm, which is <b>CPU-mineable</b> and requires a custom miner, <b>aquacppminer</b>.</p>
            <p>Even though <b>AquaChain</b> uses <b>{algo}</b>, you can still use the popular <a href="https://mycrypto.com?network=AQUA" target="mycrypto">MyCrypto</a> or <a href="https://vintage.myetherwallet.com?network=AQUA" target="mew">MyEtherWallet</a> <b>web wallets</b>.</p>
            <hr />
            <p>
              <nobr>
                <b>aquacppminer -t 1{" "}</b>
              </nobr>
              <nobr>
                <b>-F http://aquachain.wattpool.net:{selectedValue}/</b> <i>{"<"}WALLET_ADDRESS{">"}</i>/<i>{"<"}WORKER NAME{">"}</i>
              </nobr>
            </p>
            <p>Adjust the `-t` argument to control the number of threads.  For example, `-t 2` for dual-threaded, or `-t 8` for eight threads.</p>
            <hr />
            <p>Download the latest <b>aquacppminer</b> from <a href="https://aquacha.in/latest/?sort=time&order=desc" target="aquacppminer">https://aquacha.in/latest</a></p>
            <p>Miner Binary Releases:</p>
            <ul>
              <li>Windows 64bit: <a href="https://aquacha.in/latest/aquacppminer_v1.3.1_win64.zip" target="aquacppminerdownload">aquacppminer_v1.3.1_win64.zip</a></li>
              <li>Windows 32bit: <a href="https://aquacha.in/latest/aquacppminer_v1.3.1_win32.zip" target="aquacppminerdownload">aquacppminer_v1.3.1_win32.zip</a></li>
              <li>Linux AMD64: <a href="https://aquacha.in/latest/aquacppminer_v1.3.1.1_linux64.tar.gz" target="aquacppminerdownload">aquacppminer_v1.3.1.1_linux64.tar.gz</a></li>
            </ul>
            <br />
            {this.makePortSelector(activeGetworkPorts(this.props.poolStats), true)}
            {this.makeTextField({
              tabName: "aquacppminer",
              stratumTcp: "aquacppminer -F http://" + pool + ":[stratumPort]/$wallet/$rigName"
            }, selectedValue)}
          </CardBody>
        </CardBody>
      </Card>
    );
  }

  ubiq(tlsPorts) {
    const { pool } = this.props;
    return (
      <CustomTabs
        title="miner"
        headerColor="info"
        tabs={[
          {
            tabName: "Phoenix",
            text: "-coin " + this.phoenixCoin(pool) + " -pool " + pool + ":[stratumPort] -wal $wallet -rmode 2 -worker $rigName",
            stratumTcp: "-coin " + this.phoenixCoin(pool) + " -pool " + pool + ":[stratumPort] -wal $wallet -rmode 2 -worker $rigName",
            stratumTls: "-coin " + this.phoenixCoin(pool) + " -pool ssl://" + pool + ":[stratumPort] -wal $wallet -rmode 2 -worker $rigName"
          }
        ].map(o => this.makeTab(o, tlsPorts))}
      />
    )
  }

  wip() {
    const { classes, pool } = this.props;
    const algo = this.algo();
    return (
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>ethminer</h4>
        </CardHeader>
        <CardBody>
          <CardBody>
            <p><b>Solidum</b> uses the <b>{algo}</b> proof-of-work algorithm, which will likely be described in limited detail here one day.  But for now, we're trying to deploy this pool.  More detailed instructions and information will soon follow.</p>
            <p>Even though <b>Solidum</b> uses <b>{algo}</b>, you can still use the popular <a href="https://mycrypto.com?network=WEB" target="mycrypto">MyCrypto</a> or <a href="https://vintage.myetherwallet.com?network=WEB" target="mew">MyEtherWallet</a> <b>web wallets</b>.</p>
            <br />
            <p>This algorithm is not currently supported by MiningRigRentals or Nicehash</p>
            <br />
            <p>Choose from one of two miners:  (Pass <b>-U</b> for Nvidia or <b>-G</b> for AMD)</p>
            <ul>
              <li>
                <hr />
                <p>Download and build the latest <b>ethminer</b> from <a href="https://github.com/miscellaneousbits/ethminer/releases" target="ethminermisc">https://github.com/miscellaneousbits/ethminer/releases</a></p>
                <p><b>ethminer --algo progpow -G -P stratum://</b> <i>{"<"}WALLET_ADDRESS{">"}.{"<"}RIG_NAME{">"}</i> <b>@solidum.wattpool.net:8008</b></p>
                <hr />
                {this.makeTextField({
                  tabName: "ethminer",
                  stratumTcp: "ethminer --algo progpow -G -P stratum://$wallet.$rigName@" + pool + ":[stratumPort]"
                }, 8008)}
              </li>
              <li>
                <hr />
                <p>Download the latest build of @hackmod's <b>ethminer</b> from <a href="https://github.com/hackmod/ethminer/releases" target="ethminerhackmod">https://github.com/hackmod/ethminer/releases</a></p>
                <p><b>ethminer -U -P stratum://</b> <i>{"<"}WALLET_ADDRESS{">"}.{"<"}RIG_NAME{">"}</i> <b>@solidum.wattpool.net:8008</b></p>
                <hr />
                {this.makeTextField({
                  tabName: "ethminer",
                  stratumTcp: "ethminer -U -P stratum://$wallet.$rigName@" + pool + ":[stratumPort]"
                }, 8008)}
              </li>
            </ul>
          </CardBody>
        </CardBody>
      </Card>
    );
  }

  komodo() {
    const { classes, pool } = this.props;
    //const { tabName } = data;
    let { selectedValue } = this.state
    if (selectedValue === 0) {
      const stratums = activeNonTlsStratums(this.props.poolStats);
      if (stratums.length > 0) {
        selectedValue = stratums[0].stratumPort;
      }
    }
    const difficulty = "difficulty: ";
    // const algo = this.algo();
    const stratums = activeNonTlsStratums(this.props.poolStats)
    const diffStratums = stratums.map(a => {
      if (a.name.includes(difficulty)) {
        return a;
      }
      a.name = difficulty + a.name;
      return a;
    })
    return (
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>komodo miner</h4>
        </CardHeader>
        <CardBody>
          <CardBody>
            {this.makePortSelector(diffStratums)}
            <hr />
            {this.makeTextField({
              tabName: "komodo equihash miner",
              stratumTcp: "stratum+tcp:// $wallet . $rigName : x @" + pool + " : [stratumPort]"
            }, selectedValue, undefined, true)}
          </CardBody>
        </CardBody>
      </Card>
    );
  }

  getIntroMessage() {
    const { pool } = this.props;
    if (pool.split(".")[0].split("-")[0] === "etc") return (
      <big>
        <p>The Ethereum Classic pool has two ingest locations:</p>
        <ul>
          <li>eu.etc.wattpool.net [Europe]</li>
          <li>us.etc.wattpool.net [North America]</li>
        </ul>
      </big>
    );
    if (pool.split(".")[0].split("-")[0] === "eth") return (
      <big>
        <p>The Ethereum pool has two ingest locations:</p>
        <ul>
          <li>eu.eth.wattpool.net [Europe]</li>
          <li>us.eth.wattpool.net [North America]</li>
        </ul>
      </big>
    );
    return null;
  }

  render() {
    const { poolStats, pool } = this.props;
    if (typeof poolStats === "undefined" ||
      typeof poolStats.nodes === "undefined") return null;

    const tlsPorts = activeStratums(this.props.poolStats).map(s => { if (s.name.includes("tls")) { return s.stratumPort } else { return null } })

    if (pool.split(".")[0] === "webchain") return this.webchain();
    if (pool.split(".")[0] === "webchain-solo") return this.webchain();
    if (pool.split(".")[0] === "mintme") return this.webchain();
    if (pool.split(".")[0] === "mintme-solo") return this.webchain();
    if (pool.split(".")[0] === "verus") return this.verus();
    if (pool.split(".")[0] === "ubiq") return this.ubiq(tlsPorts);
    if (pool.split(".")[0] === "aqua") return this.aquachain();
    if (pool.split(".")[0] === "aqua-solo") return this.aquachain();
    if (pool.split(".")[0] === "komodo") return this.komodo();
    if (pool.split(".")[0] === "solidum") return this.wip();
    const introMessage = this.getIntroMessage();
    return (
      <CustomTabs
        title="miner"
        headerColor="info"
        tabs={[
          {
            tabName: "Phoenix",
            text: "-coin " + this.phoenixCoin(pool) + " -pool " + pool + ":[stratumPort] -wal $wallet -rmode 2 -worker $rigName",
            stratumTcp: "-coin " + this.phoenixCoin(pool) + " -pool " + pool + ":[stratumPort] -wal $wallet -rmode 2 -worker $rigName",
            stratumTls: "-coin " + this.phoenixCoin(pool) + " -pool ssl://" + pool + ":[stratumPort] -wal $wallet -rmode 2 -worker $rigName"
          },
          {
            tabName: "Claymore",
            text: "-wd 1 -r 1 -epool stratum+tcp://" + pool + ":[stratumPort] -ewal $wallet -eworker $rigName -esm 0 -epsw x -allcoins 1 -asm 1",
            stratumTcp: "-wd 1 -r 1 -epool stratum+tcp://" + pool + ":[stratumPort] -ewal $wallet -eworker $rigName -esm 0 -epsw x -allcoins 1 -asm 1",
            stratumTls: "-wd 1 -r 1 -epool stratum+tls://" + pool + ":[stratumPort] -ewal $wallet -eworker $rigName -esm 0 -epsw x -allcoins 1 -asm 1"
          },
          {
            tabName: "Ethminer",
            text: "-U -P stratum1+tcp://$wallet.$rigName@" + pool + ":[stratumPort]",
            stratumTcp: "-U -P stratum1+tcp://$wallet.$rigName@" + pool + ":[stratumPort]"
          }
        ].map(o => this.makeTab(o, tlsPorts, introMessage))}

      // {[
      //   {
      //     tabName: "Phoenix",
      //     //tabIcon: CheckBox,
      //     tabContent: (
      //       <Table
      //         tableHeaderColor={color}
      //         tableHead={["Height", "Block Hash", "Time Found", "Variance", "Reward"]}
      //         tableData={[]}
      //       />
      //     )
      //   },
      //   {
      //     tabName: "Claymore",
      //     //tabIcon: IndeterminateCheckBox,//Code,
      //     tabContent: (
      //       <Table
      //         tableHeaderColor={color}
      //         tableHead={["Height", "Block Hash", "Time Found", "Variance", "Reward"]}
      //         tableData={[]}
      //       />
      //     )
      //   },
      //   {
      //     tabName: "Ethminer",
      //     //tabIcon: CheckBoxOutlineBlank, //Cloud,
      //     tabContent: (
      //       <Table
      //         tableHeaderColor={color}
      //         tableHead={["Height", "Time Found", "Variance"]}
      //         tableData={[]}
      //       />
      //     )
      //   }
      // ]}
      />
    );
  }
}

export default withStyles(style)(CommandLine);
