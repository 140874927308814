import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
// import Apps from "@material-ui/icons/Apps";
import Casino from "@material-ui/icons/Casino";
import Person from "@material-ui/icons/Person";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// import Notifications from "@material-ui/icons/Notifications";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";
// core components
// import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import Icon from "@material-ui/core/Icon";
// import GridItem from "components/Grid/GridItem.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

import { poolAccount, apiServer } from "variables/util.jsx";

const poolsURL = apiServer + "/all/api/pools";

class HeaderLinks extends React.Component {
  state = {
    pools: [],
    color: "primary",
    open: false
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  update() {
    try {
      fetch(poolsURL)
        .then(res => res.json())
        .then(
          result => {
            try {
              if (typeof result !== "undefined") {
                this.setState({ pools: result.map(a => a.split(".")[0]) });
                //  &&
                //   (typeof result[this.state.selected] !== "undefined") &&
                //   (typeof result[this.state.selected].nodes !== "undefined") &&
                //   (typeof result[this.state.selected].nodes.length > 0)) {
                //   this.setState({ poolStats: result[this.state.selected] });
              }
            } catch (e) {
              console.log(e);
            }
            // this.setState({
            //   isLoaded: true,
            //   items: result.items
            // });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          error => {
            // this.setState({
            //   isLoaded: true,
            //   error
            // });
          }
        );
    } catch (e) {
      console.log(e);
    }
  }

  intervalID = 0;
  u = () => this.update();

  componentDidMount() {
    this.intervalID = setInterval(this.u, 4000);
    this.u();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  poolTitle() {
    const { classes, pool, account } = this.props;
    const { color } = this.state;
    return ((typeof pool === "string") && (pool.length > 0)) ?
      //<Button
      //   color={window.innerWidth > 959 ? "transparent" : "white"}
      //   //justIcon={window.innerWidth > 959}
      //   simple={!(window.innerWidth > 959)}
      //   //aria-label="Person"
      //   className={classes.buttonLink}
      // >
      //   {/* <Person className={classes.icons} /> */}
      //   <p className={classes.linkText}><font size="+10" color="#ffffff">{pool}</font></p>
      //</Button>
      // <GridContainer>
      //   <GridItem xs={12} sm={6} md={6} lg={4}>
      <Card>
        <CardHeader color={color} icon>
          {/* <CardIcon color={color}>
            <Icon>link</Icon>
          </CardIcon> */}
          <font className={classes.cardCategory} align="center" color="#000000">
            {poolAccount(pool, account, "#ffffff")}
          </font>
        </CardHeader>
      </Card>
      //   </GridItem>
      // </GridContainer>
      : null;
  }

  breadcrumb() {
    const { classes, pool, account } = this.props;
    if (typeof pool === "undefined") return null;
    return (
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Person"
        className={classes.buttonLink}
      >
        <Person className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>
            {poolAccount(pool, account, "#ffffff")}
          </p>
        </Hidden>
      </Button>
    );
  }

  render() {
    const { classes, landing } = this.props;
    const { open, pools } = this.state;
    // FIXME: landing, if present, will trigger poolLanding link in "POOLS" 'notification'
    const poolLanding = (typeof landing === "undefined" || landing === "account") ? "stats" : landing;
    return (
      <div>
        {this.breadcrumb()}
        {/* <p className={classes.searchWrapper}>{this.poolTitle()}</p> */}
        {/* <div className={classes.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: classes.margin + " " + classes.search
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search"
              }
            }}
          />
          <Button color="white" aria-label="edit" justIcon round>
            <Search />
          </Button>
        </div>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Dashboard"
          className={classes.buttonLink}
        >
          <Dashboard className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Dashboard</p>
          </Hidden>
        </Button> */}
        <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
            className={classes.buttonLink}
          >
            <Casino className={classes.icons} />
            <span className={classes.notifications}>
              &nbsp;pools&nbsp;
            {/*pools.length*/}
            </span>
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                {/* Pools */}
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !open }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      {pools.map(p =>
                        <a href={"/ui/" + p + "/" + poolLanding} key={p}>
                          <MenuItem
                            onClick={this.handleClose}
                            className={classes.dropdownItem}
                          >
                            {/* <FiberManualRecord className={classes.icons} /> */}
                            {p}
                          </MenuItem>
                        </a>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
        {/* <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Person"
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button> */}
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
