import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import { plainHashes } from "variables/util.jsx";

class WorkersOnline extends React.Component {
  render() {
    const { classes, minerStats, color } = this.props;
    if (
      typeof minerStats === "undefined" ||
      typeof minerStats === "string" ||
      typeof minerStats.currentHashrate == "undefined" ||
      typeof minerStats.hashrate == "undefined"
    ) return null;
    if (minerStats.currentHashrate === minerStats.hashrate)
      return (
        <Card>
          <CardHeader color={color} stats icon>
            <CardIcon color={color}>
              <Icon>network_check</Icon>
            </CardIcon>
            <p className={classes.cardTitle}><small>hash rate:</small> <nobr><b>{plainHashes(minerStats.currentHashrate)}</b></nobr></p>
            {/* <h3 className={classes.stats}>Workers Total: {minerStats.workersTotal}</h3> */}
          </CardHeader>
        </Card>
      )
    else
      return (
        <Card>
          <CardHeader color={color} stats icon>
            <CardIcon color={color}>
              <Icon>network_check</Icon>
            </CardIcon>
            <p className={classes.cardTitle}><small>hash rate (short):</small> <nobr><b>{plainHashes(minerStats.currentHashrate)}</b></nobr></p>
            <p className={classes.cardTitle}><small>hash rate (long):</small> <nobr><b>{plainHashes(minerStats.hashrate)}</b></nobr></p>
            {/* <h3 className={classes.stats}>Workers Total: {minerStats.workersTotal}</h3> */}
          </CardHeader>
        </Card>
      );
  }
}

WorkersOnline.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  minerStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(WorkersOnline);
