import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
// import GridItem from "components/Grid/GridItem.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import ChartistGraph from "react-chartist";

import { timeUnits, hashes, plainHashes, alwaysShorten } from "variables/util.jsx";

function shorten(s) {
  return window.innerWidth > 1259 ? s : s.substring(0, 6) + '...' + s.slice(-4);
}

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    // "& small": {
    //   color: "#777",
    //   fontSize: "65%",
    //   fontWeight: "400",
    //   lineHeight: "1"
    // }
  }
};

class Miners extends React.Component {
  chart(miners, minersSortedByHashrate) {
    var sorted = [];
    var unsorted = minersSortedByHashrate.slice(0);
    do {
      if (unsorted.length > 0) {
        sorted.push(unsorted.pop());
      }
      if (unsorted.length > 0) {
        sorted.push(unsorted.shift());
      }
    } while (unsorted.length > 0);

    var chartOptions = {
      chartPadding: 30,
      labelOffset: 100,
      labelDirection: "explode",
      labelInterpolationFnc: function (addr) {
        return alwaysShorten(addr) + ": " + plainHashes(miners.miners[addr].hr)
      }
    };
    if ((this.props.selected.split(".")[0] === "verus") || (this.props.selected.split(".")[0] === "komodo")) {
      chartOptions = {
        ...chartOptions,
        ...{
          donut: true,
          donutWidth: 80,
          donutSolid: true,
          startAngle: 270,
          showLabel: true
        }
      };
    }

    return (
      <ChartistGraph
        className="ct-chart"
        options={
          chartOptions
        }
        data={{
          labels: sorted,
          series: sorted.map(k => miners.miners[k].hr)
        }}
        type="Pie"
        style={{ height: 400 }}
      />
    );
  }
  render() {
    const { classes, color, miners, selected, blocks, poolStats, timeRightNow } = this.props;
    if (typeof miners === "undefined" ||
      typeof miners.miners === "undefined" ||
      typeof selected === "undefined") return null;
    const baseURL = "/ui/" + selected.split(".")[0] + "/";
    //const totalRoundShares = Object.keys(miners.miners).map(k => miners.miners[k].roundShares).reduce((a, b) => { return a + b; }, 0);
    const totalRoundShares = poolStats.stats.roundShares;

    var lastBlockFound = {};
    const blockHeight = Math.max(
      parseInt(
        poolStats.nodes
          .filter(({ lastBeat }) => poolStats.now / 1000 - parseInt(lastBeat) < 120)
          .map(({ height }) => height)
          .reduce((m, i) => m.concat(i), [])
      )
    )
    var oldestBlock = blockHeight;

    (blocks.candidates ? blocks.candidates : []).map(({ miner, timestamp, height }) => {
      if (miner !== "") {
        if (typeof lastBlockFound[miner] === "undefined") {
          lastBlockFound[miner] = 0;
        }
        if (timestamp > lastBlockFound[miner]) {
          lastBlockFound[miner] = timestamp;
        }
        if (height < oldestBlock) { oldestBlock = height; }
      }
    });

    (blocks.immature ? blocks.immature : []).map(({ miner, timestamp, height }) => {
      if (miner !== "") {
        if (typeof lastBlockFound[miner] === "undefined") {
          lastBlockFound[miner] = 0;
        }
        if (timestamp > lastBlockFound[miner]) {
          lastBlockFound[miner] = timestamp;
        }
        if (height < oldestBlock) { oldestBlock = height; }
      }
    });

    (blocks.matured ? blocks.matured : []).map(({ miner, timestamp, height }) => {
      if (miner !== "") {
        if (typeof lastBlockFound[miner] === "undefined") {
          lastBlockFound[miner] = 0;
        }
        if (timestamp > lastBlockFound[miner]) {
          lastBlockFound[miner] = timestamp;
        }
        if (height < oldestBlock) { oldestBlock = height; }
      }
    });

    const minersUnsorted = Object.keys(miners.miners)
    const minersSortedByHashrate = minersUnsorted.sort((a, b) => miners.miners[b].hr - miners.miners[a].hr)

    return (
      <Card> {/* Card Plain for transparent */}
        <CardHeader color={color}>
          <h4 className={classes.cardTitleWhite}>
            <nobr>
              {selected.split(".")[0]} pool total hash rate:{" "}<wbr />
              {plainHashes(miners.hashrate)}/s
              {/*<small>/s</small>*/}
            </nobr>
          </h4>
          <p className={classes.cardCategoryWhite}>
            total miners: {miners.minersTotal}
          </p>
        </CardHeader>
        <CardBody>
          {(minersUnsorted.length > 1) ? this.chart(miners, minersSortedByHashrate) : null}
          <Table
            tableHeaderColor={color}
            tableHead={["miner", "hashrate", "workers", "round share", "last share", "recent blocks found", "last block found", "rewards pending", "last paid"]}
            tableData={minersSortedByHashrate.map(k => [
              <a href={baseURL + "account/" + k}>{shorten(k)}</a>,
              <nobr>{hashes(miners.miners[k].hr)}</nobr>,
              miners.miners[k].workersTotal,
              (totalRoundShares > 0 ? (Math.round(10000 * miners.miners[k].roundShares / totalRoundShares) / 100).toFixed(2) : 0) + " %",
              timeUnits(
                (timeRightNow/*miners.now*/ - miners.miners[k].lastBeat * 1000) / 1000
              ),
              miners.miners[k].stats.blocksFound,
              ((miners.miners[k].stats.blocksFound > 0) && (typeof lastBlockFound[k] === "undefined")) ? " > " + (blockHeight - oldestBlock) + " blocks" : timeUnits(
                (miners.now - lastBlockFound[k] * 1000) / 1000
              ),
              (Math.trunc((miners.miners[k].stats.balance + miners.miners[k].stats.immature) / 1000000) / 1000).toFixed(3),
              timeUnits(
                (timeRightNow/*miners.now*/ - miners.miners[k].lastPaid * 1000) / 1000
              )
            ])}
          />
        </CardBody>
      </Card>
    );
  }
}

Miners.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  miners: PropTypes.object.isRequired,
  blocks: PropTypes.object.isRequired,
  poolStats: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired
};

export default withStyles(styles)(Miners);
