import React from "react";
import MetaTags from 'react-meta-tags';
// import { Text, View } from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Hidden from '@material-ui/core/Hidden';
// import Table from "components/Table/Table.jsx";
// import Tasks from "components/Tasks/Tasks.jsx";
// import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
// import Danger from "components/Typography/Danger.jsx";
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import PoolHash from "components/Pool/Graphs/PoolHash.jsx";
// import HashRateCard from "components/Pool/Cards/HashRate.jsx";
import MinersOnlinePoolFeeCard from "components/Pool/Cards/MinersOnlinePoolFee.jsx";
import PoolHashRateCard from "components/Pool/Cards/PoolHashRate.jsx";
//import NetworkDifficultyBlockHeightCard from "components/Pool/Cards/NetworkDifficultyBlockHeight.jsx";
import NetworkDifficultyBlockTimeCard from "components/Pool/Cards/NetworkDifficultyBlockTime.jsx";
import BlockHeightDagSizeCard from "components/Pool/Cards/BlockHeightDagSize.jsx";
// import NetHashCard from "components/Pool/Cards/NetHash.jsx";
import ConnectCard from "components/Pool/Cards/Connect.jsx";
// import BlockHeightCard from "components/Pool/Cards/BlockHeight.jsx";
// import LastBlockCard from "components/Pool/Cards/LastBlock.jsx";
// import RoundVarianceCard from "components/Pool/Cards/RoundVariance.jsx";
import LastBlockFoundRoundVarianceCard from "components/Pool/Cards/LastBlockFoundRoundVariance.jsx";
// import PoolListTable from "components/Pool/poolListTable.jsx";
// import { bugs, website, server } from "variables/general.jsx";
import TradingCard from "components/Pool/Cards/Trading.jsx";
import PriceCard from "components/Pool/Cards/Price.jsx";
import Ads from "components/Pool/Cards/Ads.jsx";
import Welcome from "components/Pool/Cards/Welcome.jsx";

// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart
// } from "variables/charts.jsx";

//import { explorers } from "variables/stats.jsx";
import { apiServer, getBlockTime } from "variables/util.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const _statsURL = apiServer + "/all/api/stats?charts=true";
const _infoURL = apiServer + "/all/api/info";
const _priceURL = apiServer + "/all/api/price";

class Dashboard extends React.Component {
  state = {
    value: 0,
    blockTime: 14.4,
    //explorer: explorers[this.props.match.params.pool + ".wattpool.net"].explorer,
    selected: this.props.match.params.pool + ".wattpool.net",
    price: {},
    poolStats: ""// stockStats[this.props.match.params.pool + ".wattpool.net"]
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  update() {
    let statsURL = _statsURL.replace("all", this.props.match.params.pool)
    let priceURL = _priceURL.replace("all", this.props.match.params.pool)
    let infoURL = _infoURL.replace("all", this.props.match.params.pool)
    try {
      Promise.all([
        fetch(statsURL).then(v => v.json()),
        fetch(priceURL).then(v => v.json()),
        fetch(infoURL).then(v => v.json())
      ]).then(allResponses => {

        const stats = allResponses[0];
        const price = allResponses[1];
        const info = allResponses[2];
        try {
          if (
            typeof stats !== "undefined" &&
            typeof stats.nodes !== "undefined"
          ) {
            this.setState({
              poolInfo: info,
              poolStats: stats,
              price: price,
              blockTime: getBlockTime(stats),
            });
            //  &&
            //   (typeof result[this.state.selected] !== "undefined") &&
            //   (typeof result[this.state.selected].nodes !== "undefined") &&
            //   (typeof result[this.state.selected].nodes.length > 0)) {
            //   this.setState({ poolStats: result[this.state.selected] });
          }
        } catch (e) {
          console.log(e);
        }
        // this.setState({
        //   isLoaded: true,
        //   items: result.items
        // });
      },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          // this.setState({
          //   isLoaded: true,
          //   error
          // });
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  intervalID = 0;
  u = () => this.update();
  timeNowID = 0;
  t = () => { this.setState({ timeRightNow: new Date().getTime() }) }

  componentDidMount() {
    this.intervalID = setInterval(this.u, 2000);
    this.timeNowID = setInterval(this.t, 1000);
    this.u();
    this.t();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    //console.log(this.state.poolStats)
    //console.log(this.props.data)
    const { classes } = this.props;
    const { poolStats, poolInfo, blockTime, selected, price, timeRightNow } = this.state;
    const graphColor = (typeof poolStats !== "undefined" &&
      typeof poolStats !== "string" &&
      typeof poolStats.stats !== "undefined" &&
      ((poolStats.now - poolStats.stats.lastBlockFound * 1000) / 1000) <= 12) ? "info" : "success";
    const baseURL = "/ui/" + selected.split(".")[0] + "/";
    const priceCard = <PriceCard
      classes={classes}
      selected={selected}
      price={price}
      color="success"
    />
    const priceCardItem = (priceCard === null) ? null : <GridItem xs={12} sm={6} md={6} lg={4}>
      {priceCard}
    </GridItem>
    const tradingCard = <TradingCard
      classes={classes}
      selected={selected}
      price={price}
      color="primary"
    />
    const tradingCardItem = (tradingCard === null) ? null : <GridItem xs={12} sm={6} md={6} lg={4}>
      {tradingCard}
    </GridItem>

    return poolStats === "" ? null : (
      <div>
        <MetaTags>
          <title>{selected}</title>
          <meta name="keywords" content={selected.split(".")[0] + ",wattpool,crypto,cryptocurrency,blockchain,mining,pool,bitcoin,btc,ethereum,eth"} />
          <meta
            name="description"
            content={selected.split(".")[0] + " mining pool on " + selected}
          />
          <meta property="og:title" content={selected} />
          <meta property="og:image" content="https://wattpool.net/poolchart.jpg" />
          <meta property="og:description" content={selected.split(".")[0] + " mining pool on " + selected} />
          <meta property="og:url" content={"https://" + selected} />
          <meta name="twitter:card" content={selected} />
          <meta property="og:site_name" content={selected} />
          <meta name="twitter:image:alt" content={selected} />
          <meta property="twitter:title" content={selected} />
          <meta property="twitter:image" content="https://wattpool.net/poolchart.jpg" />
          <meta property="twitter:description" content={selected.split(".")[0] + " mining pool on " + selected} />
          <meta property="twitter:url" content={"https://" + selected} />
        </MetaTags>

        {/* {this.props.match.params.pool} */}
        <Welcome
          selected={selected}
          location={this.props.location}
          poolInfo={poolInfo}
          poolStats={poolStats}
        />
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <PoolHashRateCard
              classes={classes}
              poolStats={poolStats}
              blockTime={blockTime}
              color="danger"
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={4}>
            <NetworkDifficultyBlockTimeCard
              classes={classes}
              poolStats={poolStats}
              selected={selected}
              color="rose"
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={4}>
            <a href={baseURL + "blocks"}>
              <BlockHeightDagSizeCard
                classes={classes}
                poolStats={poolStats}
                selected={selected}
                color="info"
              />
            </a>
          </GridItem>

          {tradingCardItem}

          <Hidden only={["xs", "sm", "md"]}>
            <GridItem lg={4} />
          </Hidden>

          {priceCardItem}

          {/* <GridItem xs={12} sm={6} md={3}>
            <NetHashCard
              classes={classes}
              poolStats={poolStats}
              color="info"
              blockTime={this.state.blockTime}
            />
          </GridItem> */}

          <GridItem xs={12} sm={6} md={6} lg={4}>
            <a href={baseURL + "miners"}>
              <MinersOnlinePoolFeeCard
                classes={classes}
                selected={selected}
                poolStats={poolStats}
                poolInfo={poolInfo}
                color="warning"
              />
            </a>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={4}>
            <a href={baseURL + "blocks"}>
              <LastBlockFoundRoundVarianceCard
                classes={classes}
                poolStats={poolStats}
                timeRightNow={timeRightNow}
                color="info"
              />
            </a>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={4}>
            <a href={baseURL + "connect"}>
              <ConnectCard
                classes={classes}
                poolStats={poolStats}
                selected={selected}
                color="rose"
              />
            </a>
          </GridItem>

          {/* <GridItem xs={12} sm={6} md={3}>
            <LastBlockCard
              classes={classes}
              poolStats={poolStats}
              color="info"
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <RoundVarianceCard
              classes={classes}
              poolStats={poolStats}
              color="danger"
            />
          </GridItem> */}
        </GridContainer>

        {/* <HashRateCard blockTime={this.state.blockTime} poolStats={this.state.poolStats[selectedPool]} /> */}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PoolHash
              color={graphColor}
              selected={selected}
              poolStats={poolStats}
            />
          </GridItem>
        </GridContainer>
        <Ads selected={selected} />
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
