import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class DigitalPrice extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     ...props
  //   };
  // }

  render() {
    const { classes, color } = this.props;
    return (
      <a href="https://digitalprice.io/?inviter=6ee7f2" target="digitalprice">
        <Card>
          <CardHeader color={color} stats>{/* icon> */}
            {/* <CardIcon color={"info"}>
              <Icon>iso</Icon>
            </CardIcon> */}
            <div align="center">
              <img alt="DigitalPrice" src="/digitalprice.png" width={160} />
            </div>
          </CardHeader>
          <p className={classes.cardTitle} align="right">
            <small><b><a href="https://digitalprice.io/?inviter=6ee7f2" target="digitalprice">exchange&nbsp;&nbsp;&nbsp;</a></b></small>
          </p>
        </Card>
      </a >
    );
  }
}

DigitalPrice.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  pool: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired,
  vertical: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(DigitalPrice);
