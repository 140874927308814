import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";

// core components
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import TwoCryptoCalc from "components/Pool/Cards/Ads/TwoCryptoCalc.jsx";
import FourMiners from "components/Pool/Cards/Ads/FourMiners.jsx";
import Ledger from "components/Pool/Cards/Ads/Ledger.jsx";
import MintMe from "components/Pool/Cards/Ads/MintMe.jsx";
import Mrr from "components/Pool/Cards/Ads/Mrr.jsx";


import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Ads extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     ...props
  //   };
  // }

  ledgerGridItem() {
    const { classes, selected } = this.props;
    return (
      <GridItem xs={12} sm={6}>
        <Ledger classes={classes} selected={selected} />
      </GridItem>
    );
  }

  mrrGridItem() {
    return (
      <GridItem xs={12} sm={6}>
        <Mrr />
      </GridItem>
    );
  }

  fourminersGridItem() {
    return (
      <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
        <FourMiners />
      </GridItem>
    );
  }

  twoCryptoCalc() {
    return (
      <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
        <TwoCryptoCalc />
      </GridItem>
    );
  }

  mintmeGridItem() {
    return (
      <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
        <MintMe color="info" />
      </GridItem>
    );
  }

  vertical(x) {
    //const pool = this.props.match.params.pool;
    const { vertical } = this.props;
    return (typeof vertical === "undefined" || !vertical) ? x : (<GridContainer>{x}</GridContainer>);
  }

  render() {
    return (
      <GridContainer>
        {this.vertical(this.ledgerGridItem())}
        {this.vertical(this.mrrGridItem())}
        {/* {this.vertical(this.fourminersGridItem())}
        {this.vertical(this.twoCryptoCalc())}
        {this.vertical(this.mintmeGridItem())} */}
      </GridContainer>
    );
  }
}

Ads.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  pool: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired,
  vertical: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(Ads);
