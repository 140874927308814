import React from "react";
import { Helmet } from "react-helmet";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardBody from "components/Card/CardBody.jsx";

import PaymentsCard from "components/Pool/Cards/Payments.jsx";

import { apiServer } from "variables/util.jsx";
//import { CardHeader } from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const _paymentsURL = apiServer + "/all/api/payments";
const _infoURL = apiServer + "/all/api/info";

class TableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: "", //ether1, //[selectedPool],
      info: "",
      explorer: "",
      selected: props.match.params.pool + ".wattpool.net",
      ...props
    };
  }

  update() {
    let paymentsURL = _paymentsURL.replace("all", this.props.match.params.pool)
    let infoURL = _infoURL.replace("all", this.props.match.params.pool)

    Promise.all([
      fetch(paymentsURL).then(v => v.json()),
      fetch(infoURL).then(v => v.json())
    ]).then(allResponses => {
      const poolPayments = allResponses[0];
      const info = allResponses[1];
      try {
        if ((typeof poolPayments.payments !== "undefined") && (poolPayments.payments !== null) && (poolPayments.payments.length > 0)) {
          this.setState({ payments: poolPayments, info: info, explorer: info.explorer });
        }
      } catch (e) {
        console.log(e)
      }
      // this.setState({
      //   isLoaded: true,
      //   items: result.items
      // });
    },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      error => {
        // this.setState({
        //   isLoaded: true,
        //   error
        // });
      }
    );
  }

  intervalID = 0;
  u = () => this.update();

  componentDidMount() {
    this.intervalID = setInterval(this.u, 3000);
    this.u();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  noPayments() {
    return (
      <GridContainer>
        <GridItem xs={4}>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader></CardHeader>
            <CardBody>
              <center>
                no payments yet
              </center>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={4}>
        </GridItem>
      </GridContainer>
    )
  }

  render() {
    const { classes } = this.props;
    if (typeof this.state === "undefined") return this.noPayments();
    const { payments, explorer } = this.state;
    if (typeof explorer === "undefined") return this.noPayments();
    if (typeof payments === "undefined" || typeof payments === "string") return this.noPayments();
    return (
      <div>
        <Helmet>
          <title>{"wattpool.net mining pools - payments"}</title>
          <meta name="keywords" content={"wattpool,crypto,cryptocurrency,blockchain,mining,pool,bitcoin,btc,ethereum,eth"} />
          <meta
            name="description"
            content={"wattpool.net mining pools"}
          />
        </Helmet>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PaymentsCard
              classes={classes}
              color="success"
              payments={payments}
              explorer={explorer}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(TableList);
