import React from "react";
import PropTypes from "prop-types";

import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import ChartistGraph from "react-chartist";

import withStyles from "@material-ui/core/styles/withStyles";

import { timeUnits } from "variables/util.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class MinerWorkerBlocksPieChart extends React.Component {
  render() {
    const { blocks, classes, color, poolStats } = this.props;
    const thisMiner = this.props.miner;
    if (typeof blocks === "undefined") return null;
    if (typeof thisMiner === "undefined") return null;
    var blocksFound = {};
    var totalBlocksFound = 0;
    var lastBlockFound = {};

    (blocks.candidates ? blocks.candidates : []).map(({ miner, worker, timestamp }) => {
      if (miner === thisMiner) {
        if (typeof blocksFound[worker] === "undefined") {
          blocksFound[worker] = 0;
          lastBlockFound[worker] = 0;
        }
        blocksFound[worker] = blocksFound[worker] + 1;
        totalBlocksFound = totalBlocksFound + 1;
        if (timestamp > lastBlockFound[worker]) {
          lastBlockFound[worker] = timestamp;
        }
      }
    });

    (blocks.immature ? blocks.immature : []).map(({ miner, worker, timestamp }) => {
      if (miner === thisMiner) {
        if (typeof blocksFound[worker] === "undefined") {
          blocksFound[worker] = 0;
          lastBlockFound[worker] = 0;
        }
        blocksFound[worker] = blocksFound[worker] + 1;
        totalBlocksFound = totalBlocksFound + 1;
        if (timestamp > lastBlockFound[worker]) {
          lastBlockFound[worker] = timestamp;
        }
      }
    });

    (blocks.matured ? blocks.matured : []).map(({ miner, worker, timestamp }) => {
      if (miner === thisMiner) {
        if (typeof blocksFound[worker] === "undefined") {
          blocksFound[worker] = 0;
          lastBlockFound[worker] = 0;
        }
        blocksFound[worker] = blocksFound[worker] + 1;
        totalBlocksFound = totalBlocksFound + 1;
        if (timestamp > lastBlockFound[worker]) {
          lastBlockFound[worker] = timestamp;
        }
      }
    });

    const finders = Object.keys(blocksFound)
    //if ((finders.length < 2) && (totalBlocksFound < 120)) return null;
    if (finders.length < 2) return null;
    const data = finders.sort((a, b) => a.localeCompare(b)).map(miner => ({ y: blocksFound[miner], label: miner, lastFound: lastBlockFound[miner] }))

    return (
      <Card>
        <CardHeader color={color}>
          <p className={classes.cardTitleWhite}>{"last " + totalBlocksFound + " blocks found by worker"}</p>
        </CardHeader>
        <CardBody>
          <ChartistGraph
            className="ct-chart"
            options={
              {
                chartPadding: 30,
                labelOffset: 100,
                labelDirection: "explode",
                labelInterpolationFnc: function (value) {
                  return value
                }
              }
            }
            data={{
              labels: data.map(({ label }) => label),
              series: data.map(({ y }) => y)
            }}
            type="Pie"
            style={{ height: 400 }}
          />
          <Table
            tableHeaderColor={color}
            tableHead={["worker", "blocks found", "last block found"]}
            tableData={data.sort((a, b) => b.y - a.y).map(({ y, label, lastFound }) => [
              label,
              y,
              timeUnits(
                (poolStats.now - lastFound * 1000) / 1000
              )
            ])}
          />
        </CardBody>
      </Card>
    );
  }
}

MinerWorkerBlocksPieChart.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  blocks: PropTypes.object.isRequired,
  miner: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(MinerWorkerBlocksPieChart);
