import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
// import Quote from "components/Typography/Quote.jsx";
// import Muted from "components/Typography/Muted.jsx";
// import Primary from "components/Typography/Primary.jsx";
// import Info from "components/Typography/Info.jsx";
// import Success from "components/Typography/Success.jsx";
// import Warning from "components/Typography/Warning.jsx";
// import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
function TypographyPage(props) {
  const { classes, color } = props;
  return (
    <Card>
      <CardHeader color={color}>
        <h4 className={classes.cardTitleWhite}>terms of service</h4>
        {/* <p className={classes.cardCategoryWhite}>
          Created using Roboto Font Family
        </p> */}
      </CardHeader>
      <CardBody>
        <p>By using the pool you accept all possible risks related to experimental software usage. Pool owner can't compensate any irreversible losses, but will do his best to prevent worst cases.</p>
      </CardBody>
    </Card>
  );
}

export default withStyles(style)(TypographyPage);
