import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// import { timeUnits, hashes } from "variables/util.jsx";

class MinerPay24 extends React.Component {
  immature() {
    const { immature } = this.props.minerStats.stats;
    if (immature === 0 || isNaN(immature)) return null
    return (<font size={1}>&nbsp;(<b>{Math.floor(immature / 1000000) / 1000}</b> immature)</font>)
  }
  toNum = a => typeof a === "number" ? a : 0
  render() {
    const { classes, minerStats, poolStats, color } = this.props;
    if (typeof minerStats === "undefined" ||
      typeof minerStats.stats === "undefined") return null;
    const { balance, immature, pending, paid } = minerStats.stats;
    const last24hrs = (typeof minerStats.payments === "undefined" ||
      minerStats.payments === null) ? [] :
      minerStats.payments.filter(o => (poolStats.now / 1000 - o.timestamp < 60 * 60 * 24)).map(o => o.amount)
    const last24hrPay = Math.floor(last24hrs.length === 0 ? 0 : last24hrs.reduce((a, b) => a + b) / 1000000) / 1000;

    return (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>attach_money</Icon>
          </CardIcon>
          <p className={classes.cardTitle}>
            <nobr>
              <small>balance: </small><b>{Math.floor((this.toNum(pending) + this.toNum(balance) + this.toNum(immature)) / 1000000) / 1000}</b>
              <wbr />{this.immature()}</nobr>
            <br />
            <small>paid last 24h:</small> <b>{last24hrPay}</b>
            <br />
            <font size={1}><small>total paid:</small> <b>{Math.floor(typeof paid !== "number" ? 0 : paid / 1000000) / 1000}</b></font>
          </p>
          {/* <h3 className={classes.stats}>{minerStats.workersTotal}</h3> */}
        </CardHeader>
      </Card>
    );
  }
}

MinerPay24.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  minerStats: PropTypes.object.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(MinerPay24);
