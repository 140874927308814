import React from "react";
// import { Text, View } from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Table from "components/Table/Table.jsx";
// import Tasks from "components/Tasks/Tasks.jsx";
// import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
// import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import NumericInput from 'react-numeric-input';

import MinerHash from "components/Pool/Graphs/MinerHash.jsx";
import MinerPayments from "components/Pool/Graphs/MinerPayments.jsx";
// import WorkersOnlineCard from "components/Pool/Cards/MinerWorkersOnline.jsx";
// import BalanceCard from "components/Pool/Cards/MinerBalance.jsx";
import Pay24Card from "components/Pool/Cards/MinerPay24.jsx";
// import WorkerList from "components/Pool/Cards/MinerWorkers.jsx";
// import PaymentList from "components/Pool/Cards/MinerPayments.jsx";
import LastBeatCard from "components/Pool/Cards/MinerLastBeat.jsx";

import MinerHashRateCard from "components/Pool/Cards/MinerHashRate.jsx";
// import MinersOnlineCard from "components/Pool/Cards/MinersOnline.jsx";
import PoolHashRateCard from "components/Pool/Cards/PoolHashRate.jsx";
// import NetworkDifficultyCard from "components/Pool/Cards/NetworkDifficulty.jsx";
// import NetHashCard from "components/Pool/Cards/NetHash.jsx";
// import PoolFeeCard from "components/Pool/Cards/PoolFee.jsx";
// import BlockHeightCard from "components/Pool/Cards/BlockHeight.jsx";
// import LastBlockCard from "components/Pool/Cards/LastBlock.jsx";
// import RoundVarianceCard from "components/Pool/Cards/RoundVariance.jsx";
// import LastBlockFoundRoundVarianceCard from "components/Pool/Cards/LastBlockFoundRoundVariance.jsx";
import TradingCard from "components/Pool/Cards/Trading.jsx";
import Welcome from "components/Pool/Cards/Welcome.jsx";
import MinerBlocksFoundWorkersOnline from "components/Pool/Cards/MinerBlocksFoundWorkersOnline.jsx";
// import { bugs, website, server } from "variables/general.jsx";
import Ads from "components/Pool/Cards/Ads.jsx";

import MinerWorkerBlocksPieChart from "components/Pool/Graphs/MinerWorkerBlocksPieChart.jsx";

import { apiServer, getBlockTime, activeStratums } from "variables/util.jsx";

// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart
// } from "variables/charts.jsx";

import { TextField, Button } from "@material-ui/core"; // Button too?

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const _poolStatsURL = apiServer + "/all/api/stats";
const _statsURL = apiServer + "/all/api/accounts/XXXX";
const _infoURL = apiServer + "/all/api/info";
const _priceURL = apiServer + "/all/api/price";
const _blocksURL = apiServer + "/all/api/blocks";

class Dashboard extends React.Component {
  state = {
    value: 0,
    blockTime: 14.4,
    explorer: "",
    miner: this.props.match.params.account,
    selected: this.props.match.params.pool + ".wattpool.net", // selectedPool,
    price: {},
    minerStats: "", // minerStats,
    poolStats: "", // stockStats[this.props.match.params.pool + ".wattpool.net"]
    blocks: "",
    info: "",
    enablePayoutControls: false,
    enableMinimumPayoutAmount: false,
    enableMinimumPayoutInterval: false,
    minPayoutAmount: 1,
    minPayoutInterval: "3h",
    newMinPayoutAmount: "",
    newMinPayoutInterval: "",
    newMinPayoutIpAddr: "",
    newIntervalDays: -1,
    newIntervalHours: -1,
    newIntervalMinutes: -1,
    hiddenPayoutControls: true
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  update() {
    const pool = this.props.match.params.pool;
    const statsURL = _statsURL
      .replace("all", pool)
      .replace("XXXX", this.state.miner);
    const poolStatsURL = _poolStatsURL.replace("all", pool);
    const infoURL = _infoURL.replace("all", pool);
    const priceURL = _priceURL.replace("all", this.props.match.params.pool);
    const blocksURL = _blocksURL.replace("all", this.props.match.params.pool);
    const exp = "^([0-9]+d){0,1}([0-9]+h){0,1}([0-9]+m){0,1}$";

    try {
      Promise.all([
        fetch(statsURL).then(v => v.json()),
        fetch(poolStatsURL).then(v => v.json()),
        fetch(infoURL).then(v => v.json()),
        fetch(priceURL).then(v => v.json()),
        fetch(blocksURL).then(v => v.json())
      ]).then(allResponses => {
        const minerStatsResult = allResponses[0];
        const poolStatsResult = allResponses[1];
        const info = allResponses[2];
        const price = allResponses[3];
        const blocks = allResponses[4];
        if (
          typeof minerStatsResult !== "undefined" &&
          typeof minerStatsResult.stats !== "undefined" &&
          typeof poolStatsResult !== "undefined" &&
          typeof poolStatsResult.nodes !== "undefined"
        ) {
          const thresh = typeof minerStatsResult.stats !== "undefined" && typeof minerStatsResult.stats.thresh !== "undefined" ? minerStatsResult.stats.thresh : "";
          var threshAmount = (typeof info.payout === "undefined") ? 1 : (typeof info.payout.size === "undefined") ? 1 : Math.round(info.payout.size);
          var threshInterval = "3h";
          var minPayAmtEnabled = false;
          var minPayIntEnabled = false;
          thresh.split(":").map(x => {
            if (x !== "" && x.match(exp)) {
              threshInterval = x;
              minPayIntEnabled = true;
            } else if (x.match("^[0-9]+$")) {
              threshAmount = x;
              minPayAmtEnabled = true;
            }
          });

          const days = parseInt('0' + threshInterval.match(exp)[1]);
          const hours = parseInt('0' + threshInterval.match(exp)[2]);
          const minutes = parseInt('0' + threshInterval.match(exp)[3]);

          const extras = this.state.info === "" ? {
            enableMinimumPayoutAmount: minPayAmtEnabled,
            enableMinimumPayoutInterval: minPayIntEnabled,
            enablePayoutControls: minPayAmtEnabled || minPayIntEnabled,
            newMinPayoutAmount: threshAmount,
            newIntervalDays: days,
            newIntervalHours: hours,
            newIntervalMinutes: minutes,
            newMinPayoutInterval: threshInterval
          } : {};

          this.setState({
            ...extras,
            minPayoutAmount: threshAmount,
            minPayoutInterval: threshInterval,
            minerStats: minerStatsResult,
            poolStats: poolStatsResult,
            blockTime: getBlockTime(poolStatsResult),
            info: info,
            price: price,
            blocks: blocks,
            explorer: info.explorer
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  intervalID = 0;
  u = () => this.update();
  timeNowID = 0;
  t = () => { this.setState({ timeRightNow: new Date().getTime() }) }

  componentDidMount() {
    this.intervalID = setInterval(this.u, (this.props.match.params.pool === "verus" || this.props.match.params.pool === "komodo") ? 40000 : 2000);
    this.timeNowID = setInterval(this.t, 1000);
    this.u();
    this.t();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  postThresh(url = '', data = {}) {
    // Default options are marked with *
    return fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'manual', // 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    }).then(this.handleErrors)
      .then(response => response.json());
  }

  payoutIntervalString = () => {
    const { newIntervalDays, newIntervalHours, newIntervalMinutes } = this.state;
    var s = "";
    if (newIntervalDays > 0) {
      s += ("" + newIntervalDays + "d")
    }
    if (newIntervalHours > 0) {
      s += ("" + newIntervalHours + "h")
    }
    if (newIntervalMinutes > 0) {
      s += ("" + newIntervalMinutes + "m")
    }
    return s;
  }

  handleSubmitButton = () => {
    this.postThresh('https://' + this.state.selected + '/api/accounts/' + this.state.miner + '/threshold', {
      addr: this.state.newMinPayoutIpAddr,
      threshold: this.state.newMinPayoutAmount + ':' + this.payoutIntervalString()
    }).then(a => {
      if (typeof a.interval !== "undefined" || typeof a.threshold !== "undefined") {
        alert("saved!")
      } else
        if (typeof a.error !== "undefined" && a.error === "no match") {
          alert("You must enter an IP address that matches one of your workers")
        }
    }).catch(a => {
      //alert(a)
      alert("You must enter an IP address that matches one of your workers")
    });
  };

  handleResetButton = () => {
    this.postThresh('https://' + this.state.selected + '/api/accounts/' + this.state.miner + '/threshold', {
      addr: this.state.newMinPayoutIpAddr,
      threshold: ''
    }).then(a => {
      if (a === {} || typeof a.cleared !== "undefined") {
        alert("reset to defaults!")
      } else
        if (typeof a.error !== "undefined" && a.error === "no match") {
          alert("You must enter an IP address that matches one of your workers")
        }
    }).catch(a => {
      //alert(a)
      alert("You must enter an IP address that matches one of your workers")
    });
  };

  handleMinPayIntervalDaysChange = (valueAsNumber, valueAsString, input) =>
    this.setState({
      newIntervalDays: valueAsNumber
    });

  handleMinPayIntervalHoursChange = (valueAsNumber, valueAsString, input) =>
    this.setState({
      newIntervalHours: valueAsNumber
    });

  handleMinPayIntervalMinutesChange = (valueAsNumber, valueAsString, input) =>
    this.setState({
      newIntervalMinutes: valueAsNumber
    });

  handleMinPayoutIpAddrTextChange = event =>
    this.setState({
      newMinPayoutIpAddr: event.target.value.trim()
    });

  handleMinPayoutAmountTextChange = event =>
    this.setState({
      newMinPayoutAmount: event.target.value.trim()
    });

  handleMinPayoutIntervalTextChange = event =>
    this.setState({
      newMinPayoutInterval: event.target.value.trim()
    });

  handleEnablePayoutControlsCheckboxChange = event =>
    this.setState({
      enablePayoutControls: event.target.checked
    });

  handleMinPayAmountCheckboxChange = event =>
    this.setState({ enableMinimumPayoutAmount: event.target.checked });

  handleMinPayIntervalCheckboxChange = event =>
    this.setState({ enableMinimumPayoutInterval: event.target.checked });

  blockchainWalletBalance() {
    const { minerStats, poolStats, info, miner } = this.state;

    const stratums = activeStratums(poolStats)
    const blockHeight = stratums.length > 0 ? stratums[0].height : 0;
    const walletHeight = ((typeof minerStats !== "undefined") &&
      (typeof minerStats.stats !== "undefined") &&
      (typeof minerStats.stats.walletHeight !== "undefined")) &&
      minerStats.stats.walletHeight > 0 ? minerStats.stats.walletHeight : blockHeight;
    const walletBalance = walletHeight > 0 ? minerStats.stats.wallet : 0;
    const units = (typeof info !== "undefined") && (typeof info.payout !== "undefined") ? info.payout.unit : "coins";
    const { baseURL, addr } = info.explorer;

    return /*walletBalance === 0 ? null :*/ (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <a href={baseURL + addr + miner} target="accountexplorer">
              <CardHeader color={"primary"}>
                {miner}
              </CardHeader>
            </a>
            <CardBody>
              <nobr>
                Wallet balance on the blockchain: <wbr />
                <b>{Math.round(walletBalance / 1000000) / 1000} {units}</b><wbr /> (as of block <b>{walletHeight}</b>)
                </nobr>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }

  payoutControls() {
    const { classes } = this.props;
    const { minerStats, poolStats, info } = this.state;

    const stratums = activeStratums(poolStats)
    const blockHeight = stratums.length > 0 ? stratums[0].height : 0;
    const walletBalance = blockHeight > 0 ? minerStats.stats.wallet : 0;
    // const units = (typeof info !== "undefined") && (typeof info.payout !== "undefined") ? info.payout.unit : "coins";

    return walletBalance === 0 ? null : (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color={"info"}>
              payout controls
            </CardHeader>
            <CardBody>
              {this.state.enablePayoutControls ? (
                <div>
                  <p className={classes.cardTitle}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={this.state.enableMinimumPayoutInterval}
                            onChange={this.handleMinPayIntervalCheckboxChange}
                            value="activeOnly"
                          />
                        }
                        label="enable minimum time interval between payouts"
                      />
                    </FormGroup>
                    {this.state.enableMinimumPayoutInterval ?
                      (<div>
                        Desired minimum time between payouts:
                        <br />
                        {/* <TextField
                          className={this.props.classes.cmd}
                          fullWidth={false}
                          //onFocus={this.handleFocus}
                          //onKeyDown={this.onTextboxKeyDown}
                          onChange={this.handleMinPayoutIntervalTextChange}
                          defaultValue={" " + this.state.minPayoutInterval}
                        /> */}
                        <table border={0}>
                          <tr>
                            <td>
                              <nobr>
                                <NumericInput
                                  min={0}
                                  max={99}
                                  defaultValue={this.state.newIntervalDays}
                                  // size={1}
                                  onChange={this.handleMinPayIntervalDaysChange}
                                  style={{
                                    input: {
                                      //color: "red",
                                      width: window.innerWidth > 959 ? 50 : 100
                                    }
                                  }}
                                />
                                {" "}days
                        </nobr>
                              <br />
                              <nobr>
                                <NumericInput
                                  min={0}
                                  max={99}
                                  defaultValue={this.state.newIntervalHours}
                                  // size={1}
                                  onChange={this.handleMinPayIntervalHoursChange}
                                  style={{
                                    input: {
                                      //color: "red",
                                      width: window.innerWidth > 959 ? 50 : 100
                                    }
                                  }}
                                />
                                {" "}hours
                        </nobr>
                              <br />
                              <nobr>
                                <NumericInput
                                  min={0}
                                  max={99}
                                  defaultValue={this.state.newIntervalMinutes}
                                  // size={1}
                                  onChange={this.handleMinPayIntervalMinutesChange}
                                  style={{
                                    input: {
                                      //color: "red",
                                      width: window.innerWidth > 959 ? 50 : 100
                                    }
                                  }}
                                />
                                {" "}minutes
                        </nobr>
                            </td>
                          </tr>
                          <tr>
                            <td valign="bottom">
                              {this.state.newIntervalDays + this.state.newIntervalHours + this.state.newIntervalMinutes > 0 ? "Your requested payment interval is: " : ""}
                              {this.state.newIntervalDays <= 0 ? "" : "" + this.state.newIntervalDays + " days "}
                              {this.state.newIntervalHours <= 0 ? "" : "" + this.state.newIntervalHours + " hours "}
                              {this.state.newIntervalMinutes <= 0 ? "" : "" + this.state.newIntervalMinutes + " minutes "}
                            </td>
                          </tr>
                        </table>
                      </div>) : null}
                  </p>
                  <p className={classes.cardTitle}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={this.state.enableMinimumPayoutAmount}
                            onChange={this.handleMinPayAmountCheckboxChange}
                            value="activeOnly"
                          />
                        }
                        label="enable minimum payout amount"
                      />
                    </FormGroup>
                    {this.state.enableMinimumPayoutAmount ?
                      (<div>Desired minimum payout ({info.payout.unit}):{" "}
                        <br />
                        <TextField
                          className={this.props.classes.cmd}
                          fullWidth={false}
                          //onFocus={this.handleFocus}
                          //onKeyDown={this.onTextboxKeyDown}
                          onChange={this.handleMinPayoutAmountTextChange}
                          defaultValue={" " + this.state.minPayoutAmount}
                        />
                      </div>) : null}
                  </p>
                  <p>
                    <div>Enter the IP address of one of your miners:{" "}
                      <br />
                      <TextField
                        className={this.props.classes.cmd}
                        fullWidth={false}
                        //onFocus={this.handleFocus}
                        //onKeyDown={this.onTextboxKeyDown}
                        onChange={this.handleMinPayoutIpAddrTextChange}
                      //defaultValue={" " + this.state.minPayoutAmount}
                      />
                    </div>
                  </p>
                </div>) : null}
              {/* <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.includeInactiveMiners}
                      onChange={this.handleCheckboxChange}
                      value="activeOnly"
                    />
                  }
                  label="include any inactive miner with a balance"
                />
              </FormGroup> */}
            </CardBody>
            {this.state.enablePayoutControls ? (
              <CardFooter>
                <div align="left">
                  <Button
                    onClick={this.handleSubmitButton}
                  >
                    SUBMIT
                  </Button>
                </div>
                <div align="right">
                  <Button
                    onClick={this.handleResetButton}
                  >
                    RESET
                  </Button>
                </div>
              </CardFooter>
            ) : (
                <CardFooter>
                  <div></div>
                  <div align="right">
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={this.state.enablePayoutControls}
                            onChange={this.handleEnablePayoutControlsCheckboxChange}
                            value="activeOnly"
                          />
                        }
                        label="enable custom payout controls"
                      />
                    </FormGroup>
                  </div>
                </CardFooter>
              )}
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
  payoutControlsNotAllowed = () =>
    this.props.match.params.pool === "verus" ||
    this.props.match.params.pool === "komodo" ||
    this.props.match.params.pool === "ethersocial";
  render() {
    //console.log(this.state.poolStats)
    //console.log(this.props.data)
    const { classes } = this.props;
    const { minerStats, poolStats, explorer, blockTime, selected, price, blocks, miner, info } = this.state;
    //const showBalance = this.props.match.params.pool !== "verus";
    const showBalance =
      typeof minerStats !== "undefined" &&
      typeof minerStats.stats !== "undefined" &&
      typeof minerStats.stats.wallet !== "undefined" &&
      minerStats.stats.wallet > 0;

    return (
      <div>
        <Welcome
          selected={selected}
          location={this.props.location}
          minerStats={minerStats}
          poolInfo={info}
          poolStats={poolStats}
          payoutControls={!this.payoutControlsNotAllowed()}
        />

        {showBalance ? this.blockchainWalletBalance() : null}

        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <a onClick={() => {
              this.setState({ hiddenPayoutControls: !this.state.hiddenPayoutControls });
            }}
            >
              <Pay24Card
                classes={classes}
                minerStats={minerStats}
                poolStats={poolStats}
                color="success"
              />
            </a>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={4}>
            <PoolHashRateCard
              classes={classes}
              poolStats={poolStats}
              blockTime={blockTime}
              color="danger"
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={4}>
            <MinerHashRateCard
              classes={classes}
              minerStats={minerStats}
              color="info"
            />
          </GridItem>

          {/* <GridItem xs={12} sm={6} md={6} lg={4}>
            <BalanceCard
              classes={classes}
              minerStats={minerStats}
              color="success"
            />
          </GridItem> */}

          <GridItem xs={12} sm={6} md={6} lg={4}>
            <MinerBlocksFoundWorkersOnline
              classes={classes}
              minerStats={minerStats}
              color="rose"
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={4}>
            <LastBeatCard
              classes={classes}
              minerStats={minerStats}
              poolStats={poolStats}
              timeRightNow={this.state.timeRightNow}
              color="warning"
            />
          </GridItem>

          {/* <GridItem xs={12} sm={6} md={3}>
            <WorkersOnlineCard
              classes={classes}
              minerStats={minerStats}
              color="primary"
            />
          </GridItem> */}

          <GridItem xs={12} sm={6} md={6} lg={4}>
            <TradingCard
              classes={classes}
              selected={selected}
              price={price}
              color="primary"
            />
          </GridItem>

        </GridContainer>

        {(this.state.hiddenPayoutControls || this.payoutControlsNotAllowed()) ? null : this.payoutControls()}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <MinerHash
              color="success"
              selected={selected}
              minerStats={minerStats}
              poolStats={poolStats}
              timeRightNow={this.state.timeRightNow}
            />
          </GridItem>

          {/* 
          <GridItem xs={12} sm={12} md={12}>
            <WorkerList
              color="warning"
              selected={this.state.selected}
              minerStats={minerStats}
            />
          </GridItem> */}
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <MinerWorkerBlocksPieChart
              blocks={blocks}
              miner={miner}
              color="primary"
              poolStats={poolStats}
            />
          </GridItem>
        </GridContainer>

        <Ads selected={selected} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <MinerPayments
              color="info"
              selected={selected}
              minerStats={minerStats}
              explorer={explorer}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired,
  pool: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
