import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const stex = ["akroma", "ether1", "mix", "egem", "ethergem", "atheios", "pirl", "reosc", "webchain", "mintme", "callisto", "perkle", "verus", "etc", "eth"];
const grav = ["akroma", "ether1", "mix", "egem", "ethergem", "pirl", "callisto", "expanse", "verus", "komodo", "etc", "eth"];
const digitalprice = ["verus", "komodo", "eth"];

class Trading extends React.Component {
  stex() {
    const { selected } = this.props;
    return stex.indexOf(selected.split(".")[0].split("-solo")[0]) < 0 ? null : (<a href="https://app.stex.com/?ref=41715718" target="stex">STEX&nbsp;&nbsp;&nbsp;</a>)
  }

  grav() {
    const { selected } = this.props;
    return grav.indexOf(selected.split(".")[0].split("-solo")[0]) < 0 ? null : (<a href="https://graviex.net/signup?ref=c1932b9b34edf20b7b9d1153" target="graviex">GRAVIEX</a>)
  }

  digitalprice() {
    const { selected } = this.props;
    return digitalprice.indexOf(selected.split(".")[0].split("-solo")[0]) < 0 ? null : (<a href="https://digitalprice.io/?inviter=6ee7f2" target="digitalprice">DIGITALPRICE&nbsp;&nbsp;&nbsp;</a>)
  }

  price() {
    const { classes, price } = this.props;
    if (price === null || typeof price === "undefined") return null;
    const priceUSD = typeof price.usd === "undefined" ? "" : price.usd.toFixed(4)
    const priceBTC = typeof price.usd === "undefined" ? "" : price.btc.toFixed(8)
    const priceETH = typeof price.usd === "undefined" ? "" : price.eth.toFixed(8)

    return priceUSD + priceBTC + priceETH === "" ? null : (
      <p className={classes.cardTitle}>{priceBTC} BTC / {priceETH} ETH<br />${priceUSD} USD</p>
    );
  }

  render() {
    const s = this.stex();
    const g = this.grav();
    const d = this.digitalprice();
    const hideStexGrav = (s === null && g === null && d === null);
    if (hideStexGrav) return null;
    const { classes, color, selected } = this.props;
    const t = [d, s, g].map(o => (<div className={classes.stats}><b>{o}</b></div>))
    const trade = hideStexGrav ? null : (<p className={classes.cardTitle}>trade <b>{selected.split(".")[0].split("-solo")[0]}</b> on: <br />{t}</p>)
    //const b = t.length > 1 ? " / " : null;

    return (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>timeline</Icon>
          </CardIcon>
          <small>
            {trade}
            {/* <div className={classes.stats}>
              <b><a href="https://app.stex.com/?ref=41715718" target="stex">STEX</a></b>
            </div>
            {"   "}
            <div className={classes.stats}>
              <b><a href="https://graviex.net/signup?ref=c1932b9b34edf20b7b9d1153" target="graviex">GRAVIEX</a></b>
            </div> */}
          </small>
        </CardHeader>
      </Card>
    );
  }
}

Trading.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired,
  price: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(Trading);
