import React from "react";
import { Helmet } from "react-helmet";
// import { Text, View } from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Table from "components/Table/Table.jsx";
// import Tasks from "components/Tasks/Tasks.jsx";
// import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
// import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import MinerHashChart from "components/Pool/Graphs/MinerHashChart.jsx";
// import MinerPayments from "components/Pool/Graphs/MinerPayments.jsx";
// import WorkersOnlineCard from "components/Pool/Cards/MinerWorkersOnline.jsx";
// import BalanceCard from "components/Pool/Cards/MinerBalance.jsx";
// import Pay24Card from "components/Pool/Cards/MinerPay24.jsx";
// import WorkerList from "components/Pool/Cards/MinerWorkers.jsx";
// import PaymentList from "components/Pool/Cards/MinerPayments.jsx";
// import LastBeatCard from "components/Pool/Cards/MinerLastBeat.jsx";

// import MinerHashRateCard from "components/Pool/Cards/MinerHashRate.jsx";
// import MinersOnlineCard from "components/Pool/Cards/MinersOnline.jsx";
// import PoolHashRateCard from "components/Pool/Cards/PoolHashRate.jsx";
// import NetworkDifficultyCard from "components/Pool/Cards/NetworkDifficulty.jsx";
// import NetHashCard from "components/Pool/Cards/NetHash.jsx";
// import PoolFeeCard from "components/Pool/Cards/PoolFee.jsx";
// import BlockHeightCard from "components/Pool/Cards/BlockHeight.jsx";
// import LastBlockCard from "components/Pool/Cards/LastBlock.jsx";
// import RoundVarianceCard from "components/Pool/Cards/RoundVariance.jsx";
// import LastBlockFoundRoundVarianceCard from "components/Pool/Cards/LastBlockFoundRoundVariance.jsx";
// import TradingCard from "components/Pool/Cards/Trading.jsx";
// import Welcome from "components/Pool/Cards/Welcome.jsx";
// import MinerBlocksFoundWorkersOnline from "components/Pool/Cards/MinerBlocksFoundWorkersOnline.jsx";
// import { Redirect } from 'react-router';
import { TextField } from "@material-ui/core"; // Button too?

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';

// import { bugs, website, server } from "variables/general.jsx";
// import Ads from "components/Pool/Cards/Ads.jsx";

// import { explorers } from "variables/stats.jsx";
import { apiServer, plainHashes } from "variables/util.jsx";

// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart
// } from "variables/charts.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { timeUnits, isAddress } from "variables/util.jsx";

const _poolStatsURL = apiServer + "/all/api/stats";
const _statsURL = apiServer + "/all/api/accounts/XXXX";

function defaultBoxText(props) {
  const miner = props.location.pathname.split("/")[3]
  return typeof miner !== "undefined" && isAddress(miner) ? miner : "Enter your wallet address";
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.onTextboxKeyDown = this.onTextboxKeyDown.bind(this);
  }
  state = {
    value: 0,
    miner: this.props.location.pathname.split("/")[3],//this.props.account,//this.props.match.params.account,
    selected: this.props.match.params.pool + ".wattpool.net", // selectedPool,
    minerStats: "", // minerStats,
    poolStats: "", // stockStats[this.props.match.params.pool + ".wattpool.net"]
    boxText: defaultBoxText(this.props),
    includeInactiveMiners: false
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  update() {
    if (!isAddress(this.state.miner)) { return };
    let statsURL = _statsURL.replace("XXXX", this.state.miner);
    let poolStatsURL = _poolStatsURL;

    try {
      Promise.all([
        fetch(statsURL).then(v => v.json()),
        fetch(poolStatsURL).then(v => v.json())
      ]).then(allResponses => {
        const minerStatsResult = allResponses[0];
        const poolStatsResult = allResponses[1];
        if (
          typeof minerStatsResult !== "undefined" &&
          typeof poolStatsResult !== "undefined"
        ) {
          this.setState({
            //miner: this.props.location.pathname.split("/")[3],
            minerStats: minerStatsResult,
            poolStats: poolStatsResult
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  intervalID = 0;
  u = () => this.update();

  componentDidMount() {
    this.intervalID = setInterval(this.u, 2000);
    this.u();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  hashrate(acctInfo) {
    const { currentHashrate, hashrate } = acctInfo;
    const eqHR = currentHashrate === hashrate;
    const hrText = eqHR || (currentHashrate + hashrate === 0) ? "hash rate: " + plainHashes(acctInfo.currentHashrate) : /*"hash rate: "+*/plainHashes(acctInfo.currentHashrate) + " (30m) / " + plainHashes(acctInfo.hashrate) + " (3h)";
    return (
      <nobr>
        {hrText}
      </nobr>
    )
  }

  poolCard(pool, miner, acctInfo) {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <a href={"/ui/" + pool.split(".")[0] + "/account/" + miner}>
            <Card>
              <CardHeader color="info">
                <b>{pool.split(".")[0]}</b>
                <MinerHashChart
                  color="info"
                  height="100px"
                  hideWorkers={true}
                  selected={pool}
                  minerStats={acctInfo}
                  poolStats={this.state.poolStats}
                />
              </CardHeader>
              <CardFooter>
                <div className={classes.stats}>
                  {this.hashrate(acctInfo)}
                </div>
                <div className={classes.stats}>
                  <nobr>
                    {acctInfo.workersTotal + " "}worker{acctInfo.workersTotal > 1 ? "s" : ""}
                  </nobr>
                </div>
                <div className={classes.stats}>
                  <nobr>
                    balance:{" " + Math.trunc((acctInfo.stats.balance + acctInfo.stats.immature + acctInfo.stats.pending) / 1000000) / 1000}
                  </nobr>
                </div>
                <div className={classes.stats}>
                  <nobr>
                    last share:{" " + timeUnits((this.state.poolStats[pool].now - 1000 * acctInfo.stats.lastShare) / 1000)}
                  </nobr>
                </div>
              </CardFooter>
            </Card>
          </a>
        </GridItem>
      </GridContainer>
    );
  }

  onTextboxKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      const addr = e.target.value.trim();
      const newLoc = "/ui/search/" + addr;
      if (isAddress(addr)) { window.location.assign(newLoc); }
    }
  };

  handleFocus = event => {
    event.target.select();
  };

  handleCheckboxChange = event =>
    this.setState({ includeInactiveMiners: event.target.checked })

  qualify = (minerStat) => {
    const v = !this.state.includeInactiveMiners ? 0 : minerStat.stats.balance + minerStat.stats.immature + minerStat.stats.pending;
    return v + minerStat.currentHashrate + minerStat.hashrate + minerStat.workersTotal > 0
  }

  render() {
    const { classes } = this.props;
    const { miner, minerStats } = this.state; // , poolStats, explorer, blockTime, selected
    return (
      <div>
        <Helmet>
          <title>{"wattpool.net miner account search"}</title>
          <meta name="keywords" content={"wattpool,crypto,cryptocurrency,blockchain,mining,pool,bitcoin,btc,ethereum,eth"} />
          <meta
            name="description"
            content={"wattpool.net mining pools"}
          />
        </Helmet>

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <p className={classes.cardTitle}>
                  <nobr>Miner account:{" "}
                    <TextField
                      className={this.props.classes.cmd}
                      fullWidth={true}
                      onFocus={this.handleFocus}
                      onKeyDown={this.onTextboxKeyDown}
                      //onChange={()=>{this._handleChangeEvent(this.state.data);}} 
                      defaultValue={" " + this.state.boxText}
                    />
                  </nobr>
                </p>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.includeInactiveMiners}
                        onChange={this.handleCheckboxChange}
                        value="activeOnly"
                      />
                    }
                    label="include any inactive miner with a balance"
                  />
                </FormGroup>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        {Object.keys(minerStats).filter(p =>
          this.qualify(minerStats[p])
        ).map(k => this.poolCard(k, miner, minerStats[k]))}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
  // account: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
