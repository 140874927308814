import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// import AccessTime from "@material-ui/icons/AccessTime";
// core components
// import GridItem from "components/Grid/GridItem.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import PaymentList from "components/Pool/Cards/MinerPayments.jsx";

import { minerPayments } from "variables/stats.jsx";
// import { timeUnits } from "variables/util.jsx";
// import { emailsSubscriptionChart } from "variables/charts.jsx"

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";


class MinerPayments extends React.Component {
  render() {
    //console.log(this.props.minerStats)
    //console.log(this.props.data)
    const { classes, minerStats, explorer } = this.props;
    if (typeof minerStats === "undefined" ||
      typeof minerStats.payments === "undefined" ||
      typeof minerStats.paymentCharts === "undefined") return null

    const payments = minerPayments(minerStats);
    let graphHeader =
      (<CardHeader color={this.props.color} >
        <ChartistGraph
          className="ct-chart"
          data={payments.data}
          type="Bar"
          options={payments.options}
          responsiveOptions={payments.responsiveOptions}
          listener={payments.animation}
        />
      </CardHeader >)

    return (
      <Card chart>
        {minerStats.paymentsTotal === 0 ? null : graphHeader}
        < CardBody >
          {/* <h4 className={classes.cardTitle}>Our Pool's Hash Rate</h4> */}
          < h4 className={classes.cardTitle} > miner payments: {minerStats.paymentsTotal}</h4 >
          {/* <p className={classes.cardCategory}>
                  Last Campaign Performance
                </p> */}
          < PaymentList color={this.props.color} minerStats={minerStats} explorer={explorer} />
        </CardBody >
        {/* <CardFooter chart>
          <div className={classes.stats}>
            <AccessTime /> updated {timeUnits(Math.round(this.props.minerStats.now / 1000) - Math.max.apply(null, this.props.minerStats.poolCharts.map(i => i.x)))} ago
          </div>
        </CardFooter> */}
      </Card >
    );
  }
}

MinerPayments.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  explorer: PropTypes.object.isRequired,
  minerStats: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(MinerPayments);
