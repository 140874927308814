import React from "react";
import { Helmet } from "react-helmet";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardBody from "components/Card/CardBody.jsx";

import MinersCard from "components/Pool/Cards/Miners.jsx";

import { apiServer } from "variables/util.jsx";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    // "& small": {
    //   color: "#777",
    //   fontSize: "65%",
    //   fontWeight: "400",
    //   lineHeight: "1"
    // }
  }
};

const _minersURL = apiServer + "/all/api/miners";
const _blocksURL = apiServer + "/all/api/blocks";
const _statsURL = apiServer + "/all/api/stats";
// const selectedPool = "ether1.wattpool.net"

class TableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      miners: "",//apiMiners, //[selectedPool],
      blocks: "",
      poolStats: "",
      selected: this.props.match.params.pool + ".wattpool.net",
      ...props
    };
  }

  update() {
    let minersURL = _minersURL.replace("all", this.props.match.params.pool)
    let blocksURL = _blocksURL.replace("all", this.props.match.params.pool)
    let statsURL = _statsURL.replace("all", this.props.match.params.pool)
    Promise.all([
      fetch(minersURL).then(v => v.json()),
      fetch(blocksURL).then(v => v.json()),
      fetch(statsURL).then(v => v.json())
    ]).then(allResponses => {
      const poolMiners = allResponses[0];
      const poolBlocks = allResponses[1];
      const poolStats = allResponses[2];
      try {
        if ((typeof poolMiners.miners !== "undefined") && (Object.keys(poolMiners.miners).length > 0)) {
          this.setState({ miners: poolMiners, blocks: poolBlocks, poolStats: poolStats });
        }
      } catch (e) {
        console.log(e)
      }
      // this.setState({
      //   isLoaded: true,
      //   items: result.items
      // });
    },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      error => {
        // this.setState({
        //   isLoaded: true,
        //   error
        // });
      }
    );
  }

  intervalID = 0;
  u = () => this.update();
  timeNowID = 0;
  t = () => { this.setState({ timeRightNow: new Date().getTime() }) }

  componentDidMount() {
    this.intervalID = setInterval(this.u, 3000);
    this.timeNowID = setInterval(this.t, 1000);
    this.u();
    this.t();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  noMiners() {
    return (
      <GridContainer>
        <GridItem xs={4}>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader></CardHeader>
            <CardBody>
              <center>
                no miners right now
              </center>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={4}>
        </GridItem>
      </GridContainer>
    )
  }


  render() {
    const { classes } = this.props;
    const { selected, miners, blocks, poolStats, timeRightNow } = this.state;
    return typeof miners === "undefined" || typeof miners === "string" ? this.noMiners() : (
      <div>
        <Helmet>
          <title>{"wattpool.net mining pools - miners"}</title>
          <meta name="keywords" content={"wattpool,crypto,cryptocurrency,blockchain,mining,pool,bitcoin,btc,ethereum,eth"} />
          <meta
            name="description"
            content={"wattpool.net mining pools"}
          />
        </Helmet>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <MinersCard
              classes={classes}
              color="primary"
              selected={selected}
              miners={miners}
              blocks={blocks}
              poolStats={poolStats}
              timeRightNow={timeRightNow}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(TableList);
