import React from "react";
import { Helmet } from "react-helmet";
// import { Text, View } from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Table from "components/Table/Table.jsx";
// import Tasks from "components/Tasks/Tasks.jsx";
// import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
// import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// import PoolHash from "components/Pool/Graphs/PoolHash.jsx";
// import HashRateCard from "components/Pool/Cards/HashRate.jsx";
// import MinersOnlineCard from "components/Pool/Cards/MinersOnline.jsx";
// import PoolHashRateCard from "components/Pool/Cards/PoolHashRate.jsx";
// import NetworkDifficultyCard from "components/Pool/Cards/NetworkDifficulty.jsx";
// import NetHashCard from "components/Pool/Cards/NetHash.jsx";
// import PoolFeeCard from "components/Pool/Cards/PoolFee.jsx";
// import BlockHeightCard from "components/Pool/Cards/BlockHeight.jsx";
// import LastBlockCard from "components/Pool/Cards/LastBlock.jsx";
// import RoundVarianceCard from "components/Pool/Cards/RoundVariance.jsx";
// import LastBlockFoundRoundVarianceCard from "components/Pool/Cards/LastBlockFoundRoundVariance.jsx";
// import LastBlockFoundCard from "components/Pool/Cards/AllPoolsLastBlockFound.jsx";
import PoolListTable from "components/Pool/poolListTable.jsx";
import Ads from "components/Pool/Cards/Ads.jsx";
// import { bugs, website, server } from "variables/general.jsx";

// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart
// } from "variables/charts.jsx";

import {
  apiServer,
  difficulty,
  hashes,
  timeUnits,
  //  getBlockTime,
  activeNonTlsStratums
} from "variables/util.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";


const infoURL = apiServer + "/all/api/info";
const statsURL = apiServer + "/all/api/stats";
const paymentsURL = apiServer + "/all/api/payments";

class Dashboard extends React.Component {
  state = {
    value: 0,
    allPools: "",
    allPayments: "",
    allInfo: ""
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  _objectWithoutProperties = (obj, keys) => { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }
  update() {
    try {
      Promise.all([
        fetch(statsURL).then(v => v.json()),
        fetch(paymentsURL).then(v => v.json()),
        fetch(infoURL).then(v => v.json())
      ]).then(allResponses => {
        const poolStatsResult = this._objectWithoutProperties(allResponses[0], ["genom.wattpool.net"]);
        const paymentsResult = this._objectWithoutProperties(allResponses[1], ["genom.wattpool.net"]);
        const infoResult = this._objectWithoutProperties(allResponses[2], ["genom.wattpool.net"]);
        if (
          typeof poolStatsResult !== "undefined"
        ) {
          this.setState({
            allPools: poolStatsResult,
            allPayments: paymentsResult,
            allInfo: infoResult
          });
        }
      })
    } catch (e) {
      console.log(e);
    }

    // try {
    //   fetch(statsURL)
    //     .then(res => res.json())
    //     .then(
    //       result => {
    //         try {
    //           if (
    //             typeof result !== "undefined"
    //           ) {
    //             this.setState({ allPools: result });
    //           }
    //         } catch (e) {
    //           console.log(e);
    //         }
    //       },
    //       // Note: it's important to handle errors here
    //       // instead of a catch() block so that we don't swallow
    //       // exceptions from actual bugs in components.
    //       error => {
    //         // this.setState({
    //         //   isLoaded: true,
    //         //   error
    //         // });
    //       }
    //     );
    // } catch (e) {
    //   console.log(e);
    // }
  }

  intervalID = 0;
  u = () => this.update();
  timeNowID = 0;
  t = () => { this.setState({ timeRightNow: new Date().getTime() }) }

  componentDidMount() {
    this.intervalID = setInterval(this.u, 2000);
    this.u();
    this.timeNowID = setInterval(this.t, 1000);
    this.t();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  roundVariance(k) {
    const { allPools } = this.state;
    const poolStats = allPools[k];
    const { roundShares } = poolStats.stats;
    return (typeof roundShares === "number") ? (
      <div>
        <nobr>
          round variance:{" "}
        </nobr>
        <br />
        <nobr>
          <b>{Math.round(
            (100 * roundShares) / difficulty(poolStats)
          )} <small>%</small></b>
        </nobr>
      </div>
    ) : null;
  }
  getMinimumPayout(chain) {
    const thisChainInfo = this.state.allInfo[chain];
    if (typeof thisChainInfo === "undefined") return "-";
    let mp = thisChainInfo.payout;
    return "" + mp.size + " " + mp.unit;
  }

  getPoolMethod(chain) {
    const thisChainInfo = this.state.allInfo[chain];
    return (typeof thisChainInfo === "undefined") ? "PROP" : thisChainInfo.payout.method;
  }

  minimumPayout(k) {
    const minPayout = this.getMinimumPayout(k);

    return !minPayout ? null : (
      <div>
        <nobr>
          min payout:{" "}
        </nobr>
        <br />
        <nobr>
          <b>{minPayout}</b>
        </nobr>
      </div>
    );
  }

  stratums(k) {
    const { classes } = this.props;
    const { allPools } = this.state;
    const poolStats = allPools[k];
    const stratums = activeNonTlsStratums(poolStats);
    if (stratums.length === 0) return null;
    const stratumPort = stratums[0].stratumPort;
    const getworkPort = stratums[0].httpPort;

    const stratum = typeof stratumPort !== "string" || stratumPort === "" ? null :
      (<div>
        <nobr>
          stratum:{" "}
        </nobr>
        <br />
        <nobr>
          <b><font size={1}>{k + ':' + stratumPort}</font></b>
        </nobr>
      </div>)

    const getwork = typeof getworkPort !== "string" || getworkPort === "" ? null :
      (<div>
        <nobr>
          getwork:{" "}
        </nobr>
        <br />
        <nobr>
          <b><font size={1}>{k + ':' + getworkPort}</font></b>
        </nobr>
      </div>)

    return (
      <CardFooter>
        <div className={classes.stats}>
          {stratum}
        </div>
        <div className={classes.stats} align="right">
          {getwork}
        </div>
      </CardFooter>
    );
  }

  algo(k) {
    const { allInfo } = this.state;
    if ((typeof allInfo === "undefined") ||
      (typeof allInfo[k] === "undefined")) {
      return "";
    }
    const poolInfo = allInfo[k];
    return (typeof poolInfo.algo !== "undefined") &&
      (poolInfo.algo !== null) &&
      (typeof poolInfo.algo.name !== "undefined") ? poolInfo.algo.name : "";
  }

  poolCardGridItem(k) {
    const { classes } = this.props;
    const { allPools, timeRightNow } = this.state;
    if (typeof k === "undefined" ||
      typeof allPools[k] === "undefined" ||
      typeof allPools[k].stats === "undefined") return null;
    //const blockTime = getBlockTime(allPools[k]);
    return (
      <GridItem key={k} xs={12} sm={6} md={6} lg={4}>
        <a href={"/ui/" + k.split(".")[0] + "/stats"}>
          <Card>
            <CardHeader color={((allPools[k].now - allPools[k].stats.lastBlockFound * 1000) / 1000) <= 10 ? "rose" : "info"}>
              {/* <CardIcon color={"rose"}>
              <Icon>extension</Icon>
            </CardIcon> */}
              <h4 className={classes.cardTitleWhite}>{k.split(".")[0]}</h4>
              {/* <p className={classes.cardTitle}>Blocks Found: <b>{minerStats.stats.blocksFound}</b></p>
              <p className={classes.cardTitle}>Workers Online: <b>{minerStats.workersOnline}</b></p> */}
            </CardHeader>
            <CardBody>
              <CardFooter>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      pool hash rate:{" "}
                    </nobr>
                    <br />
                    <nobr>
                      <b>{hashes(allPools[k].hashrate)}</b>
                    </nobr>
                  </div>
                </div>
                <div className={classes.stats} align="right">
                  <div>
                    <nobr>
                      network hash rate:{" "}
                    </nobr>
                    <br />
                    <nobr>
                      <b>{hashes(allPools[k].networkHashrate /*difficulty(allPools[k]) / blockTime*/)}</b>
                    </nobr>
                  </div>
                </div>
              </CardFooter>
              <CardFooter>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      difficulty:{" "}
                    </nobr>
                    <br />
                    <nobr>
                      <b>{hashes(difficulty(allPools[k]))}</b>
                    </nobr>
                  </div>
                </div>
                <div className={classes.stats} align="right">
                  <div>
                    <nobr>
                      block height:{" "}
                    </nobr>
                    <br />
                    <nobr>
                      <b>{Math.max(
                        parseInt(
                          allPools[k].nodes === null ? 0 :
                            allPools[k].nodes
                              .filter(({ lastBeat }) => allPools[k].now / 1000 - parseInt(lastBeat) < 120)
                              .map(({ height }) => height)
                        )
                      )}</b>
                    </nobr>
                  </div>
                </div>
              </CardFooter>
              <CardFooter>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      algorithm:{" "}
                    </nobr>
                    <br />
                    <nobr>
                      <b>{this.algo(k)}</b>
                    </nobr>
                  </div>
                </div>
                <div className={classes.stats} align="right">
                  <div>
                    <nobr>
                      method:{" "}
                    </nobr>
                    <br />
                    <nobr>
                      <b>{this.getPoolMethod(k)}</b>
                    </nobr>
                  </div>
                </div>
              </CardFooter>
              <CardFooter>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      recent blocks / last found:{" "}
                    </nobr>
                    <br />
                    <nobr>
                      <b>{allPools[k].immatureTotal + allPools[k].candidatesTotal}</b>
                    </nobr>
                    {" / "}
                    <nobr>
                      <b>{timeUnits((timeRightNow - allPools[k].stats.lastBlockFound * 1000) / 1000)}</b>
                    </nobr>
                  </div>
                </div>
                <div className={classes.stats} align="right">
                  {this.minimumPayout(k)}
                </div>
              </CardFooter>
              <CardFooter>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      miners online:{" "}
                    </nobr>
                    <br />
                    <nobr>
                      <b>{allPools[k].minersTotal}</b>
                    </nobr>
                  </div>
                </div>
                <div className={classes.stats} align="right">
                  {this.roundVariance(k)}
                </div>
              </CardFooter>
              {/* <CardFooter>
                <div className={classes.stats}>
                  <div>
                    <nobr>
                      blocks pending:{" "}
                    </nobr>
                    <br />
                    <nobr>
                      <b>{allPools[k].immatureTotal}</b>
                    </nobr>
                  </div>
                </div>
                <div className={classes.stats} align="right">
                  <div>
                    <nobr>
                      new blocks:{" "}
                    </nobr>
                    <br />
                    <nobr>
                      <b>{allPools[k].candidatesTotal}</b>
                    </nobr>
                  </div>
                </div>
              </CardFooter> */}
              {this.stratums(k)}
            </CardBody>
          </Card>
        </a>
      </GridItem >
    );
  }

  tableGridItem() {
    const { allPools, allPayments, allInfo, timeRightNow } = this.state;
    if (typeof allPools === "undefined") return null;
    return (
      <GridItem xs={12}>
        {/* sm={12} md={12} lg={8} xl={4}> */}
        <Card>
          <CardHeader color="primary">
            wattpool.net pool index at a glance
          </CardHeader>
          {/* <CardBody align='center'> */}
          {/* <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}> */}
          <PoolListTable poolStats={allPools} payments={allPayments} timeRightNow={timeRightNow} info={allInfo} />
          {/* </div> */}
          {/* </CardBody> */}
        </Card>
      </GridItem>
    );
  }

  render() {
    //console.log(this.state.poolStats)
    //console.log(this.props.data)
    const { allPools } = this.state;

    if (
      typeof allPools === "undefined" ||
      typeof allPools === "string"
    ) return null;

    const p = Object.keys(allPools).map(k => k).sort((a, b) => allPools[b].minersTotal - allPools[a].minersTotal).map(k => this.poolCardGridItem(k));

    return (
      <div>
        <Helmet>
          <title>{"wattpool.net pool index"}</title>
          <meta name="keywords" content={"wattpool,crypto,cryptocurrency,blockchain,mining,pool,bitcoin,btc,ethereum,eth"} />
          <meta
            name="description"
            content={"wattpool.net cryptocurrency mining pools"}
          />
        </Helmet>

        {/* {this.props.match.params.pool} */}
        <GridContainer>
          {this.tableGridItem()}
          {p}
        </GridContainer>
        <Ads />
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
