import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";

// core components
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";


import { timeUnits, hashes } from "variables/util.jsx";

/*
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    // "& small": {
    //   color: "#777",
    //   fontSize: "65%",
    //   fontWeight: "400",
    //   lineHeight: "1"
    // }
  }
};
*/

class Workers extends React.Component {
  isOffline(yesno, text) {
    return yesno ? (<i>{text}</i>) : (<div>{text}</div>);
  }
  render() {
    const { color, minerStats, poolStats, timeRightNow } = this.props;
    if (typeof minerStats === "undefined" ||
      typeof poolStats === "undefined" ||
      typeof minerStats.workers === "undefined" ||
      minerStats.workers === null
    ) return null;
    var eqHR = true;
    Object.keys(minerStats.workers).map(k => {
      if (minerStats.workers[k].hr !== minerStats.workers[k].hr2) eqHR = false;
      return eqHR;
    });
    var tableHeadings = eqHR ?
      ["worker", "hashrate", "", "last share", "difficulty"] :
      ["worker", "hashrate (rough, short average)", "hashrate (accurate, long average)", "last share", "difficulty"]
    return (
      <Table
        tableHeaderColor={color}
        tableHead={tableHeadings}
        tableData={Object.keys(minerStats.workers).map(k => [
          this.isOffline(minerStats.workers[k].offline, k),
          this.isOffline(minerStats.workers[k].offline, hashes(minerStats.workers[k].hr)),
          this.isOffline(minerStats.workers[k].offline, eqHR ? "" : hashes(minerStats.workers[k].hr2)),
          this.isOffline(minerStats.workers[k].offline, timeUnits(
            (timeRightNow - minerStats.workers[k].lastBeat * 1000) / 1000
          )),
          this.isOffline(minerStats.workers[k].offline, hashes(minerStats.workers[k].difficulty))
        ])
        }
      />
    );
  }
}

Workers.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  minerStats: PropTypes.object.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Workers);
