import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AccessTime from "@material-ui/icons/AccessTime";
// core components
// import GridItem from "components/Grid/GridItem.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { poolHashRate } from "variables/stats.jsx";
import { timeUnits } from "variables/util.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";


class PoolHash extends React.Component {
  render() {
    //console.log(this.props.poolStats)
    //console.log(this.props.data)
    const { classes, poolStats, selected } = this.props;
    if (typeof poolStats === "undefined" ||
      typeof poolStats.nodes === "undefined" ||
      typeof poolStats.poolCharts === "undefined" ||
      poolStats.poolCharts === null ||
      poolStats.poolCharts.length === 0) return (<hr />);
    return (
      <Card chart>
        <CardHeader color={this.props.color}>
          <ChartistGraph
            className="ct-chart"
            data={poolHashRate(poolStats).data}
            type="Line"
            options={poolHashRate(poolStats).options}
            listener={poolHashRate(poolStats).animation}
            style={{ height: "300px" }}
          />
        </CardHeader>
        <CardBody>
          {/* <h4 className={classes.cardTitle}>Our Pool's Hash Rate</h4> */}
          <h4 className={classes.cardTitle}>{selected} hash rate</h4>
          {/* <p className={classes.cardCategory}>
                  Last Campaign Performance
                </p> */}
        </CardBody>
        <CardFooter chart>
          <div className={classes.stats}>
            {/* <AccessTime /> updated 2 minutes ago */}
            <AccessTime /> updated {timeUnits(Math.round(poolStats.now / 1000) - Math.max.apply(null, poolStats.poolCharts.map(i => i.x)))} ago
                </div>
        </CardFooter>
      </Card>
    );
  }
}

PoolHash.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(PoolHash);
