import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
// import GridItem from "components/Grid/GridItem.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBox from "@material-ui/icons/IndeterminateCheckBox";

// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
// import Tasks from "components/Tasks/Tasks.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import { Button } from "@material-ui/core";
//import { Button } from "@material-ui/core";
//import { Button } from "react";

// import { bugs, website, server } from "variables/general.jsx";
// import { Checkbox } from "@material-ui/core";

import { timeConverter, hashes, alwaysShorten } from "variables/util.jsx";

function shorten(s) {
  return window.innerWidth > 1259 ? s : s.substring(0, 6) + '...' + s.slice(-4);
}

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Blocks extends React.Component {
  render() {
    const { color, stats, blocks, explorer } = this.props;
    const { baseURL, block, uncle } = explorer;
    const blockBaseURL = '' + baseURL + block;
    const uncleBaseURL = '' + baseURL + uncle;
    const useHeightNotHash = blockBaseURL.indexOf("atheios") > 0;
    const useHashNotHeight = (blockBaseURL.indexOf("verus") > 0 || blockBaseURL.indexOf("komodo") > 0);
    const varianceLabel = (blockBaseURL.indexOf("verus") > 0 || blockBaseURL.indexOf("komodo") > 0) ? "" : "variance";
    const difficultyLabel = (blockBaseURL.indexOf("verus") > 0 || blockBaseURL.indexOf("komodo") > 0) ? "" : "difficulty";
    const currentBlockHeight = typeof stats !== "undefined" && typeof stats.nodes !== "undefined" && stats.nodes.length > 0 ?
      Math.max(
        parseInt(
          stats.nodes
            .filter(({ lastBeat }) => stats.now / 1000 - parseInt(lastBeat) < 120)
            .map(({ height }) => height)
            .reduce((m, i) => m.concat(i), [])
        )
      ) : 0
    if (typeof blocks === "undefined") return null;
    var newFindersMissing = false;
    (blocks.candidates ? blocks.candidates : []).map(({ miner }) => {
      if (miner === "") {
        newFindersMissing = true;
      }
    });
    const tabRecent = {
      tabName: "Recently Found",
      tabIcon: CheckBoxOutlineBlank, //Cloud,
      tabContent: (
        <Table
          tableHeaderColor={color}
          tableHead={["height", "time found", "confirmations", varianceLabel, difficultyLabel, newFindersMissing === true ? "" : "finder"]}
          tableData={(blocks.candidates ? blocks.candidates : [])
            .sort((a, b) => (a.height < b.height) ? 1 : (a.height > b.height) ? -1 : (a.timestamp < b.timestamp) ? 1 : (a.timestamp > b.timestamp) ? -1 : 0)
            .map(({ height, timestamp, shares, difficulty, uncle, orphan, miner, worker }) => [
              orphan === true ? <Button title="orphan" color="#ff0000">orphan</Button> :
                <a href={(uncle === true ? uncleBaseURL : blockBaseURL) + height + (uncle === true ? "#uncles" : "")} target="blockExplorer">
                  {String(height)}
                </a>,
              timeConverter(timestamp),
              orphan ? "-" : currentBlockHeight === 0 ? "" : currentBlockHeight - height,
              difficulty === 0 ? "" : String(Math.round(100 * shares / difficulty)) + '%',
              difficulty === 0 ? "" : hashes(difficulty),
              newFindersMissing === true ? "" : <a href={"account/" + miner} >
                {alwaysShorten(miner) + "." + worker}
              </a >,
            ])}
        />
      )
    };
    var immatureFindersMissing = false;
    (blocks.immature ? blocks.immature : []).map(({ miner }) => {
      if (miner === "") {
        immatureFindersMissing = true;
      }
    });
    const tabImmature = {
      tabName: "Immature",
      tabIcon: IndeterminateCheckBox,//Code,
      tabContent: (
        <Table
          tableHeaderColor={color}
          tableHead={["height", "block hash", "time found", "confirmations", varianceLabel, difficultyLabel, "reward", immatureFindersMissing ? "" : "finder"]}
          tableData={(blocks.immature ? blocks.immature : [])
            .sort((a, b) => (a.height < b.height) ? 1 : (a.height > b.height) ? -1 : (a.timestamp < b.timestamp) ? 1 : (a.timestamp > b.timestamp) ? -1 : 0)
            .map(({ height, hash, timestamp, shares, difficulty, uncle, orphan, reward, miner, worker }) => [
              <a href={(uncle === true ? uncleBaseURL : blockBaseURL) + (useHashNotHeight ? hash : height) + (uncle === true ? "#uncles" : "")} target="blockExplorer">
                {String(height)}
              </a>,
              orphan === true ? <Button title="orphan" color="#ff0000">orphan</Button> :
                <a href={blockBaseURL + (useHeightNotHash ? height : hash) + (uncle === true ? "#uncles" : "")} target="blockExplorer">{shorten(hash)}</a>,
              timeConverter(timestamp),
              orphan ? "-" : currentBlockHeight === 0 ? "" : currentBlockHeight - height,
              difficulty === 0 ? "" : String(Math.round(100 * shares / difficulty)) + '%',
              difficulty === 0 ? "" : hashes(difficulty),
              (reward / 1000000000000000000).toFixed(5),
              immatureFindersMissing ? "" : <a href={"account/" + miner} >
                {alwaysShorten(miner) + "." + worker}
              </a >,
            ])
          }
        />
      )
    };
    var maturedFindersPresent = false;
    (blocks.matured ? blocks.matured : []).map(({ miner }) => {
      if (miner !== "") {
        maturedFindersPresent = true;
      }
    });
    const tabMature = {
      tabName: "Matured",
      tabIcon: CheckBox,
      tabContent: (
        <Table
          tableHeaderColor={color}
          tableHead={["height", "block hash", "time found", "confirmations", varianceLabel, difficultyLabel, "reward", !maturedFindersPresent ? "" : "finder"]}
          tableData={(blocks.matured ? blocks.matured : [])
            .sort((a, b) => (a.height < b.height) ? 1 : (a.height > b.height) ? -1 : (a.timestamp < b.timestamp) ? 1 : (a.timestamp > b.timestamp) ? -1 : 0)
            .map(({ height, hash, timestamp, shares, difficulty, uncle, orphan, reward, miner, worker }) => [
              <a href={(uncle === true ? uncleBaseURL : blockBaseURL) + (useHashNotHeight ? hash : height) + (uncle === true ? "#uncles" : "")} target="blockExplorer">
                {String(height)}
              </a>,
              orphan === true ? <Button title="orphan" color="#ff0000">orphan</Button> :
                <a href={blockBaseURL + (useHeightNotHash ? height : hash) + (uncle === true ? "#uncles" : "")} target="blockExplorer">{shorten(hash)}</a>,
              timeConverter(timestamp),
              orphan ? "-" : currentBlockHeight === 0 ? "" : currentBlockHeight - height,
              difficulty === 0 ? "" : String(Math.round(100 * shares / difficulty)) + '%',
              difficulty === 0 ? "" : hashes(difficulty),
              (reward / 1000000000000000000).toFixed(5),
              !maturedFindersPresent ? "" : miner === "" ? "-" : <a href={"account/" + miner} >
                {alwaysShorten(miner) + "." + worker}
              </a >,
            ])}
        />
      )
    };
    const tabNoneYet = {
      tabName: "none yet",
      tabContent: (<center>no blocks found</center>)
    };

    var tabs = [];
    if (blocks.candidatesTotal + blocks.maturedTotal + blocks.immatureTotal === 0) {
      tabs.push(tabNoneYet)
    }
    if (blocks.candidates && blocks.candidates.length > 0) {
      tabs.push(tabRecent);
    }
    if (blocks.immature && blocks.immature.length > 0) {
      tabs.push(tabImmature);
    }
    if (blocks.matured && blocks.matured.length > 0) {
      tabs.push(tabMature);
    }
    return (
      <CustomTabs
        title="blocks"
        headerColor="info"
        tabs={tabs}
      />
    );
  }
}

Blocks.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  stats: PropTypes.object.isRequired,
  blocks: PropTypes.object.isRequired
};

export default withStyles(styles)(Blocks);
