import React from "react";
import PropTypes from "prop-types";

import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import ChartistGraph from "react-chartist";

import withStyles from "@material-ui/core/styles/withStyles";

import { alwaysShorten, shorten, timeUnits } from "variables/util.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class PoolMinerBlocksPieChart extends React.Component {
  render() {
    const { blocks, classes, color, timeRightNow } = this.props;
    if (typeof blocks === "undefined") return null;
    var blocksFound = {};
    var totalBlocksFound = 0;
    var lastBlockFound = {};

    (blocks.candidates ? blocks.candidates : []).map(({ miner, timestamp }) => {
      if (miner !== "") {
        if (typeof blocksFound[miner] === "undefined") {
          blocksFound[miner] = 0;
          lastBlockFound[miner] = 0;
        }
        blocksFound[miner] = blocksFound[miner] + 1;
        totalBlocksFound = totalBlocksFound + 1;
        if (timestamp > lastBlockFound[miner]) {
          lastBlockFound[miner] = timestamp;
        }
      }
    });

    (blocks.immature ? blocks.immature : []).map(({ miner, timestamp }) => {
      if (miner !== "") {
        if (typeof blocksFound[miner] === "undefined") {
          blocksFound[miner] = 0;
          lastBlockFound[miner] = 0;
        }
        blocksFound[miner] = blocksFound[miner] + 1;
        totalBlocksFound = totalBlocksFound + 1;
        if (timestamp > lastBlockFound[miner]) {
          lastBlockFound[miner] = timestamp;
        }
      }
    });

    (blocks.matured ? blocks.matured : []).map(({ miner, timestamp }) => {
      if (miner !== "") {
        if (typeof blocksFound[miner] === "undefined") {
          blocksFound[miner] = 0;
          lastBlockFound[miner] = 0;
        }
        blocksFound[miner] = blocksFound[miner] + 1;
        totalBlocksFound = totalBlocksFound + 1;
        if (timestamp > lastBlockFound[miner]) {
          lastBlockFound[miner] = timestamp;
        }
      }
    });

    const finders = Object.keys(blocksFound)
    if (((finders.length < 2) && (totalBlocksFound > 3)) || !finders.length) return null;
    const data = finders.sort((a, b) => a.localeCompare(b)).map(miner => ({ y: blocksFound[miner], label: miner, short: alwaysShorten(miner), lastFound: lastBlockFound[miner] }))

    return (
      <Card>
        <CardHeader color={color}>
          <p className={classes.cardTitleWhite}>{"finder" + ((totalBlocksFound > 1) ? "s" : "") + " of the last " + (totalBlocksFound > 1 ? totalBlocksFound + " blocks" : " block")}</p>
        </CardHeader>
        <CardBody>
          {totalBlocksFound <= 1 ? null : (<ChartistGraph
            className="ct-chart"
            options={
              {
                chartPadding: 30,
                labelOffset: 100,
                labelDirection: "explode",
                labelInterpolationFnc: function (value) {
                  return alwaysShorten(value)
                }
              }
            }
            data={{
              labels: data.map(({ short }) => short),
              series: data.map(({ y }) => y)
            }}
            type="Pie"
            style={{ height: 400 }}
          />)}
          <Table
            tableHeaderColor={color}
            tableHead={["miner", "blocks found", "last block found"]}
            tableData={data.sort((a, b) => b.y - a.y).map(({ y, label, lastFound }) => [
              <a href={"account/" + label}>{shorten(label)}</a>,
              y,
              timeUnits(
                (timeRightNow - lastFound * 1000) / 1000
              )
            ])}
          />
        </CardBody>
      </Card>
    );
  }
}

PoolMinerBlocksPieChart.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  blocks: PropTypes.object.isRequired,
  poolStats: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(PoolMinerBlocksPieChart);
