import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Ledger extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     ...props
  //   };
  // }

  render() {
    const { classes, selected } = this.props;
    const tracker = (typeof selected === "undefined" ? "" : (selected.split(".")[0] + ".")) + "wattpool.net"
    return (
      <a href={"https://www.ledger.com/?r=eda7c183c5fc&tracker=" + tracker} target="ledger">
        <Card>
          <CardHeader color="info" stats>{/* icon> */}
            {/* <CardIcon color={"info"}>
          <Icon>iso</Icon>
          </CardIcon> */}
            {/* <p className={classes.cardTitle} align="right"><small><b>ledger hardware wallet:</b></small></p> */}
            <div align="center">
              <img alt="Ledger Nano S - The secure hardware wallet" src="/ledger.png" width={240} />
            </div>
          </CardHeader>
          <p className={classes.cardTitle} align="right"><small><b>
            <a href="https://www.ledger.com/?r=eda7c183c5fc&tracker=wattpool.net" target="ledger">buy now direct&nbsp;&nbsp;&nbsp;</a>
          </b></small></p>
        </Card>
      </a>);
  }
}

Ledger.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  pool: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  poolStats: PropTypes.object.isRequired,
  vertical: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(Ledger);
